.dk-settDelPageMain{
    margin-top: 40px;
    .dk-settDelPageCon{
        .dk-boxsCon{
            color: #333;
            .dk-informationCols{
                padding: 35px 25px;
                background: #e2e2e2;
                text-align: left;
                position: relative;
                width: 100%;
                max-width: 94%;
                &::before{
                    content: "";
                    width: 6px;
                    height: 100%;
                    background: #2d96b6;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
            .dk-complinceCon{
                background: #d9d9d9;
                color: #333;
                .title{
                    background: #1f394c;
                    padding: 0 10px;
                    color: #fff;
                    font-size: 18px;
                    text-align: center;
                    border-top-left-radius: 5px ;
                    border-top-right-radius: 5px;
                    height: 45px;
                    line-height: 45px;
                }
                .dk-complaince-Body{
                    border-radius: 5px;
                    text-align: left;
                    padding: 20px 35px;
                    .sb-title{
                        font-weight: 700;
                    }
                    ul{
                        margin-bottom: 0;
                        padding-left: 20px;
                        li{
                            list-style-type: disc;
                        }
                    }
                    .checkboxCon{
                        label{
                            margin-bottom: 0;
                        }
                        input{
                            display: inline-block;
                            margin-right: 5px;
                        }
                    }
                    .dk-complaince-field{
                        margin: 15px auto;
                        button.dk-deleteBtn {
                            background: #e2445b;
                            border-color: #e2445b;
                            max-width: fit-content;
                            padding: 0 25px;
                            border-radius: 0;
                            color: #fff;
                            height: 40px;
                            outline: 0;
                            text-transform: uppercase;
                            border: none;
                        }
                        button.dk-deleteBtn-disabled{
                            background: #b3adad;
                            border-color: #b3adad;
                            max-width: fit-content;
                            padding: 0 25px;
                            border-radius: 0;
                            color: #333;
                            height: 40px;
                            outline: 0;
                            text-transform: uppercase;
                            border: none;
                            &:hover{
                                background: #2d96b6;
                                color: #fff;
                                transition: all 0.5s;
                            }
                        }
                    }
                }
            }
        }
    }

}