.dk-jobsloginMain{
    background: #1F4353;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .dk-jobslogincard.card {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border-radius: 5px;
        border: none;
        max-width: 500px;
        margin: 0 auto;
        padding: 0;
        .title{
            text-transform: uppercase;
            font-weight: 700;
            font-size: 20px;
            text-align: center;
            padding: 15px 0;
            border-bottom: 1px solid #ddd;
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            a{
                color: #333;
            }
        }
        .dkjobs-tabContent{
            padding-bottom: 16px;
            .sbtitle {
                text-align: center;
                padding: 25px 0;
                display: block;
            }
            .LoginBlock{
                display: flex;
                justify-content: center;
                padding: 0 50px 30px;
                .LoginBox{
                    width: 130px;
                    height: 120px;
                    background: #1f4353;
                    margin: 0 10px;
                    border-radius: 5px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    line-height: 35px;
                    text-transform: uppercase;
                    span{
                        i{
                            display: block;
                            font-size: 46px;
                        }
                        svg{
                            display: block;
                            font-size: 46px;
                            text-align: center;
                            width: 100%;
                        }
                    }
                }
                .LoginBox:hover {
                    background: #2d96b6;
                }
            }
        }
    }
}
