.dk-jobListingMain{
    background-color: #25455a;
    color: #fff;
    padding: 30px 15px;
    .dk-jobListing-head{
        margin-bottom: 16px;
        h2.title{
            text-align: center;
            font-size: 24px;
            text-transform: uppercase;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            a{
                background: transparent;
                border: 1px solid #afafaf;
                color: #afafaf;
                display: inline-block;
                padding:0px 20px;
                border-radius: 30px;
                font-weight: 600;
                font-size: 14px;
                margin-left:12px;
                &:hover{
                    border: 1px solid #2d96b6;
                    color: #fff;
                    background: #2d96b6;
                    transition: all 0.5s;
                }
            }
        }
    }
    .col-md-2{
        @media(min-width:768px){
            max-width: 18%;
            flex: 18% 1;       
        }
    }
    .col-md-8{
        @media(min-width:768px){
            max-width: 65%;
            flex: 65% 1;       
        }
    }  
    .dk-jobsFilterMain{
        .dk-jobsfilter{
            margin-top: 55px;
            .dk-mobViewFilter-main{
                .dk-jobsfilter-search{
                    margin-bottom: 10px;
                    label{
                        margin-bottom:0;
                        input{
                            border-radius: 5px 0 0 5px!important;
                            height: 40px;
                            font-size: 14px;
                            border-right: 0;
                        }
                        button.dk-filter-search-btn{
                            border-radius: 0 5px 5px 0;
                            border-bottom: 1px solid #ccc;
                            width: 48px;
                            height: 40px;
                            border: none;
                            border-left: 1px solid #ccc;
                            background: #fff;                            
                        }
                    }
                }
                .dk-jobsfilter-cols{
                    &:nth-child(02){
                        border-radius: 5px 5px 0 0;
                    }
                    background: #fff;
                    padding: 10px 15px;
                    font-size: 14px;
                    font-weight: 300;
                    border: 1px solid #ddd;
                    .title{
                        font-size: 14px;
                        margin-bottom: 15px;
                        color: #333;
                        font-weight: 700;
                        text-transform: uppercase;
                    }
                    .d-flex{
                        margin-bottom: 10px;
                    }
                    label{
                        color: #333;
                        font-weight: 400;
                        &::before{
                            border-radius: 0;
                            background: #ccc;
                            border-color: #ccc;
                            top: 3px;
                        }
                    }
                    .custom-control-input:checked~.custom-control-label:before{
                        color: #fff;
                        border-color: #007bff;
                        background-color: #007bff;
                    }
                    span{
                        color: #096fdc;
                        font-weight: 500;
                    }
                    .fulltime{
                        .custom-control-label{
                            &::after{
                                background-color: #0170c1;
                                border-color: #0170c1;
                            }
                        }
                    }
                    .parttime{
                        .custom-control-label{
                            &::after{
                                background-color: #34976e;
                                border-color: #34976e;
                            }
                        }
                    }
                    .freelance{
                        .custom-control-label{
                            &::after{
                                background-color: #34976e;
                                border-color: #34976e;
                            }
                        }
                    }
                    .remote{
                        .custom-control-label{
                            &::after{
                                background-color: #ec8b5e;
                                border-color: #ec8b5e;
                            }
                        }
                    }
                    .intership{
                        .custom-control-label{
                            &::after{
                                background-color: #ffc001;
                                border-color: #ffc001;
                            }
                        }
                    }
                }
            }
        }
        
    }
    .dk-jobslisting{
        .dk-jobslisting-category{
            background: #fff;
            border-radius: 5px;
            .filterUl{
                display: flex;
                align-items: center;
                justify-content: center;
                white-space: nowrap;
                width: 100%;
                list-style: none;
                margin: 0;
                padding: 0;
                li{
                    color: #000;
                    float: left;
                    padding: 0!important;
                    width: 100%;
                    text-align: center;
                    border-right: 1px solid #ccc;
                    font-size: 13px;
                    font-weight: 600;
                    list-style-type: none;
                    &:last-child{
                        border-right:none;
                        a{
                            border-radius:0 4px 4px 0;
                        }
                    }
                    &:first-child{
                        a{
                            border-radius: 4px 0 0 4px;
                        }
                    }
                    a{
                        color: #333;
                        display: block;
                        padding: 10px 0;
                    }
                }
                a.active{
                    background: #2d96b6;
                    border: 1px solid #2d96b6;
                    color: #fff;
                }
            }
        }
        .dk-jobslisting-tagselect{
            background: #fff;
            margin: 15px 0 10px;
            border-radius: 3px;
            padding: 7px 0 7px 12px;
            font-size: 14px;
            .dk-jobs-list{
                color: #333;
            }
            .dk-jobs-cate-link{
                a.box{
                    border: 1px solid #ccc;
                    display: inline-block;
                    margin: 0 5px;
                    border-radius: 30px;
                    position: relative;
                    font-size: 11px;
                    font-weight: 600;
                    color: #333;
                    padding:2px 27px 2px 12px;
                    background: #ccc;
                    &:hover{
                        background: #2d96b6;
                        color: #fff;
                        transition: all .5s;
                        border-color: #2d96b6;
                        i{
                            background-color: #1780a0;
                            color: #fff;
                            transition: all .5s;
                        }
                    }
                    i{
                        position: absolute;
                        right: 0;
                        background: #a5a5a5;
                        padding: 7px 6px;
                        top: 0;
                        border-radius: 0 30px 30px 0;
                        color: #fff;
                    }
                }
                a{
                    &:last-child{
                        margin-left: 0;
                        width: 100%;
                        font-weight: 700;
                        padding:9px 12px 12px;
                        color: #007bff;
                        text-transform: uppercase;
                        border-radius: 0 2px 2px 0;
                        border-right: none;
                        &:hover{
                            background: #325f7d;
                            color: #fff;
                            transition: all .5s;
                        }
                    }
                }
            }
        }
        .dk-boxes-cols{
            background-color: #fff;
            padding: 0;
            margin-bottom: 10px;
            border-radius: 3px;
            .dk-boxes-cols-img{
                text-align: center;
                img{
                    width: 100%;
                    max-width: 95px;
                }
            }
            .col-md-7{
                @media(min-width:767px){
                    max-width: 62%;
                    flex: 62% 1;
                }
            }
            .col-md-3{
                @media(min-width:767px){
                    max-width: 20%;
                    flex: 20% 1;
                }
            }
            .dk-boxes-cols-right{
                .dk-boxes-cols-right-cols{
                    width: 100%;
                    .title{
                        font-size: 16px;
                        margin-bottom: 0;
                        display: block;
                        color: #184354;
                        font-weight: 700;
                        pointer-events: none;
                        margin-top:10px;
                    }
                    .dk-boxes-right-cols-desc{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 15px;
                        width: 100%;
                        max-width: 325px;
                        ul{
                            li{
                                list-style-type: none;
                                font-size: 13px;
                                width: 100%;
                                position: relative;
                                margin-bottom: 7px;
                                color: #000;
                                display: flex;
                                white-space: nowrap;
                                align-items: center;
                                i{
                                    color: #9c9c9c;
                                    display: inline-block;
                                    width: 22px;
                                }
                            }
                        }
                    }
                }
            }
            .dk-boxes-cols-right-cols-viewjobs{
                width: 100%;
                text-align: right;
                margin-top: 0;
                color: #000;
                height: 140px;
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                flex-wrap: wrap;
                border-left: 1px solid #ccc;
                padding:0;
                a{
                    background-color: #fff;
                    border: #194254;
                    color: #333;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    border-radius: 0;
                    padding:13px 15px;
                    margin-right: 0;
                    font-size: 14px;
                    width: 100%;
                    text-align: center;
                    border-bottom: 1px solid #ddd;
                    display: block;
                    &:hover{
                        background-color: #2d96b6;
                        border-color: #2d96b6;
                        color: #fff;
                        transition: all 0.5s;
                    }
                    i{
                        display: inline-block;
                        width: 20px;
                    }
                }
            } 
            
            .dk-searchByHead{
                padding:10px 12px;
                display: flex;
                position: relative;
                .dk-bacjBtn{
                    display: inline-block;
                    a{
                        display:block;
                        background: #6c757d;
                        color: #fff;
                        padding:7px 15px;
                        text-transform: uppercase;
                        white-space: nowrap;
                        font-size: 13px;
                        border-radius: 5px;
                        &:hover{
                            background: #194254;
                            color: #fff;
                            transition: all 0.5s;
                        }
                    }
                }
                .title{
                    position: absolute;
                    left: 50%;
                    top: 17px;
                    transform: translate(-50%);
                    font-size: 17px;
                    color: #333;
                    font-weight: 700;
                    display: inline-block;
                }
            }
            .dk-searchByPanel{
                border-top: 1px solid #ccc;
                padding: 12px;
                ul{
                    margin:0;
                    padding: 0;
                    list-style: none;
                    li{
                        list-style-type: none;
                        padding-bottom: 10px;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        label.form-check-label{
                            cursor: pointer;
                            font-size: 15px;
                            color: #333;
                        }
                    }
                }
            }
        }
    }
    @media(min-width:768px){
        .dk-col-md-2{
            flex: 0 0 16.666667%;
            max-width: 16.666667%;
        }
    }
    .dk-companiesMain{
        margin-top: 57px;
        .dk-companiesBox{
            border: 1px solid #ccc;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 25px 15px;
            margin-bottom: 10px;
            background: #fff;
        }
    }
}