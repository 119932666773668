.dk-supportPage{
    margin-top: 40px;
    .dk-supportPageCon{
        .dk-boxsCon{
            color: #333;
            .dk-informationCols{
                padding: 35px 25px;
                background: #e2e2e2;
                text-align: left;
                position: relative;
                width: 100%;
                max-width: 94%;
                &::before{
                    content: "";
                    width: 6px;
                    height: 100%;
                    background: #2d96b6;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
            .dk-complinceCon{
                background: #fff9f9;
                color: #333;
                .title{
                    background: #1f394c;
                    padding: 0 10px;
                    color: #fff;
                    font-size: 18px;
                    text-align: center;
                    border-top-left-radius: 5px ;
                    border-top-right-radius: 5px;
                    height: 45px;
                    line-height: 45px;
                }
                .dk-complaince-Body{
                    background-color: #67808e;
                    form{
                        .row.border-message{
                            border-top:1px solid rgba(0,0,0,.1);
                        }
                    }
                    label {
                        width: 100%;
                        font-weight: 400;
                        position: relative;
                        color: #fff;
                        text-align: left;
                        margin-bottom: 5px;
                        span {
                            width: 40px;
                            background: #ccc;
                            text-align: center;
                            &:hover{
                                background: #2d96b6;
                                color: #fff;
                                cursor: pointer;
                            }
                            i {
                                color: #333;
                            }
                        }
                        span.colorRequired{
                            background: transparent;
                        }
                    }
                    input.form-control{
                        width: 100%;
                        height: auto;
                        display: block;
                        border: 1px solid #ccc;
                        padding: 7px 10px;
                        border-radius: 0;
                        margin: 0;
                        font-size: 13px;
                        background: #fff;
                    }
                    select.form-control{
                        border-radius: 0;
                        height: 35px;
                        line-height: 35px;
                    }
                    input.file{
                        padding: 4px 10px;
                    }
                    input.form-control:focus{
                        box-shadow: none;
                    }
                    .btn-container{
                        .dk-cancelBtn{
                            background: transparent;
                            color: #fff;
                            font-size: 16px;
                            border-radius: 5px;
                            outline: none;
                            margin: 0 5px;
                            border: none;
                            padding: 8px 25px;
                            &:hover{
                                background: #E2445B !important;
                                color: #fff;
                                transition: all 0.5s;
                            }
                        }
                        .dk-updateBtn{
                            background: #1f394c;
                            color: #fff;
                            font-size: 16px;
                            border-radius: 5px;
                            outline: none;
                            margin: 0 5px;
                            border: none;
                            padding: 8px 25px;
                            text-transform: capitalize;
                            box-shadow:none;
                            &:hover{
                                background: #2d96b6;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}