.dk-privacyContactPage{
    padding-top: 38px;
    padding-bottom: 90px;
    .container{
        h1 {
            padding: 0 0 10px;
            font-size: 30px;
            font-weight: 600;
            color: #38607c;
        }
        .contact_detail {
            background: #fff;
            box-shadow: 0 0 20px rgba(0,0.2,0.4,0.55);
            margin: 10px 0 0;
            padding: 25px 30px;
            h3 {
                margin: 35px 0 25px;
                font-size: 18px;
                color: #25455a;
                font-weight: 600;
            }
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                li {
                    margin: 0;
                    padding: 0 0 20px 20px;
                    color: #25455a;
                    position: relative;
                    text-align: left;
                    i {
                        position: absolute;
                        left: 0;
                        top: 5px;
                    }
                    a {
                        color: #333;
                    }
                }
            }
        }
        .dk-rightprivacyCard{
            background-color: #38607c;
            background-clip: border-box;
            border-radius: 1px;
            border: 1px solid #38607c;
            box-shadow: 0 0 20px rgba(0,0.2,0.4,0.55);
            form {
                padding: 20px 30px 40px;
                h2 {
                    margin: 15px 0;
                    font-size: 18px;
                    color: #fff;
                    text-align: left;
                    font-weight: normal;
                }
                .form-group {
                    margin-bottom: 1rem;
                    input {
                        height: 34px;
                        padding: 0.1rem 0.5rem;
                        font-size: 14px;
                        background-color: #fff;
                        line-height: 1.3;
                        border: #fff;
                        border-radius: 5px;
                    }
                    .form-group.textarea{
                        textarea {
                            height: 80px;
                            border-radius: 5px;
                        }
                    }
                }
                p.termsuser {
                    display: flex;
                    align-items: center;
                    color: #ccc;
                    padding: 0 15px;
                    width: 100%;
                    input {
                        margin-right: 5px;
                        a {
                            color: #e1ad01;
                            margin: 0 2px;
                        }
                    }
                }
                .btn.btn-light {
                    font-size: 14px;
                    padding: 10px 25px;
                    margin: 0 15px;
                    border-radius: 5px;
                    background: #e0e0e0;
                    border: 1px solid #e0e0e0;
                    display: flex;
                    justify-content: left;
                    text-transform: uppercase;
                    &:hover {
                        background: #3198b7;
                        border: 1px solid #3198b7;
                        transition: all .5s;
                        color: #fff;
                        outline: 0;
                        box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15);
                    }
                }
            }
        }
    }
}