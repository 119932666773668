.dk-accessdataMain{
   width: 100%;
    max-width: 700px;
    background: #325f7d;
    color: #fff;
    border-radius: 5px;
    padding-bottom: 15px;
    margin: 0 auto 35px;
    margin-top: 4.3rem;
    .pageTitle{
        padding: 30px 0px 25px;
        .section-title{
            font-size: 28px;
            font-weight: 700;
            color: #fff;
            margin: 0;
            padding: 0 0 15px;
            text-transform: uppercase;
            text-align: center;
        }
        p{
            color: #000;
        }
    }
    .dk-access-data {
        font-family: "Open Sans",sans-serif;
        .dk-access-data-cols {
            width: 100%;
            max-width: 500px;
            color: #fff;
            margin: 0 auto 30px;
            form{
                .form-group{
                    margin-bottom: 1rem;
                    .title{
                        color: #fff;
                        text-align: left;
                        margin-bottom: 0.5rem;
                    }
                    .d-flex{
                        .dk-form-cols {
                            width: 100%;
                            margin-right: 8px;
                            input.dk-radiobtn {
                                display: none;
                            }
                            label {
                                color: #fff;
                                border-radius: 4px;
                                border: 1px solid #fff;
                                padding: 6px 15px;
                                display: inline-block;
                                cursor: pointer;
                                -webkit-user-select: none;
                                -ms-user-select: none;
                                user-select: none;
                                width: 100%;
                                text-align: center;
                                font-size: 15px;
                                vertical-align: middle;
                                transition: all .5s;
                            }
                            input[type=radio]:checked+label {
                                background: #4caf50;
                                color: #fff;
                                border: 1px solid #4caf50;
                                transition: all .5s;
                            }
                        }
                        .dk-form-cols-checkbox-area{
                            width: 100%;
                            margin-right: 8px;
                            input.dkcheckbox-btn {
                                display: none;
                            }
                            label {
                                color: #fff;
                                border-radius: 4px;
                                border: 1px solid #fff;
                                padding: 6px 15px;
                                display: inline-block;
                                cursor: pointer;
                                -webkit-user-select: none;
                                -ms-user-select: none;
                                user-select: none;
                                width: 100%;
                                text-align: center;
                                font-size: 15px;
                                vertical-align: middle;
                                transition: all .5s;
                            }
                            input[type=checkbox]:checked+label {
                                background: #4caf50;
                                color: #fff;
                                border: 1px solid #4caf50;
                                transition: all .5s;
                            }
                        }
                    }
                }
                .dk-form-fill{
                    .dk-form-fill-cols {
                        margin-right: 8px;
                        width: 100%;
                        padding: 0 10px;
                        &:last-child {
                            margin-right: 0;
                        }
                        .dk-form-fill-group-cols{
                            padding: 0 5px;
                            margin-bottom: 10px;
                            label {
                                color: #fff;
                                width: 100%;
                                text-align: left;
                                input {
                                    border-radius: 0;
                                    width: 100%;
                                }
                            }
                        }
                        label{
                            display: block;
                            text-align: left;
                            textarea {
                                border-radius: 0;
                                width: 100%;
                                height: 110px;
                                resize: vertical;
                                border: none;
                            }
                        }
                    }
                    button {
                        font-size: 14px;
                        padding: 10px 25px;
                        background-color: #25455a;
                        margin: 0;
                        color: #fff;
                        border: 0;
                        border-radius: 0.125rem;
                        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
                    }
                }
            }
        }
    }
}