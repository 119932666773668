.dk-companyJobsHeader{
    background: #67818e;
    padding: 25px 5px 0;
    .dk-companyJobs-logo{
        padding: 25px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
    }
    .company-nav{
        display: flex;
        align-items: center;
        justify-content: center;
        a{
            color: #fff;
            padding: 10px 15px;
            display: block;
            font-size: 14px;
            text-transform: uppercase;
            font-weight:300;
        }
        a.active{
            color: #f4d03e;
            font-weight:600;
        }
    }
}

.dk-companyJobs-main{
    padding:15px 15px;
    .dk-jobsfilter-search{
        margin-bottom: 1.1em;
        margin-top: 3.7rem;
        label{
            input{
                height:35px;
                border-radius: 0;
                border-right: 0;
            }
            .dk-filter-search-btn{
                display: flex;
                align-items: center;
                padding: 0.7rem 0.75rem;
                margin-bottom: 0;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #495057;
                text-align: center;
                white-space: nowrap;
                background-color: #e9ecef;
                border: 1px solid #ced4da;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }
        }
    }
    .dk-jobsFilter-main{
        .MuiExpansionPanel-root.Mui-expanded{
            margin: 0;
            min-height: auto;
            &:first-child{
                margin-top: 0;
            }
        }
        .MuiExpansionPanelSummary-root{
            min-height: auto;
            padding: 0;
            .MuiExpansionPanelSummary-content{
                margin: 0;
                display: block;
                padding: 10px 15px;
                background: #67818e;
                color: #fff;
                .MuiTypography-root{
                    color: #fff;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
            .MuiButtonBase-root{
                padding: 0;
                margin-right: 10px;
                position: absolute;
                right: 0;
                color: #fff;
            }
        }
        .MuiExpansionPanelSummary-root.Mui-expanded{
            min-height: auto;
        }
        .MuiPaper-root{
            .MuiCollapse-root{
                .MuiCollapse-wrapper{
                    .MuiCollapse-wrapperInner{
                        width: 100%;
                        .MuiExpansionPanelDetails-root{
                            padding: 0;
                            .MuiTypography-root{
                                display: block;
                                width: 100%;
                                padding-top: 10px;
                                padding-bottom: 10px;
                                border: 1px solid #afabab;
                                ul{
                                    margin: 0;
                                    li{
                                        list-style-type: none;
                                        &:last-child{
                                            a{
                                                border-bottom: none;
                                            }
                                        }
                                        a{
                                            display: block;
                                            color: #333;
                                            padding: 5px 12px;
                                            font-size: 13px;
                                            span{
                                                float: right;
                                                color: #0070c0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .small_title{
        width: 100%;
        position: relative;
        top: 4px;
        ul{
            list-style: none;
            padding: 0;
            margin: 0;
            li{
                color: #676363;
                font-size: 14px;
                display: inline;
                position: relative;
                top: 2px;
            }
        }
    }
    .joblistfilterul{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        li{
            padding: 3px 10px;
            text-align: center;
            border: 1px solid #0087be;
            display: inline-flex;
            font-size: 11px;
            margin-right: 5px;
            border-radius: 3px;
            a{
                font-weight: 700;
                color: #333;
            }
        }
    }
    .joblistul{
        margin: 0;
        padding: 0 18px;
        li{
            margin: 0 0 25px;
            list-style: none;
            border: 1px solid #afabab;
            position: relative;
            .jobtop{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .joblist-right{
                    width: 200px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    img{
                        max-width: 180px;
                    }
                    button.btn-success{
                        box-shadow: none;
                        padding:10px 10px 8px;
                        min-width: 100%;
                        border-radius: 0;
                        margin: 0;
                        font-size: 13px;
                        top:35px;
                        position: relative;
                        border:none;
                        background-color: #00796b!important;
                        border-color: #00796b!important;
                        text-transform: uppercase;
                        font-weight:300;
                    }
                    button.btn-danger{
                        box-shadow: none;
                        padding:10px 10px 8px;
                        min-width: 100%;
                        border-radius: 0;
                        margin: 0;
                        font-size: 13px;
                        top:35px;
                        position: relative;
                        border:none;
                        background-color: #E2445B!important;
                        border-color: #E2445B!important;
                        text-transform: uppercase;
                        font-weight:300;
                    }
                }
                .joblist-left{
                    width: calc(100% - 200px);
                    height: 100%;
                    padding: 10px 0 10px 15px;
                    border-left: 1px solid #afabab;
                    .width-71{
                        width: 71%;
                        .jtitle{
                            margin-bottom: 10px;
                            color: #0a3958;
                            font-size: 18px;
                            font-weight: 700;
                            display: block;
                        }
                    }
                    table{
                        tr{
                            td{
                                font-size: 13px;
                                font-weight: 500;
                                vertical-align: top;
                            }
                        }
                    }
                    .dk-company-viewjob{
                        padding-left: 15px;
                        width: 100%;
                        max-width: 200px;
                        a{
                            background-color: #5c707a;
                            border-color: #5c707a;
                            color: #fff!important;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            border-radius: 3px;
                            padding: 8px 10px;
                            margin-right: 5px;
                            font-size: 14px;
                            margin-right: 5px;
                            cursor: pointer;
                            &:hover{
                                background: #f4d03e;
                                transition: all 0.5s;
                                color: #333 !important;
                            }
                        }
                        span{
                            font-size: 13px;
                            width: 100%;
                            display: block;
                        }
                    }
                }   
            }
        }
        
    }
    .dk-allJobsHead{
        padding: 0 15px;
    }
    .cj-right{
        margin-top:43px;
        a{
            margin: 0 0 10px;
            background: #fff;
            border: 1px solid #cac4c4;
            min-height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .dk-companyJobs-aboutUs{
        padding: 15px;
        border:1px solid #ccc;
        margin-top:43px;
        margin-bottom:20px;
        .dk-companyJobsInfo{
            .dk-jobInfoHead{
                background: #5c707a;
                color: #fff;
                font-weight: 300;
                padding: 10px 12px;
                border-bottom: 1px solid #fff;
            }
            .dk-jobInfoContent{
                background: #fff;
                border-bottom: 1px solid #ccc;
                padding: 10px 12px;
            }
        }
        article{
            margin:15px 0 10px;
        }
    }
    .dk-companyJobsContactus{
        margin-top: 43px;
        margin-bottom: 20px;
        .dk-visualcontact-cols{
            width: 50%;
            min-height: 500px;
            .title{
                font-weight: 600;
            }
            ul{
                padding: 0;
                margin: 20px 0 10px;
                list-style: none;
                li{
                    list-style-type: none;
                    margin: 12px 0;
                }
            }
            .dk-socialIcon{
                margin-top: 25px;
                a{
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    border: 1px solid #fff;
                    text-align: center;
                    line-height: 30px;
                    color: #fff;
                    margin-right: 10px;
                    &:hover{
                        background-color: #182834;
                        border-color: #182834;
                        transition: all .5s;
                    }
                }
            }
        }
        .dk-visualcontact-cols.bg_blue{
            padding: 90px;
            background-color: #5c707a;
            color: #fff;
            padding: 95px 100px 100px;
            box-shadow: 0 0 10px rgba(0,0,0,0.21);
            .title{
                font-size: 27px;
            }
        }
        .dk-visualcontact-cols.dk-form{
            background-color: #fff;
            box-shadow: 0 0 10px rgba(0,0,0,0.21);
            padding: 15px 35px;
            .title{
                margin-bottom: 15px;
                font-size: 18px;
            }
            form{
                label{
                    width: 100%;
                    font-weight: 400;
                    input{
                        height: 40px;
                        width: 100%;
                        outline: none;
                        border-radius: 0;
                        border: 1px solid #ccc;
                        padding: 0 10px;
                    }
                    button{
                        background: #5c707a;
                        border: none;
                        color: #fff;
                        padding:12px 22px;
                        font-weight: 300;
                        font-size: 15px;
                        border-radius: 5px;
                        outline: none;
                    }
                    textarea{
                        height: 120px;
                        resize: vertical;
                        border: 1px solid #ccc;
                        box-shadow: none !important;
                    }
                }
                input[type=checkbox]{
                    margin-top: -7px;
                    margin-right: 8px;
                }
            }
        }
    }
}