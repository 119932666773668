.dk-postNewJobMain{
    margin-top: 50px;
    padding: 0 15px 25px;
    .dk-postNew-leftPanel{
        width: 100%;
        max-width: 160px;
        margin-right: 25px;
        .nav-pills{
            list-style: none;
            .nav-item{
                list-style-type: none;
                max-width: 100%;
                flex: 100% 1;
                border: none!important;
                margin: 0;
                a{
                    background: #67818e;
                    display: block;
                    padding: 8px 15px;
                    color: #fff;
                    font-weight: 500;
                    border: none;
                    border-bottom: 1px solid #ccc;
                    position: relative;
                    border-radius: 0;
                }
                a.active{
                    background: #234158;
                    border-radius: 0;
                    border: none;
                    border-bottom: 1px solid #ccc;
                    position: relative;
                    &::after{
                        content: "\f0da";
                        display: inline-block;
                        font-family: "Font Awesome 5 Free";
                        font-size: 18px;
                        font-weight: 700;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        position: absolute;
                        right: 10px;
                        top: 7px;
                    }
                }
            }
        }
    }
    .dk-postNew-Rightpanel{
        width: 100%;
        .dk-postingPanel{
            .dk-sdpost-main-cols{
                width: 100%;
                margin: auto;
                .dk-sdpost-top{
                    background-color: #234158;
                    padding: 12px 15px;
                    color: #fff;
                    .dk-sdpost-top-box{
                        width: 100%;
                        max-width: 33.33%;
                        .textorange{
                            list-style-type: none;
                            color: #fff;
                            font-size: 14px;
                            font-weight: 500;
                            span{
                                color: #ccc;
                            }
                        }
                        li.textorange {
                            color: orange!important;
                        }
                        li.textgreen {
                            span{
                                color: #ccc;
                            }
                            color: #27d627!important;
                        }
                        li{
                            list-style-type: none;
                            color: #fff;
                            font-size: 14px;
                            font-weight: 500;
                            span{
                                color: #ccc;
                            }
                        }
                    }
                    .title{
                        font-weight: 700;
                        color: #fff;
                        text-transform: uppercase;
                        display: block;
                        text-align: center;
                        font-size: 22px;
                    }
                }
            }
            .dk-postNewJob-cols{
                .dk-newJobsCols{
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    background: #d9d9d9;
                    padding:25px 10px;
                    border: 1px solid #c5c5c5;
                    .dk-formBox{
                        background-color: #e9ecef;
                        box-shadow:0 2px 10px 0 rgba(0,0,0,0.12);
                        padding: 15px;
                        .grey-text{
                            color: #333;
                            width: 150px;
                            font-weight: 600;
                            span.colorRequired{
                                color: red;
                                padding-left: 3px;
                            }
                        }
                        .form-control{
                            border-radius: 0;
                        }
                        .infoDiv{
                            position: relative;
                        }
                        span.dk-infoTag{
                            cursor: pointer;
                            background: #e9ecef;
                            display: inline-block;
                            width: 31px;
                            height: 31px;
                            text-align: center;
                            line-height: 32px;
                            color: #333;
                            position: absolute;
                            right: 0;
                            border-left: 1px solid #ccc;
                            border-right: 1px solid #ccc;
                        }
                    }
                    .dk-formRightPanel{
                        form{
                            .grey-text{
                                color: #333!important;
                                width: 150px;
                                font-weight: 600;
                                span.colorRequired{
                                    color:red;
                                    padding-left: 3px;
                                }
                            }
                            .form-control{
                                border-radius: 0;
                            }
                            textarea{
                                resize: vertical;
                            }
                            .standard-job-post-editor{
                                .ck.ck-editor__main{
                                    .ck-editor__editable{
                                        min-height: 525px;
                                    }                                    
                                }
                            }
                            .job-post-footer{
                                button{
                                    font-size: 15px;
                                    font-weight: 600;
                                    border: 1px solid #30495d;
                                    border-radius: 0;
                                    text-transform: capitalize;
                                    padding: 12px 20px;
                                    box-shadow: none;
                                    color: #fff!important;
                                    min-width: 140px;
                                    margin: 0 8px;
                                    text-transform: uppercase;
                                    &:hover{
                                        box-shadow:0 4px 15px 0 rgba(0,0,0,0.15);
                                        transition: all 0.5s;
                                    }
                                }
                                button.previewbtn{
                                    background-color: #ffc356!important;
                                    border-color: #ffc356;
                                    color: #000!important;
                                }
                                button.postbtn{
                                    background-color: #2d96b6!important;
                                    border-color: #2d96b6!important;
                                }
                            }
                        }
                    }
                }
            }
        }
        .dk-postOtherPanel{
            h2{
                font-weight: 700;
                font-size: 22px;
                text-transform: uppercase;
                margin-bottom: 0;
                text-align: center;
                background: #67818e;
                padding: 8px 0;
                color: #fff;
            }
            .dk-panelCols{
                padding: 15px;
                border: 1px solid #ccc;
                border-top: none;
            }
        }
    }
}

.dk-activeJobsMain{
    padding: 15px;
    .dk-myDocs-head{
        padding: 10px 0 5px;
        position: relative;
        .dk-searchFieldBox{
            position: relative;
            width: 100%;
            max-width: 150px;
            input{
                border-radius: 0;
                padding-right: 25px;
            }
            &::after{
                content: "\f002";
                display: inline-block;
                font-family: "Font Awesome 5 Free";
                font-weight: bold;
                font-size: 14px;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                position: absolute;
                right: 9px;
                top: 8px;
                color: #333;
            }
        }
        .dk-userFilerBtn{
            background: #f2f2f2;
            width: 40px;
            border: 1px solid #ccc;
            outline: none;
        }
        .dk-tableTitle{
            position: absolute;
            left: 50%;
            -webkit-transform: translate(-50%);
            transform: translate(-50%);
            color: #333;
            font-size: 20px;
            font-weight: 700;
            margin: 0;
            display: inline-block;
            top: 15px;
            text-transform: uppercase;
        }
        .dk-myDocs-btn{
            .dropdown{
                .dropdown-toggle{
                    background-color: #fff!important;
                    color: #333;
                    border: 1px solid #ccc;
                    box-shadow: none;
                    &::after{
                        top: 3px;
                        position: relative;
                        font-size: 18px;
                    }
                }
                .dropdown-menu{
                    left: inherit!important;
                    right: 0!important;
                    -webkit-transform: inherit!important;
                    transform: inherit!important;
                    top: 35px!important;
                    padding: 0;
                    border-radius: 0;
                    border:1px solid #ddd;
                    a{
                        padding:5px 12px;
                    }
                }
            }
        }
    }
    .dk-activeJobsTable{
        table{
            tr{
                th{
                    border: 1px solid #ccc;
                    color: #fff;
                    font-weight: 600;
                    font-size: 15px;
                    background: #1e394c;
                    vertical-align: middle;
                    padding: 12px;
                    text-align: center;
                }
                td{
                    border: 1px solid #ccc;
                    color: #333;
                    vertical-align: middle;
                    padding: 3px 12px 4px;
                    background: #f5f5f5;
                    font-weight: 500;
                    font-size: 14px;
                    text-align: center;
                    .dk-profileImg{
                        img{
                            width: 35px;
                            border-radius: 50%;
                        }
                    }
                    .active{
                        span{
                            display: inline-block;
                            background: green;
                            color: #fff;
                            cursor: pointer;
                            padding: 7px 16px;
                        }
                    }
                    .dkjobTitle{
                        a{
                            font-weight: normal;
                            color: #333;
                            &:hover{
                                color:#2d96b6;
                            }
                        }
                    }
                    .dk-actionBtn{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        a{
                            display: inline-block;
                            padding: 0;
                            border-radius: 3px;
                            font-weight: 600;
                            margin-right: 5px;
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                        }
                        a.dkrepost{
                            background: #ccc;
                            color:#222;
                        }
                        a.dkview{
                            background: #ffb64d;
                            color: #333;
                        }
                        a.dkedit{
                            background: #007bff;
                            color: #fff;
                        }
                        a.dkdelete{
                            background: #d93647;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

.dk-adminFilterMain{
    .modal-dialog{
        min-width: 500px;
        max-width: 500px;
        .modal-content{
            border-radius: 5px;
            .modal-header{
                background-color: #26597d;
                padding:8px 12px;
                .modal-title{
                    margin-bottom: 0;
                    line-height: 1.5;
                    font-size: 1.3em;
                    width: 100%;
                    text-align: center;
                    font-weight: 700;
                    color: #fff;
                }
                .close{
                    color: #fff;
                    z-index: 999;
                    text-shadow: none;
                    opacity: 1;
                    padding: 0;
                    position: absolute;
                    right: 25px;
                    top: 25px;
                    outline: none;
                }
            }
            .modal-body{
                .user_filter_ul{
                    margin: 0;
                    padding: 0;
                    border:1px solid #ddd;
                    li.filter_admin{
                        border-bottom: 1px solid #ddd;
                        list-style-type:none;
                        a{
                            transition: all 0.5s;
                            &:hover{
                                transition: all .5s;
                                background: #26597d;
                                .thum-name{
                                    color:#fff;
                                }
                                .thum-number{
                                    color: #fff;
                                }
                            }
                            span.thumb-pic{
                                border-right: 1px solid #ddd;
                                padding: 5px;
                                img{
                                    border-radius: 50%;
                                    width: 32px;
                                    height: 32px;
                                }
                            }
                            span.thum-name{
                                color: #333;
                                font-size: 14px;
                                padding: 10px;
                                min-width: 395px;
                            }
                            span.thum-number{
                                color: #333;
                                font-size: 14px;
                                padding: 10px;
                                border-left: 1px solid #ddd;
                            }
                        }
                    }
                }
            }
        }
    }
}