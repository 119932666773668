.dk-employerListPageMain{
    background-color: #25455a;
    color: #fff;
    section {
        padding: 30px 0 35px;
        .section-title {
            margin: 0;
            padding: 0 0 15px;
            font-size: 24px;
            text-transform: uppercase;
            text-align: center;
            color: #fff;
            font-weight: 600;
        }
    }
    .dk-listContainer {
        width: 100%;
        max-width: 1400px;
        margin: auto;
        padding: 0 15px;
        .employers-cols {
            position: relative;
            word-wrap: break-word;
            background-color: #fff;
            background-clip: border-box;
            border: 1px solid rgba(0,0,0,.125);
            border-radius: 5px;
            padding: 10px 0;
            label {
                width: 100%;
                margin: 0 15px;
                input, select.form-control{
                    border: 1px solid #ccc;
                    font-size: 14px;
                    border-radius: 0;
                }
                .dk-emplistsrcDrpDwn{
                    width: 100%;
                    padding-top: 18px;
                    .dropdown {
                        background-color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid #ccc;
                        position: relative;
                        a {
                            color: #696262;
                            font-size: 14px;
                            width: 100%;
                            padding: 7px 10px;
                            text-align: center;
                            &:after {
                                color: #696262;
                                content: "";
                                font-family: "Dropdown";
                                position: absolute;
                                right: 13px;
                                font-weight: 400;
                                opacity: .9;
                            }
                        }
                        .dropdown-menu{
                            display: block;
                            width: 100%;
                            border-radius: 0;
                            padding: 0;
                            border-color: currentcolor #96c8da #96c8da;
                            border-top: #96c8da;
                            box-shadow: 0 2px 3px 0 rgba(0,0,0.38,0.15);
                            input.form-control {
                                border-radius: 0;
                                border: none;
                                border-bottom: 1px solid grey;
                                font-size: 14px;
                            }
                            ul {
                                margin-bottom: 0;
                                max-height: 220px;
                                overflow-y: auto;
                                .dropdown-item {
                                    background-color: #fff;
                                    border-top: 1px solid #ddd;
                                    padding: 0.5rem 0.4rem;
                                    color: #00f;
                                    text-align: left;
                                    font-size: 13px;
                                    width: 100%;
                                    &:first-child {
                                        border-top: none;
                                    }
                                    &::after{
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
                button.btn-default {
                    padding: 8px 12px;
                    background-color: #25455a!important;
                    color: #fff!important;
                    height: 38px;
                    text-transform: uppercase;
                    font-size: 12px;
                    border: 1px solid #bbaeae;
                    font-weight: 700;
                    border-radius: 5px;
                    position: relative;
                    width: 97%;
                    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
                }
            }
        }
        .featured-companies{
            .card {
                border-radius: 0.25rem;
                font-weight: 400;
                border: 0;
                box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
                .card-head {
                    text-align: center;
                    padding: 2rem 1.25rem;
                    a{
                        img{
                            max-width: 100%;
                            height: auto;
                        }
                    }
                }
                .card-footer {
                    padding: 0.5rem 0.75rem;
                    background-color: rgba(0,0,0,.03);
                    border-top: 1px solid rgba(0,0,0,.125);
                    .font-size-sm{
                        .company-name {
                            color: #333;
                        }
                        a {
                            color: #007bff;
                            cursor: pointer;
                            &:hover{
                                color: #0056b3;
                            }
                        }
                    }
                }
            }
        }
    }
    .dk-emplistPageination{
        .pagination.pagination-circle{
            color: #ccc;
            .page-item{
                .page-link {
                    background: #325f7d;
                    border:1px solid #325f7d ;
                    color: #fff;
                    height: 40px;
                    width: 40px;
                    margin-right: 3px;
                    margin-left: 3px;
                    font-size: 1.3em;
                    padding: 8px 15px;
                    border-radius: 50%;
                }
                &.active{
                    .page-link {
                        background: #ffee89;
                        color: #333;
                        border: 1px solid #ffee89;
                    }
                }
            } 
        }
    }
}