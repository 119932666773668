.dk-paymentMethodMain{
    padding: 35px 15px;
    .dk-paymentHead {
        position: relative;
        h3 {
            text-align: center;
            font-weight: 700;
            font-size: 20px;
            text-transform: uppercase;
            span {
                cursor: pointer;
                display: inline-block;
                margin-left:5px;
            }
        }
    }
    .dk-paymentMethod-cols {
        background: #d9d9d9;
        box-shadow: 0 0 10px rgba(0,0,0,0.10);
        padding: 25px;
        margin-top: 0;
        border: 1px solid #ccc;
        .dk-paymentBox{
            background: #fff;
            box-shadow: 0 0 5px rgba(0,0,0,0.12);
            min-height: 170px;
            .cardImg-dropdown {
                padding: 15px;
                .dk-CardImg {
                    width: 100%;
                    max-width: 65px;
                }
            }
            .dk-cardDropdown{
                .dropdown{
                    .dropdown-toggle {
                        padding: 0;
                        margin: 0;
                        box-shadow: none;
                        background-color: transparent!important;
                        color: #1f394c;
                        font-size: 20px;
                        line-height: 0;
                        border: none;
                        &::after{
                            display: none;
                        }
                    }
                    .dropdown-menu {
                        border-radius: 5px;
                        padding: 0;
                        border: none;
                        background: #f2f2f2;
                        color: #000;
                        left: inherit!important;
                        right: 0!important;
                        -webkit-transform: inherit!important;
                        transform: inherit!important;
                        top: 30px!important;
                        &:before {
                            content: "";
                            display: inline-block;
                            width: 0;
                            height: 0;
                            border-color: transparent transparent #f2f2f2;
                            border-style: solid;
                            border-width: 0 12.5px 15px;
                            line-height: 0;
                            _border-color: #000 #000 #f2f2f2 #000;
                            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                            position: absolute;
                            top: -15px;
                            right: 3px;
                        }
                        a.dropdown-item{
                            display: block;
                            padding: 5px 15px;
                            color: #333;
                            border-bottom: 1px solid #ccc;
                            &:hover {
                                background: #1f394c;
                                color: #fff;
                            }
                            &:last-child{
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
            .card-rating {
                padding: 0 15px;
                .dk-payment-star {
                    color: #666;
                    margin-top: 15px;
                    font-weight: 600;
                }
            }
            .dk-paymentFoot {
                background: #f2f2f2;
                padding: 8px 15px;
                margin-top: 25px;
                border-top: 1px solid #ccc;
                .dk-cardName {
                    font-size: 14px;
                    text-transform: uppercase;
                    color: #0089ff;
                    line-height: 1.3rem;
                    font-weight: 600;
                    span {
                        font-size: 12px;
                        display: block;
                        text-transform: none;
                        color: #666;
                        font-weight: 400;
                    }
                }
                .dk-cardExpire {
                    font-weight: 700;
                    line-height: 1.3rem;
                    span {
                        font-weight: 400;
                        display: block;
                        font-size: 12px;
                    }
                }
            }
            a.dk-addCard {
                color: #666;
                font-size: 14px;
                font-weight: 600;
                text-align: center;
                text-transform: uppercase;
                span {
                    display: block;
                    font-size: 16px;
                }
            }
        }
    }
}