.dk-notificationPage{
    margin-top: 40px;
    h2{
        text-transform: uppercase;
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 25px;
    }
    .dk-prefrenceCols{
        width: 100%;
        max-width: 60%;
        margin: auto;
        background: #e5e5e5;
        padding: 20px;
        border: 1px solid #dcdcdc;
        .dk-emailNotification{
            text-align: left;
            .sbtitle{
                font-weight: 700;
                font-size: 16px;
                margin-bottom: 5px;
                text-align: left;
            }
        }
        .dk-emailNotification-cols{
            padding: 10px 0;
            .dk-emailNotification-box{
                padding: 25px;
                border: 1px solid #ccc;
                border-bottom: none;
                background: #fff;
                .dk-emailColsLeft{
                    h4{
                        margin: 0;
                        font-size: 16px;
                        font-weight: 600;
                        text-align: left;
                    }
                    p{
                        margin: 5px 0;
                        font-size: 13px;
                        color: #2d96b6;
                    }
                }
                .dk-emailColsRight{
                    .custom-control-input {
                        position: absolute;
                        left: 0;
                        z-index: -1;
                        width: 1rem;
                        height: 1.25rem;
                        opacity: 0;
                    }
                    .custom-control-label{
                        &::before{
                            width: 3.5rem;
                            height: 1.6rem;
                            border-radius: 30px;
                            background: #ccc;
                            color: #fff;
                            border-color: #2d96b6;
                            background-color: #2d96b6;
                        }
                        &::after{
                            width: 19px;
                            height: 19px;
                            border-radius: 50%;
                            background: #fff;
                            transform: translateX(1.85rem);
                        }
                    }
                }
            }
        }
    }
}