.dk-ds-sub-menu {
    position: relative;
    width: 100%;
    top: 0;
    .dk-ds-sub-menu-cols {
        background: #eaeaea;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-left: 0.05rem;
        ul{
            list-style: none;
            margin-bottom: 0;
            display: flex;
            li{
                width: 100%;
                max-width: 11.1%;
                a{
                    width: 100%;
                    display: block;
                    color: #333;
                    font-weight: 600;
                    font-size: 14px;
                    padding: 12px 0;
                    text-align: center;
                    border-right: 1px solid #c7c0c0;
                    &.sub-active, &:hover{
                        background-color: #fff;
                    }
                    .badge{
                        box-shadow: none;
                        background: #2d96b6;
                        border-radius: 3px;
                        padding: 4px 6px;
                        margin: 0 2px;
                        color:#fff;
                    }
                    .badge.red{
                        background: #f44336!important;
                    }
                }
            }
        }
    }
}
