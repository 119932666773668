.dk-savedJobsMain{
    width: 100%;
    background: #f2f2f2;
    padding:35px 0;
    .title{
        color: #333;
        margin: 0;
        font-size: 24px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
    }
    .dk-savedJobs-fluid{
        width: 100%;
        max-width: 1600px;
        margin: auto;
        margin-top:20px;
        padding-right: 30px;
        .dk-savedJobs-container{
            width: 100%;
            max-width: 1423px;
            margin-left: auto;
            .dk-savedJobs-cols{
                background: #fff;
                border:1px solid #ddd;
                margin-bottom: 12px;
                .dk-boxes-cols{
                    width: 100%;
                    .dk-boxes-cols-img{
                        text-align: center;
                        padding:12px 0 0;
                        img{
                            width: 100%;
                            max-width: 95px;
                        }
                        .dk-boxes-btn{
                            display: block;
                            width: 100%;
                            max-width: 170px;
                            margin: 5px auto;
                            padding: 5px 0;
                            border-radius: 0;
                            font-size: 14px;
                            font-weight: 700;
                            color: #0170c1;
                            border:1px solid #0170c1;
                        }
                    }
                    .dk-boxes-cols-right{
                        .dk-boxes-cols-right-cols{
                            .title{
                                font-size: 16px;
                                margin-bottom: 2px;
                                display: block;
                                color: #184354;
                                font-weight: 700;
                                pointer-events: none;
                                text-align: left;
                                margin: 12px 0;
                            }
                            .dk-boxes-right-cols-desc{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin-top: 15px;
                                width: 100%;
                                max-width: 325px;
                                ul{
                                    li{
                                        list-style-type: none;
                                        font-size: 13px;
                                        width: 100%;
                                        position: relative;
                                        margin-bottom: 7px;
                                        color: #000;
                                        display: flex;
                                        white-space: nowrap;
                                        align-items: center;
                                        i{
                                            color: #9c9c9c;
                                            display: inline-block;
                                            width: 22px;
                                            text-align: left;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .dk-actionBtn{
                    margin-top: 0;
                    background: #67818e;
                    height: auto;
                    padding:20px;
                    display: block;
                    width: 100%;
                    max-width: 230px;
                    a{
                        display: block;
                        width: 100%;
                        max-width: 180px;
                        margin: auto;
                        clear: both;
                        display: block;
                        width: 100%;
                        max-width: 100%;
                        text-align: center;
                        padding: 0;
                        height: 35px;
                        line-height: 35px;
                        font-size: 14px;
                        background: #f2f2f2;
                        color: #333;
                        border: 1px solid #ccc;
                        margin: 0 0 5px;
                    }
                }           
            }
            .dk-savedJob-company{
                .dk-companiesBox{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 25px 15px;
                    margin-bottom: 10px;
                    border:1px solid #ddd;
                    background: #fff;
                }
            }
        }
    }
}

.dk-savedJobsPagination{
    text-align: center;
    ul{
        list-style: none;
        display: flex;
        justify-content: center;
        margin:30px 0 0;
        padding: 0;
        li{
            list-style-type: none;
            a{
                background: #67818e;
                color: #fff;
                height: 40px;
                width: 40px;
                margin-right: 3px;
                margin-left: 3px;
                font-size: 1.3em;
                border-radius: 50%;
                display: inline-block;
                line-height: 40px;
                text-align: center;
                &:hover{
                    background: #ffee89;
                    color: #000;
                    transition: all 0.5s;
                }
            }
            a.active{
                background: #ffee89;
                color: #000;
            }
        }
    }
}