.dk-packagesMainEmpSt{
    padding:25px 15px;
    position: relative;
    h3.title{
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        text-transform: uppercase;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        top:25px;
    }
    .dk-leftTabs{
        width: 100%;
        max-width: 150px;
        margin-right: 35px;
        margin-top: 40px;
        .nav-pills{
            .nav-item{
                list-style-type: none;
                position: relative;
                display: block;
                width: 100%;
                a{
                    display: block;
                    background: #234158;
                    padding: 8px 15px;
                    border-bottom: 1px solid #2e5a7c;
                    color: #fff;
                    text-transform: capitalize;
                    position: relative;
                    border-radius: 0;
                }
                a.active{
                    background: #67818e;
                    &::after{
                        content: "\f0da";
                        display: inline-block;
                        font-family: "Font Awesome 5 Free";
                        font-size: 18px;
                        font-weight: 700;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        position: absolute;
                        right: 10px;
                        top:9px;
                    }
                }
            }
        }
    }
    .tab-content{
        width: 100%;
        .dk-tabsPanel-body{
            background: #d9d9d9;
            border: 1px solid #b9b9b9;
            padding: 25px;
            width: 100%;
            margin-top:40px;
            .dk-renewCols {
                background: #fff;
                padding: 25px;
                box-shadow: 2px 0 7px rgba(0,0,0,0.04);
                .dk-renewBox{
                    .dk-renewLeft{
                        width: 100%;
                        .dk-itemsImg{
                            img{
                                width: 100%;
                                max-width: 45px;
                            }
                            .expiredText{
                                font-weight: 700;
                                color: #c12f2f;
                                margin-left: 10px;
                                position: relative;
                                top: 3px;
                            }
                            .expiredText.active{
                                color: green;
                            }
                        }
                        .dk-renewPlan{
                            padding: 0;
                            .title{
                                font-weight: 700;
                                font-size: 15px;
                                p{
                                    margin: 0;
                                    font-size: 12px;
                                }
                            }
                        }
                        .dk-renew-Expires.greenText{
                            color: green;
                            max-width: 140px;
                        }
                        .dk-renew-Expires{
                            width: 100%;
                            max-width: 130px;
                            font-size: 13px;
                            font-weight: 600;
                            color: #c12f2f;
                            margin: 0 15px;
                        }
                        .viewDetail{
                            a{
                                display: inline-block;
                                background: #3b97b6;
                                padding: 10px 20px;
                                color: #fff;
                                text-transform: uppercase;
                                font-weight: 600;
                                border-radius: 5px;
                            }
                        }
                    }
                }
            }
        }
        .dk-package-mt{
            margin-top:40px;
        }
        .dk-packagesPlanMain{
            background: #f0f0f0;
            box-shadow: 0 0 10px rgba(0,0,0,0.10);
            border: 1px solid #e0e0e0;
            .nav-pills{
                flex-wrap: nowrap;
                width: 100%;
                .nav-item{
                    width: 100%;
                    max-width: 100%;
                    flex:100%;
                    a{
                        width: 100%;
                        border: none;
                        color: #333;
                        background: #fff;
                        border-radius: 0;
                        border-bottom: 2px solid #f0f0f0;
                        background: #fff;
                        color: #333;
                        font-weight: 600;
                        font-size: 16px;
                        padding: 17px 15px 15px;
                        width: 100%;
                        text-align: center;
                    }
                    a.active{
                        background: #f0f0f0;
                    }
                }
            }
            .dk-planLeft-body{
                padding: 0 25px 15px;
                background: #f0f0f0;
                .dk-planLeft-row{
                    padding: 10px 0 15px;
                    .title{
                        font-weight: 600;
                        font-size: 15px;
                        color: #333;
                        text-transform: uppercase;
                        display: inline-block;
                        position: relative;
                        top: 22px;
                    }
                    .dk-packRangeSlide{
                        p{
                            float: right;
                            text-transform: uppercase;
                            font-weight: 500;
                            margin: 0;
                            font-size: 15px;
                            color: #333;
                        }
                        div{
                            width: 100%;
                            height: auto;
                        }
                        .MuiSlider-root{
                            padding-bottom: 3px;
                            .MuiSlider-thumb{
                                .PrivateValueLabel-label-15{
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
        .dk-buyRight{
            background: #f0f0f0;
            box-shadow: 0 0 10px rgba(0,0,0,0.10);
            padding: 15px;
            border: 1px solid #e0e0e0;
            min-height: 455px;
            .dk-buyRIght-img{
                width: 100%;
                max-width: 130px;
                margin: 21px auto;
            }
            .dk-buyRightPrice{
                background: #f2f2f2;
                padding: 25px 15px;
                text-align: center;
                p{
                    margin: 5px 0;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }
            .dk-buyRightDetails{
                text-align: center;
                padding: 15px 0;
                p{
                    margin: 0;
                    font-size: 16px;
                }
            }
            .dk-buyPlanBtn{
                text-align: center;
                padding-bottom: 10px;
                button{
                    border: none;
                    background: #3b97b6;
                    outline: none;
                    color: #fff;
                    text-transform: uppercase;
                    font-weight: 600;
                    padding: 8px 20px;
                    border-radius: 5px;
                }
            }
        }
    }
    
}