.dk-darMain{
    background-color:#1f4353;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .sm-container{
        width:100%;
        max-width: 500px;
        padding: 0;
        background: #fff;
        border-radius: 5px;
        .title{
            text-transform: uppercase;
            font-weight: 700;
            font-size: 20px;
            text-align: center;
            padding: 15px 0;
            border-bottom: 1px solid #ddd;
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
        }
        .dk-cols-12{
            padding: 15px;
            svg{
                font-size: 42px;
                width: 100%;
            }
            .formsubmit-button{
                border: none;
                padding: 10px 45px;
                color: #fff;
                border-radius: 5px;
                font-size: 14px;
                background-color: #25455a;
                letter-spacing: 0.02857em;
                text-transform: uppercase;
            }
            .dk-loading{
                width: auto !important;
                padding:25px 0 !important;
                svg{
                    width: 55px !important;
                    height: auto !important;
                }
            }
            form{
                .loginsubmit-buttonCon{
                    margin-top: 10px;
                    .loginsubmit-btn{
                        background: #1f4353;
                        color: #fff;
                        min-width: 250px;
                        font-size: 13px;
                        border-radius: 5px;
                        border: 1px solid #9d265c;
                        text-transform: uppercase;
                        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
                    }
                    .loginsubmit-btn:hover{
                        background: #25455a;
                        border: 1px solid #9d265c;
                    }
                }
            }
        }
    }
}