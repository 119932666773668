.dk-loginEmpMainBg{
    width: 100%;
    height: 100vh;
    background: #25455a;
    display: flex;
    align-items: center;
    justify-content: center;
    .container{
        .dk-job-empFromCon{
            .dk-job-empFromTitle{
                h2{
                    color: #fff;
                    padding-bottom: 15px;
                    text-align: center;
                }
            }
            .dk-job-empFromBox{
               background-color: #fff;
               width: 100%;
               padding: 25px 30px;
               border-radius: 5px;
                .nav-pills.dk-loginTabs{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border: 1px solid #c7c7c7;
                    border-radius: 24px;
                    margin: 20px auto;
                    line-height: 36px;
                    padding: 5px;
                    border-radius: 24px;
                    font-size: 13px;
                    li{
                        list-style-type: none;
                        width: 100%;
                        min-width: 48%;
                        max-width: 48%;
                        white-space: nowrap;
                        a{
                            border-radius: 19px;
                            display: block;
                            padding: 0 8px;
                            transition: all .5s;
                            font-weight: 600;
                            color: #333;
                            text-transform: uppercase;
                            text-align: center;
                        }
                        a.emp-standard.active, a.emp-standard:hover{
                            background: #67818e;
                            color: #fff;
                        }
                        a.emp-premium.active, a.emp-premium:hover{
                            background: #2e95b4;
                            color: #fff;
                        }
                    }
                }
                .dk-loginTabpaneCon{
                    color: #333;
                    margin: 0 auto;
                    width: 100%;
                    padding-left: 15px;
                    padding-right: 15px;
                    form{
                        .form-group{
                            margin-bottom: 5px;
                            input.form-control{
                                font-size: 13px;
                                background: #fff;
                                height: 35px;
                                border-radius: 0;
                                border: 1px solid #ccc;
                                padding: 5px 10px;
                                color: #67818e;
                            }
                            input.form-control:focus{
                                box-shadow: none;
                            }
                            input.form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                                color: #67818e;
                            }
                            input.form-control::-moz-placeholder { /* Firefox 19+ */
                                color: #67818e;
                            }
                            input.form-control:-ms-input-placeholder { /* IE 10+ */
                                color: #67818e;
                            }
                            input.form-control:-moz-placeholder { /* Firefox 18- */
                                color: #67818e;
                            }
                        }
                        .dklogin-checkBoxCon{
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            padding-right: 15px;
                            padding-left: 15px;
                            margin-top: 1rem;
                            input[type="checkbox"]{
                                width: 14px; /*Desired width*/
                                height: 14px; /*Desired height*/
                                cursor: pointer;
                                -webkit-appearance: none;
                                appearance: none;
                                border: 2px solid #0000008a;
                            }
                            label{
                                padding-left: 12px;
                                margin-bottom: 0;
                            }
                        }
                        .agree-termsCon{
                            display: flex;
                            flex-flow: row wrap;
                            align-items: center;
                            justify-content: center;
                            font-size: 14px;
                            margin: 15px 0 0;
                            p{
                                margin-bottom: 0;
                                a{
                                    padding-left: 3px;
                                    padding-right: 3px;
                                }
                            }
                        }
                        .loginsubmit-buttonCon{
                            margin-top: 10px;
                            text-align: center;
                            .loginsubmit-btn{
                                background: #9d265c;
                                color: #fff;
                                min-width: 250px;
                                font-size: 13px;
                                border-radius: 5px;
                                border: 1px solid #9d265c;
                                text-transform: uppercase;
                                box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
                            }
                            .loginsubmit-btn:hover{
                                background: #25455a;
                                border: 1px solid #9d265c;
                            }
                        }
                    }
                }
            }
            .login-bottomSec {
                display: block;
                margin: 30px 0 0;
                width: 100%;
                text-align: center;
                li {
                    list-style-type: none;
                    a{
                        display: block;
                        color: #fff;
                        margin: 15px 0 0;
                        font-weight: 400;
                        letter-spacing: .5px;
                    }
                    a:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}