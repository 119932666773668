.dk-recruitmentMain{
    padding-top: 0;
    h1{
        margin-bottom: 35px;
        font-size: 24px;
        font-weight: 600;
        position: relative;
        &::after{
            content: "";
            width: 100%;
            position: absolute;
            bottom: -15px;
            left: 50%;
            -webkit-transform: translate(-50%);
            transform: translate(-50%);
            max-width: 252px;
            height: 1px;
            background: #ccc;
        }
        &::before{
            content: "";
            width: 100%;
            position: absolute;
            bottom: -15px;
            left: 50%;
            -webkit-transform: translate(-50%);
            transform: translate(-50%);
            max-width: 70px;
            height: 4px;
            background: #2d96b6;
            border-radius: 10px;
        }
        span{
            font-weight: 700;
            color: #333;
        }
    }
    p{
        color: #444;
    }
    .dk-recruitmentCols{
        width: 100%;
        max-width: 900px;
        margin: auto;
        padding: 30px 0;        
    }
    .dk-whitis{
        background: #e7eff2;
        padding: 35px 15px;
        h1{
            font-size: 24px;
            font-weight: 600;
            position: relative;
        }
    }
    .dk-recruitmentBox{
        margin-top: 25px;
        padding-right: 30px;
        h3{
            position: relative;
            color: #1d618e;
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 700;
            &::before{
                content: "\f14a";
                display: inline-block;
                font-family: "Font Awesome 5 free";
                font-size: 21px;
                font-weight: 700;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                margin-right: 12px;
                position: relative;
                top: 1px;
            }
        }
        p{
            margin-left: 21px;
            padding: 5px 10px;
        }
    }
    .dk-contactDetail{
        background: #1d618e;
        padding: 35px 15px;
        h1{
            color: #fff;
            margin-bottom: 55px;
            font-size: 24px;
            font-weight: 600;
            position: relative;
            &::before{
                max-width: 50px;
                background: #fff;
            }
            &::after{
                max-width: 175px;
                background: #fff;
            }
            span{
                color: #fff;
            }
        }
        .dk-aboutLogo{
            display: inline-block;
            background: #fff;
            padding: 37px 30px;
            border-radius: 50px 0;
            box-shadow: 3px 4px 8px #073b5d;
            img{
                max-width: 225px;
            }
        }
        .dk-contactText{
            li{
                position: relative;
                list-style-type: none;
                color: #fff;
                font-size: 14px;
                padding-left: 22px;
                margin-bottom: 13px;
                i{
                    position: absolute;
                    left: 0;
                    top: 1px;
                    font-size: 16px;
                }
                strong{
                    text-transform: uppercase;
                    font-size: 15px;
                    font-weight: 700;
                    display: block;
                    line-height: 15px;
                }
            }
        }
    }
}