.dk-jobDetails-main{
    background-color: #243b4c;
    padding: 30px 15px 50px;
    .dk-job-backbtn{
        text-align: left;
        padding: 0 42px;
        a{
            display: inline-block;
            font-weight: 400;
            border: 1px solid #fff;
            padding: 6px 15px;
            border-radius: 5px;
            color: #fff;
            transition: all .5s;
            opacity: .5;
            &:hover{
                background-color: #ccc;
                border-color: #ccc;
                color: #000;
                transition: all .5s;
            }        
        }
    }
    .container-fluid{
        width: 100%;
        max-width: 1600px;
        margin: auto;
    }
    .dk-job-leftpanel{
        .dk-job-leftpanel-profile{
            background: #28485f;
            border: 1px solid #456e8c;
            border-bottom: 0;
            border-radius: 5px 5px 0 0;
            .dk-job-leftpanel-text{
                padding: 15px 15px 0;
                border-right: 1px solid #456e8c;
                .dk-job-leftpanel-text-cols{
                    margin-right: 0;
                    margin-bottom: 30px;
                    width: 100%;
                    max-width: 25%;
                    .title{
                        position: relative;
                        font-weight: 700;
                        color: #847e7e;
                        padding-left: 23px;
                        font-size: 13px;
                        &::before{
                            content: "";
                            background: url('../../../assets/images/location_map.png') no-repeat;
                            padding: 8px;
                            position: absolute;
                            top: 3px;
                            left: 0;
                        }
                    }
                    .jobnumber{
                        padding-left: 23px;
                        font-size: 13px;
                        color: #fff;
                    }
                }
            }
        }
        .dk-leftpanel-cols-profile{
            width: 100%;
            margin:0;
            .dk-jobs-btn{
                margin: 13px 0;
                text-align: center;
                img{
                    width: 100%;
                    max-width: 150px;
                    background: #fff;
                    padding: 8px 15px;
                    border-radius: 5px;
                }
            }
            .dk-viewCols{
                border-top: 1px solid #456e8c;
                li{
                    list-style-type: none;
                    width: 100%;
                    max-width: 50%;
                    border-right: 1px solid #456e8c;
                    padding:18px 0;
                    color: #fff;
                    text-align: center;
                    &:last-child{
                        border-right:none;
                    }
                    b{
                        display: block;
                        color: #f4d048;
                    }
                }
            }
        }
        .dk-job-leftpanel-content{
            padding: 15px;
            background: #243b4c;
            border: 1px solid #456e8c;
            border-radius: 0 0 5px 5px;
            h1{
                margin-bottom: 20px;
                color: #f4d03e;
                text-align: left;
                font-size: 22px;
                font-weight: 700;
            }
            h3{
                margin: 0 0 10px;
                padding: 0;
                font-size: 14px;
                font-weight: 700;
                color: #f4d03e;
            }
            p{
                color: #fff;
                opacity: .8;
            }
            .dkjobLeftCols{
                ul{
                    margin-bottom: 25px;
                    h3{
                        margin: 0 0 10px;
                        padding: 0;
                        font-size: 14px;
                        font-weight: 700;
                        color: #f4d03e;
                    }
                    li{
                        list-style-type: disc;
                        margin: 0 35px;
                        color: #fff;
                        opacity: 0.8;
                    }
                }
            }
        }
    }
    .dk-leftPanelJobs{
        .dk-leftPanelJobs-cols{
            .dk-jobProfile{
                background: #f4d048;
                padding: 25px 15px;
                position: relative;
                min-height: 90px;
                border-radius: 5px 5px 0 0;
                .dk-jobProfileImg{
                    width: 100%;
                    max-width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    position: absolute;
                    bottom: -40px;
                    left: 50%;
                    -webkit-transform: translate(-50%);
                    transform: translate(-50%);
                    img{
                        border-radius: 50%;
                        width: 100px;
                        height: 100px;
                    }
                }
            }
            .dk-jobsText{
                background: #28485f;
                border-left: 1px solid #456e8c;
                border-right: 1px solid #456e8c;
                border-bottom: 1px solid #456e8c;
                border-radius: 0 0 5px 5px;
                .dk-Jobs-profileTitle{
                    padding: 60px 0 25px;
                    text-align: center;
                    color: #fff;
                    h4{
                        margin: 0;
                        font-size: 18px;
                        font-weight: 500;
                    }
                    p{
                        font-size: 14px;
                        color: #ccc;
                        margin: 0;
                    }
                    .dk-socialIcon{
                        margin: 10px 0;
                        display: block;
                        a{
                            margin: 0 5px;
                            color: #f4d048;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
    .dk-job-rightpanel{
        margin: 98px 0 0;
        h2{
            text-align: center;
            font-size: 22px;
            font-weight: 700;
            padding: 6px 0;
            color: #888686;
            letter-spacing: 1px;
            margin-bottom:3px;
        }
        .dk-job-rightpanel-cols{
            width: 100%;
            height: 645px;
            border: 1px solid #456e8c;
            margin-top: 0;
            border-radius: 5px;
            background: #28485f;
            .dk-job-leftpanel-text{
                padding:20px 15px;
                justify-content: space-between;
                .dk-job-leftpanel-text-cols{
                    margin-right: 0;
                    margin-bottom: 30px;
                    width: 100%;
                    max-width: 50%;
                    .title{
                        position: relative;
                        font-weight: 700;
                        color: #847e7e;
                        padding-left: 23px;
                        font-size: 13px;
                        &::before{
                            content: "";
                            background: url('../../../assets/images/location_map.png') no-repeat;
                            padding: 8px;
                            position: absolute;
                            top: 3px;
                            left: 0;
                        }
                    }
                    .jobnumber{
                        padding-left: 23px;
                        font-size: 13px;
                        color: #fff;
                    }
                }                
            }
        }
    }

    .dk-blankBox{
        width: 100%;
        margin: 30px 15px 0;
        .dk-boxPanel{
            width: 100%;
            height: 280px;
            background: #28485f;
            border-right: none!important;
            border: 1px solid #456e8c;
            &:last-child{
                border: 1px solid #456e8c!important;
            }
            .title{
                text-align: center;
                color: #fff;
                padding: 9px 12px;
                font-weight: 400;
                font-size: 15px;
                border-bottom: 1px solid #456e8c;
                background: rgba(69,110,140,0.43);
                text-transform: uppercase;
            }
            .dk-attachedFile{
                .dk-modal-top-cols{
                    padding: 0 25px;
                    border: none!important;
                    margin: 25px 0 35px;
                    color: #fff;
                    .dk-attachment{
                        .title{
                            padding: 0 0 6px;
                            color: #ccc;
                            border: none;
                            font-weight: 400;
                            font-size: 15px;
                            text-align: left!important;
                            background: transparent!important;
                            text-transform: capitalize!important;
                        }
                        .dk-attachCols{
                            position: relative;
                            &::before{
                                content: "Choose File";
                                display: inline-block;
                                background: #456e8c;
                                border: none;
                                border-radius: 3px;
                                padding: 4px 9px;
                                outline: none;
                                white-space: nowrap;
                                -webkit-user-select: none;
                                cursor: pointer;
                                position: absolute;
                                left: 7px;
                                top: 3px;
                                pointer-events: none;
                            }
                            &::after{
                                content: "No File Selected";
                                display: inline-block;
                                background: #28485f;
                                border: none;
                                border-radius: 0;
                                padding: 4px 9px;
                                outline: none;
                                white-space: nowrap;
                                -webkit-user-select: none;
                                cursor: pointer;
                                position: absolute;
                                left: 99px;
                                top: 3px;
                                pointer-events: none;
                            }
                            input{
                                padding: 2px 8px;
                                background: #28485f;
                                color: #fff;
                                border: 1px solid #365973;
                            }
                            span{
                                position: absolute;
                                right: 8px;
                                background: #2d96b6;
                                top: 3px;
                                padding: 3px 15px;
                                border-radius: 4px;
                                cursor: pointer;
                                display: none;
                            }
                        }
                    }
                }
            }
            .dk-infoCols{
                p{
                    margin: 0;
                    padding: 12px 10px 8px;
                    color: #ccc;
                    font-size: 13px;
                }
                .dk-infozBox{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 15px 0 0;
                    padding: 0 12px;
                    label{
                        color: #ccc;
                        margin:0;
                        &:last-child{
                            width: 100%;
                            max-width: 250px;
                        }
                        input{
                            height: 30px;
                            background: #28485f;
                            border: 1px dotted #456e8c;
                        }
                    }
                    .dk-yesnoCheckbox{
                        width: 100%;
                        max-width: 250px;
                        display: flex;
                        align-items: center;
                        input[type=radio]{
                            margin-right: 5px;
                            position: relative;
                            top: 0;
                            width: 40px!important;
                            height: 18px!important;
                        }
                        label{
                            margin-right: 10px;
                        }
                    }
                }
                label.boxTeaxtarea{
                    width: 100%;
                    .sbTitle{
                        text-align: center;
                        padding: 7px 0;
                        color: #ccc;
                    }
                    textarea{
                        width: 100%;
                        height: 202px;
                        background: #28485f;
                        border: #456e8c;
                        resize: vertical;
                        overflow-y: auto;
                        color: #ccc;
                        border-top: 1px dotted #456e8c;
                    }
                }
            }
        }
        .saveJobCols{
            color: #ccc;
            background: #28485f;
            padding: 10px 0 28px;
            border: 1px solid #456e8c;
            border-top: none;
            .checkboxCols{
                margin-bottom: 15px;
                label{
                    margin: 0 0 0 8px;
                }
            }
            button{
                border: none;
                background: #182834;
                margin: 0 5px;
                padding:10px 20px;
                border-radius: 4px;
                color: #ccc;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 14px;
                outline: none;
                &:last-child{
                    background: #f4d048;
                    color: #333;
                    padding:9px 20px;
                }
            }
        }
    }
    .dk-similarjobsTitle{
        text-align: center;
        font-size: 24px;
        color: #ccc;
        font-weight: 600;
        margin: 0;
        margin-top:25px;
    }
    .dk-similerjobs{
        width: 100%;
        height:230px;
        border: 1px solid #456e8c;
        margin-top: 0;
        border-radius: 5px;
        background: #28485f;
        margin-top:10px;
    }
}