.dk-doctitleModal{
    .modal-dialog{
        min-width: 500px;
        max-width: 500px;
        .modal-content{
            border: none;
            background-color: transparent;
            .dk-doctitleModalHeader{
                padding: 8px;
                text-align: center;
                width: 100%;
                background: #1f394c;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px 5px 0;
                .modal-title {
                    font-weight: 700;
                    font-size: 18px;
                    width: 100%;
                    text-align: center;
                }
                .close{
                    opacity: 1;
                    span{
                        color: #fff;
                        text-shadow: none;
                        &:hover{
                            opacity: 1;
                        }
                    }
                }
            }
            .dk-doctitleModalBody{
                background-color: #fff;
                border-radius: 0 0 5px 5px;
            }
        }        
    }
}
