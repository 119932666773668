.dk-forgotPassMain{
    background-color: #1f4353;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .dk-forgotCols{
        width: 100%;
        max-width: 500px;
        .title{
            color: #fff;
            text-transform: uppercase;
        }
        .dk-forgotContainer{
            background: #fff;
            padding: 25px;
            border-radius: 10px;
            form{
                label{
                    width: 100%;
                    input{
                        border-radius: 0;
                        height: 38px;
                    }
                    .dk-sendBtn{
                        display: inline-block;
                        background: #1f4353;
                        color: #fff;
                        text-transform: uppercase;
                        padding: 10px 20px;
                        border-radius: 3px;
                        outline: none;
                        border:none;
                    }
                }
            }
        }
        a.dk-backLogin{
            font-size: 16px;
            font-weight: 500;
            color: #fff;
        }
        .dk-otpInput{
            input{
                height: 38px;
                border-radius: 3px;
                border: none!important;
                border-bottom: 1px solid #213e52!important;
                margin: 0 10px;
                border-radius: 0;
                padding: 0;
                text-align: center;
                width: 100%!important;
                outline: none;
            }
        }
        .dk-resendBtn{
            display: block;
            margin-top: 10px;
            font-weight: 600;
        }
    }
}