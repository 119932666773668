.dk-searchNewMain{
    width: 100%;
    margin-top:30px;
    padding:0 15px;
    .dk-searchNew-head{
        .dk-searchBoxFilter{
            border-radius: 0;
            margin: 0 0 15px;
            background: #f0f0f0;
            padding:5px;
            padding-right: 0;
            border:1px solid #ddd;
            .dk-jobs-list{
                .dk-jobsSearchBox{
                    position: relative;
                    input{
                        border-radius: 0;
                        height: 32px;
                        font-size: 14px;
                        border:1px solid #ccc !important;
                        box-shadow: none !important;
                        padding-right:30px;
                    }
                    &::after{
                        content: "\f002";
                        display: inline-block;
                        font-family: "Font Awesome 5 Free";
                        font-size: 12px;
                        font-weight: 700;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        position: absolute;
                        top: 7px;
                        right: 8px;
                        color: #333;
                    }
                }
            }
            .dk-jobs-cate-link{
                a{
                    font-size: 14px;
                    background: transparent;
                    color: #2d96b6;
                    padding: 0 5px;
                    border-radius: 0;
                    border: none;
                    margin: 0 4px;
                    i{
                        margin:0 3px;
                    }
                    &:last-child{
                        background: #234158;
                        border-radius: 0;
                        padding: 11px 30px 13px;
                        color: #fff;
                        text-transform: uppercase;
                        margin-left: 25px;
                        margin-right: 0;
                        font-weight:600;
                    }
                }
            }
        }
    }
    .dk-searchFilterPanel{
        width: 100%;
        max-width: 200px;
        background: #fff;
        box-shadow: none;
        margin-right:30px;
        margin-top: 0;
        .MuiPaper-root{
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            box-shadow: none;
            margin: 0 0 10px;
            border: 1px solid #dadada;
            &::before{
                top: 0;
            }
            .MuiAccordionSummary-root{
                padding: 0;
                min-height: auto;
                .MuiAccordionSummary-content{
                    margin:0;
                    .MuiTypography-root{
                        display: block;
                        padding: 10px 15px;
                        width: 100%;
                        flex: 100% 1;
                        background: #f0f0f0;
                        color: #676767;
                        text-transform: uppercase;
                        font-size: 12px;
                        font-weight: 600;
                        border-bottom: none;
                        i{
                            margin-right: 8px;
                            font-size: 14px;
                            display: none;
                        }  
                    }
                }
                .MuiButtonBase-root{
                    background: #f0f0f0;
                    border-radius: 0;
                    border: none;
                    height: auto;
                    padding: 0;
                    position: absolute;
                    right: 15px;
                    color: #333;
                }
            }
            .MuiCollapse-wrapper{
                .MuiCollapse-wrapperInner{
                    .MuiAccordionDetails-root{
                        padding: 0;
                        max-height: 200px;
                        overflow-y: auto;
                        overflow-x: hidden;
                        background: #fff;
                        .MuiTypography-root{
                            width: 100%;
                        }
                        .dk-searchPanelNew{
                            width: 100%;
                            padding: 5px 0 0;
                            .col{
                                flex: 100% 1;
                                padding: 5px 0 5px 21px!important;
                                .form-group{
                                    margin: 0 0 5px;
                                    padding: 0 10px;
                                    display: flex;
                                    input[type=checkbox]{
                                        border-radius: 0;
                                        margin-right: 6px;
                                        margin-top: 3px;
                                    }
                                    label{
                                        display: flex;
                                        justify-content: space-between;
                                        width: 100%;
                                        font-size: 13px;
                                        color: #333;
                                    }
                                    .custom-control-label{
                                        &::before{
                                            border-radius: 0;
                                            background: #fff;
                                            border-color: #ccc;
                                            top: 3px;
                                        }
                                        &::after{
                                            top: 3px;
                                        }
                                        span{
                                            color: #2d96b6;
                                        }
                                    }
                                }
                                .custom-checkbox{
                                    .custom-control-input:checked~.custom-control-label{
                                        &::before{
                                            color: #fff;
                                            border-color: #007bff;
                                            background-color: #007bff;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .dk-searchNew-detailPanel{
        width: 100%;
        .dk-searchNew-header{
            margin-bottom: 0;
            .childTitle{
                background: #fff;
                width: 100%;
                max-width: 100%;
                height: auto;
                margin-bottom: 4px;
                margin-top: 5px;
                text-align: center;
                font-size: 15px;
                color: #333;
                font-weight: 700;
                border-left: none;
                border-bottom: none;      
                &:first-child{
                    border-left: none;
                    width: 100%;
                    max-width: 30.5%;
                    .dk-jobs-list{
                        text-align: left;
                        font-weight: 400;
                        color: #333;
                    }
                }
            }
            .childTitle-right{
                display: flex;
                width: 100%;
                max-width: 70%;
                .childTitle{
                    
                }
            }
        }
        .dk-searchNew-col-12{
            padding: 7px 15px;
            position: relative;
            overflow: hidden;
            margin-bottom: 0;
            border: 1px solid #dcdcdc;
            border-bottom: none;
            .dk-searchNew-col-left{
                width: 100%;
                max-width: 30%;
                margin-right: 0;
                .dk-searchNew-col-img{
                    position: relative;
                    width: 100%;
                    max-width: 65px;
                    margin-right: 15px;
                    img{
                        width:65px;
                        height: 65px;
                        border-radius: 50%;
                    }
                }
                .dk-searchNew-col-detail{
                    .title{
                        a{
                            color: #000;
                            font-weight: 700;
                            font-size: 16px;
                            &:hover{
                                color: #2d96b6;
                                transition: all 0.5s;
                            }
                            i{
                                color: green;
                                margin-left:5px;
                            }
                            i.red-fa{
                                color: #f44336;
                            }
                        }
                    }
                    .textDetails{
                        padding-top: 5px;
                        li{
                            font-size: 14px;
                            list-style-type: none;
                            display: inline-block;
                            &:last-child{
                                margin-right: 0;
                                border-right: none;
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
            .dk-searchNew-col-right{
                width: 100%;
                max-width: 70%;
                padding-right: 15px;
                display: flex;
                justify-content: space-between;
                .dk-searchNew-rightCols{
                    display: flex;
                    width: 100%;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    line-height: 1.5rem;
                    &:last-child{
                        max-width: 115px;
                        padding-left: 45px;
                    }
                    .saveTag{
                        position: absolute;
                        background: #ffb607;
                        width: 90px;
                        color: #fff;
                        text-align: center;
                        -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
                        top: -7px;
                        right: -35px;
                        height: 36px;
                        line-height: 43px;
                    }
                    .saveTag.skyblue{
                        background: #2d96b6;
                    }
                    .saveTag.red{
                        background:#f44336!important;
                    }
                    a{
                        display: block;
                        width: 100%;
                        line-height: 1.4rem;
                        font-size: 14px;
                        font-weight: 600;
                        color: #2d96b6;
                        transition: all .5s;
                        &:hover{
                            color:#234158;
                            transition: all 0.5s;
                        }
                    }
                }
            }
            &:nth-last-of-type(02){
                border-bottom:1px solid #dcdcdc;
            }
        }
        .dk-searchNew-col-12.even{
            background: #f5f5f5;
        }

        .dk-searchJobs-pagination{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 15px;
            .pagination-circle.pagination{
                margin: 0;
                .page-item{
                    margin: 0 3px;
                    a{
                        height: 32px;
                        width: 32px;
                        padding: 0;
                        text-align: center;
                        line-height: 32px;
                        font-size: 15px;
                        box-shadow: 0 0 2px #000;
                        background: #fff;
                        color: #333;
                        display: inline-block;
                        border-radius: 50%;
                        &:hover{
                            background: #ccc;
                            border-color: #ccc;
                            color: #333;
                            transition: all 0.5s;
                        }
                    }
                    a.active{
                        background: #2d96b6;
                        border-color: #2d96b6;
                        color: #fff;
                    }
                }
            }
        }
    }
}

.dk-searchCvModalMain{
    .modal-dialog{
        width: 100%;
        max-width: 75%;
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 0;
        top: 60px;
        border-top-left-radius: 5px;
        bottom: 0;
        .modal-content{
            border-radius: 5px 0 0 5px;
            border: none;
            box-shadow: none;
            background:#23475f;
            height: 100vh;
            .modal-body{
                color: #fff;
                position: relative;
                padding:20px 0;
                overflow:hidden;
                .saveTag{
                    position: absolute;
                    width: 90px;
                    color: #fff;
                    text-align: center;
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                    top: -7px;
                    right: -35px;
                    height: 36px;
                    line-height: 43px;
                    z-index: 99;
                    width: 110px;
                    height: 50px;
                    line-height: 60px;
                    top: -9px;
                    right: -42px;
                }
                .saveTag.skyblue{
                    background: #2d96b6;
                }
                .avatar{
                    margin-bottom: 5px;
                    border:none;
                    overflow: hidden;
                    border-radius: 50%;
                    width: 100px;
                    height: 100px;
                    img{
                        width: 100%;
                    }
                }
                .dk-searchModal-useDetail{
                    .title{
                        font-weight: 700;
                        margin: 0;
                        position: relative;
                        top: 7px;
                        font-size: 1.5rem;
                    }
                    ul{
                        list-style: none;
                        li{
                            list-style-type: none;
                            padding: 5px 0;
                            font-size: 14px;
                            font-weight: 600;
                            white-space: nowrap;
                            color: #ddd;
                        }
                    }
                }
                .dk-searchJobsTabs{
                    padding:15px 15px;
                    .nav-pills{
                        flex-wrap: nowrap;
                        .nav-item{
                            width: 100%;
                            a{
                                border-radius: 0;
                                border-left: 1px solid #ad9f9f;
                                font-weight: 600;
                                color: #23475f;
                                font-size: 14px;
                                background: #e7e6e6;
                                text-align: center;
                            }
                            a.active{
                                background: #f4d03e;
                            }
                            &:first-child{
                                a{
                                    border-top-left-radius: 0.25rem;
                                    border-bottom-left-radius: 0.25rem;
                                }
                            }
                            &:last-child{
                                a{
                                    border-top-right-radius: 0.25rem;
                                    border-bottom-right-radius: 0.25rem;
                                }
                            }
                        }
                    }
                    .dk-viewall-form{
                        margin-top: 30px;
                        .dk-formright-panel{
                            .title{
                                background: #e7e6e6;
                                border-radius: 5px 5px 0 0;
                                width: 100%;
                                margin: 0;
                                border: 1px solid #8a8383;
                                border-bottom: none;
                                padding: 4px 0;
                                text-align: center;
                                color: #000;
                                font-size: 14px;
                            }
                            label{
                                width: 100%;
                                textarea{
                                    margin: 0;
                                    border-radius: 0 0 5px 5px;
                                    border: 1px solid #ccc;
                                    height: 150px;
                                    width: 100%;
                                    resize: vertical;
                                    outline: none;
                                    padding: 10px;
                                }
                            }
                        }
                        .dk-searchClass{
                            width: 100%;
                            background: #fff;
                            padding: 25px 15px;
                            color: #333;
                            margin-top: 25px;
                            display: flex;
                            flex-wrap: wrap;
                            border-radius: 5px;
                            label{
                                color: #000!important;
                                font-size: 14px;
                                min-width: 140px;
                                &:last-child{
                                    width: 100%;
                                }
                                input{
                                    background: #e7e6e6;
                                    height: 35px;
                                    border-color: #e7e6e6;
                                    font-size: 14px;
                                    color: #000;
                                    text-align: center;
                                }
                            }
                        }
                    }
                    .dk-profileSkill{
                        margin-top: 20px;
                        .dk-profileSkll-cols{
                            width: 100%;
                            max-width: 285px;
                            .title{
                                text-align: center;
                                margin: 0 0 5px;
                                color: #fff;
                                text-transform: uppercase;
                                font-weight: 600;
                            }
                            .dk-profileSkll-box{
                                table{
                                    width: 100%;
                                    border: none;
                                    tr{
                                        th{
                                            background: #d9d9d9;
                                            text-align: center;
                                            vertical-align: middle;
                                            font-weight: 600;
                                            color: #000;
                                            padding: 7px 10px;
                                            border-bottom: none;
                                            border-color: #a5a5a5;
                                            &:first-child{
                                                border-radius: 5px 0 0 0;
                                                border: none;
                                                box-shadow: 0 0 2px #377084;
                                            }
                                            &:last-child{
                                                border-radius: 0 5px 0 0;
                                                box-shadow: 0 0 0 #377084;
                                                border: none;
                                                border-left: 1px solid #b5b5b5;
                                            }
                                        }
                                        td{
                                            text-align: center;
                                            color: #333;
                                            background: #fff;
                                            padding: 5px 10px;
                                            vertical-align: middle;
                                            border-color: #ccc;
                                            font-weight: 500;
                                            &:first-child{
                                                border-left: none;
                                            }
                                            &:last-child{
                                                border-right: none;
                                            }
                                        }
                                        &:last-child{
                                            td{
                                                &:first-child{
                                                    border-radius: 0 0 0 5px;
                                                    border: none;
                                                }
                                                &:last-child{
                                                    border-right: none;
                                                    border-radius: 0 0 5px;
                                                    border-right: none;
                                                    border-bottom: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .dk-interestsMain{
                        padding: 0;
                        margin-top: 20px;
                        background: transparent;
                        .dk-interestsCols{
                            background: #fff;
                            padding: 25px 15px;
                            border-radius: 5px;
                            border: 1px solid #ccc;
                            margin-bottom: 20px;
                            .title{
                                font-size: 16px;
                                font-weight: 600;
                                width: 100%;
                                max-width: 200px;
                                color: #333;
                            }
                            .dk-interestsBtn{
                                display: flex;
                                width: 100%;
                                span{
                                    display: inline-block;
                                    background: #2b5977;
                                    margin: 0 5px;
                                    width: 100%;
                                    max-width: 100px;
                                    text-align: center;
                                    border-radius: 5px;
                                    padding: 8px 0;
                                    color: #fff;
                                }
                                input{
                                    background: rgb(43, 89, 119);
                                    color: rgb(255, 255, 255);
                                    border-radius: 5px;
                                }
                            }
                        }
                    }
                    .dk-searchCvDownload{
                        margin-top: 30px;
                        h6{
                            text-align: center;
                            font-size: 1.2rem;
                            span{
                                cursor: pointer;
                            }
                        }
                        table{
                            border:none;
                            tr{
                                th{
                                    border-color: #336879;
                                    border-top: 1px solid #336879;
                                    background: #234158;
                                    border-bottom: none;
                                    font-weight: 700;
                                    color: #fff;
                                    text-align: center;
                                    &:nth-child(02){
                                        text-align: left;
                                    }
                                    &:first-child{
                                        border-radius: 5px 0 0 0;
                                        display: block;
                                        border-right: none;
                                    }
                                    &:last-child{
                                        border-radius: 0 5px 0 0;
                                        display: block;
                                        border-left: none;
                                    }
                                }
                                td{
                                    background: #fff;
                                    color: #333;
                                    font-weight: 500;
                                    vertical-align: middle;
                                    text-align: center;
                                    &:nth-child(02){
                                        text-align: left;
                                    }
                                    button{
                                        margin:0 5px;
                                        padding:10px 15px 7px;
                                        color: #333;
                                        border: none;
                                        box-shadow:0 2px 10px 0 rgba(0,0,0,0.12);
                                        border-radius: 3px;
                                        text-transform: uppercase;
                                        font-size: 12px;
                                        font-weight: 500;
                                        &:hover{
                                            background: #3b97b6 !important;
                                            color: #fff;
                                            transition: all 0.5s;
                                        }
                                    }
                                    button.viewBtn{
                                        background-color: #fb3;
                                        font-weight: 600;
                                    }
                                    button.downloadBtn{
                                        background-color:#7bcaff;
                                        font-weight: 600;
                                    }
                                }
                            }
                        }
                    }

                    .dk-experinceAccordion{
                        .accordion{
                            .card{
                                background-color: transparent;
                                border: none;
                                .card-header{
                                    color: #fff;
                                    border-bottom: none;
                                    padding: 0;
                                    .dk-timeline-wrapper{
                                        display: flex;
                                        flex-flow: row wrap;
                                        .dk-timeline-time{
                                            font-style: italic;
                                            padding: 20px;
                                            flex: 0 0 40%;
                                            text-align: center;
                                            position: relative;
                                            span{
                                                padding: 6px 40px;
                                                cursor: pointer;
                                                color: #23475f;
                                                background: #e7e6e6;
                                                border-radius: 4px;
                                                cursor: pointer;
                                                position: relative;
                                                z-index: 9;
                                            }
                                            &:first-child:after {
                                                position: absolute;
                                                content: "";
                                                left: 15px;
                                                top: 30px;
                                                width: 30%;
                                                height: 1px;
                                                background: #dadbdb;
                                                display: block;
                                            }
                                            .timeline-icon {
                                                position: absolute;
                                                width: 30px;
                                                height: 30px;
                                                line-height: 30px;
                                                color: #23475f;
                                                background: #e7e6e6;
                                                text-align: center;
                                                left: 0;
                                                border-radius: 5px;
                                                top: 15px;
                                                font-style: normal;
                                                z-index: 2;
                                                font-size: 14px;
                                                cursor: pointer;
                                            }
                                        }
                                        .dk-timeline-series {
                                            margin-top: 15px;
                                            margin-bottom: 15px;
                                            flex: 0 0 60%;
                                            border: 1px solid #ccc;
                                            padding: 0.5em 1em;
                                            border-radius: 4px;
                                            position: relative;
                                            background: #fff;
                                            color: #333;
                                            cursor: pointer;
                                            &:before {
                                                position: absolute;
                                                content: "";
                                                left: -23%;
                                                top: 15px;
                                                width: 23%;
                                                height: 1px;
                                                background: #dadbdb;
                                                display: block;
                                            }
                                            .dk-seriesBetween{
                                                p{
                                                    width: 100%;
                                                    position: relative;
                                                    text-align: left;
                                                    span{
                                                        position: absolute;
                                                        right: 10px;
                                                    }
                                                }
                                                .collapse{
                                                    .dk-dgd{
                                                        ul{
                                                            list-style: none;
                                                            li{
                                                                text-align: left;
                                                                color: #333 !important;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .dk-achivementTabCon{
                        border: 1px solid rgb(173, 159, 159);
                        padding: 1rem;
                        padding-top: 0;
                        background: #fff;
                        margin-top:40px;
                        color: #333;
                        .dk-archiveBoxCon{
                            display: flex;
                            border-bottom: 1px solid #ccc;
                            padding: 1rem 0; 
                            &:last-child{
                                border-bottom: none;
                            }
                            .dk-achiveRightPanel{
                                .topCon{
                                    display: flex;
                                    align-items: center;

                                    label{
                                        min-width: 145px;
                                        text-align: left;
                                        font-weight: bold;
                                    }
                                    span{
                                        margin-bottom: 0.5rem;
                                    }
                                }
                                .dk-sliderdesc{
                                    p{
                                        text-align: justify;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.modal.right.fade{
    .modal-dialog{
        right: -75%;
        -webkit-transition: opacity 0.5s linear, right 0.5s ease-out;
           -moz-transition: opacity 0.5s linear, right 0.5s ease-out;
             -o-transition: opacity 0.5s linear, right 0.5s ease-out;
                transition: opacity 0.5s linear, right 0.5s ease-out;
    }
    
}

.modal.right.fade.show{
    .modal-dialog{
        right: 0;
    }		
}

.dk-searchCvModalMain.modal::-webkit-scrollbar {
    width: 0;
}

.dk-searchCvModalMain.modal::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
}

.dk-searchCvModalMain.modal::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 0 solid slategrey;
}

.dk-searchDocInfoModal{
    background:rgba(0,0,0,0.49);
    .modal-dialog{
        max-width: 35%;
        .modal-content{
            border-radius: 7px;
            border: none;
            box-shadow: none;
            .modal-header{
                padding: 6px 0;
                background: #f7bb44;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                .modal-title{
                    width: 100%;
                    color: #333;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                }
                .close{
                    background: transparent;
                    color: #333;
                    top: 19px;
                    right: 12px;
                    position: absolute;
                    padding: 0;
                    z-index: 9;
                    opacity: 1;
                    text-shadow: none;
                    font-size: 26px;
                    outline: none;
                    width: 40px;
                    height: auto;
                    line-height: normal;
                    font-weight:500;
                }
            }
        }
    }
}