.dkjobs-SerchSecHome{
    background: #325f7d;
    padding: 90px 0;
    .dkjobs-topSrchCon{
        .dkjobs-srchtitleCon{
            width: 100%;
            text-align: center;
            h1.dkjobs-srchtitle{
                padding-bottom: 0;
                margin-bottom: 20px;
                text-align: center;
                font-size: 28px;
                color: #fff;
                font-weight: 700;
                text-transform: uppercase;
            }
        }
        .dkjobsSrchFormCon{
            background: #25455a;
            padding: 15px 18px 18px;
            border: 1px solid #557992;
            border-radius: 5px;
            .dkjobsSrchForm{
                form{
                    .form-group{
                        margin-bottom: 0;
                        label{
                            color: #fff;
                            font-size: 15px;
                        }
                    }
                }
            }
            .dkjobs-srchbtn{
                display: flex;
                align-items: center;
                justify-content: center;
                button{
                    height: 40px;
                    margin: 0;
                    padding: .375rem .75rem;
                    color: #fff;
                    background-color: #343a40;
                    border: 1px solid #343a40;
                    font-size: 15px;
                    border-radius: .125rem;
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                    transition: color .15s ease-in-out;
                    text-transform: uppercase;
                    white-space: nowrap;
                    margin-top: 1.75rem;
                }
            }
        }
        .dkjobs-advBtnCon{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 4px;
            a{
                color: #fff;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 600;
            }
        }
    }
}
.dkjobs-yellow-section{
    background: #f4d03e;
    position: relative;
    ul.dk-itemsCon{
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        list-style: none;
        li{
            display: flex;
            align-items: center;
            white-space: nowrap;
            border-right: 1px solid #38607c;
            a {
                padding: 15px 30px;
                color: #333!important;
                font-size: 15px;
                width: 100%;
                min-height: 49px;
                display: inline-block;
                font-weight: 700;
                transition: all .4s ease-in-out;
                span{
                    display: flex;
                    align-items: center;
                    img{
                        margin-right: 10px;
                    }
                }
            }
            a:hover {
                text-decoration: none;
                color: #25455a;
                transition: all .4s ease-in-out;
            }
        }
        li:last-child{
            border-right: none;
        }
    }

}
.dkjobs-yellow-section:before {
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    content: "";
    background: url("../../../assets/images/sadowimg.png") no-repeat 50%;
    width: 100%;
    height: 31px;
    bottom: -30px;
}
.employe-feeds-section{
    background-color: #fff;
    color: #333;
    padding: 30px 0 35px;
    .dk-empfeedbtnCon{
        display: flex;
        justify-content: center;
        align-items: center;
        button.dk-viewallpostBtn{
            color: #333;
            background-color: transparent;
            background-image: none;
            border: 1px solid#cdd2d6;
            box-shadow: none;
            font-size: .9rem;
            border-radius: 5px;
            line-height: 1.2rem;
            text-transform: uppercase;
            word-wrap: break-word;
            white-space: normal;
            padding: .7rem 1.25rem; 
        }
        button.dk-viewallpostBtn:hover {
            color: #fff;
            border-color: #25455a;
            background: #25455a;
        }
    }
}
.dk-livejobs-section{
    background-color: #325f7d;
    color: #fff;
    padding: 30px 0 35px;
    .secTitle{
        display: flex;
        justify-content: center;
        align-items: center;
        h2{
            margin: 0;
            padding: 0 0 15px;
            font-size: 24px;
            text-transform: uppercase;
            font-weight: 500;
        }
    }
    .dk-liveJobsTabsCon{
        .nav-pills.dk-livejobTabs{
            background-color: #25455a;
            .dk-livejobTabItem.nav-item{
                width: 100%;
                max-width: 11.11%;
                min-width: 11.11%;
                position: relative;
                text-transform: inherit;
                font-weight: 400;
                outline: none;
                transition: all .5s;
                overflow: visible!important;
                border-right: 1px solid #2e546d;
                border-bottom: 1px solid #2e546d;
                border-top: 1px solid #2e546d;
                border-left: 1px solid transparent;
                &:hover{
                    border: 1px solid #fff;
                    transition: all 0.5s;
                    .nav-link{
                        span.tabText{
                            opacity: 1;
                            transition: all 0.5s;
                        }
                    }
                }
                .nav-link{
                    border-radius: 0;
                    padding: 30px 10px;
                    background-color: #25455a;
                    color: #fff;
                    text-align: center;
                    outline: none;
                    span.tabText {
                        position: relative;
                        top: 20px;
                        opacity: 0.7;
                        font-weight: 300;
                        transition: all 0.5s;
                    }
                    
                }
                
                .nav-link.active,
                & .show>.nav-link {
                    color: #333;
                    background-color: #fff;
                    border: 1px solid #fff;
                    border-radius: 0;
                    position: relative;
                }
                .nav-link.active:after {
                    content: "";
                    width: 0;
                    height: 0;
                    border-color: #fff transparent transparent;
                    border-style: solid;
                    border-width: 10px 10px 0;
                    line-height: 0;
                    _border-color: #fff #000 #000 #000;
                    _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                    position: absolute;
                    bottom: -20px;
                    left: 50%;
                    transform: translate(-50%, -10px);
                    z-index: 999;
                    opacity: 1;
                }
            }
            .dk-livejobTabItem.nav-item:first-child{
                border-left: 1px solid #2e546d;
            }
            .dk-livejobTabItem.jobtype{
                .nav-link{
                    &::before{
                        content: "";
                        background: url("../../../assets//images//sprite_img.png") no-repeat;
                        padding: 13px 13px;
                        transition: all .5s;
                        background-position: 0 0;
                        pointer-events: none;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        margin-top: -11px;
                    }
                }
                .nav-link.active{
                    &::before{
                        background-position: 0 -58px;
                    }
                }
            }
            .dk-livejobTabItem.industry{
                .nav-link{
                    &::before{
                        content: "";
                        background: url("../../../assets//images//sprite_img.png") no-repeat;
                        padding: 13px 13px;
                        transition: all .5s;
                        background-position:59% 0;
                        pointer-events: none;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        margin-top: -11px;
                    }
                }
                .nav-link.active{
                    &::before{
                        background-position:59% -58px;
                    }
                }
            }
            .dk-livejobTabItem.level{
                .nav-link{
                    &::before{
                        content: "";
                        background: url("../../../assets//images//sprite_img.png") no-repeat;
                        padding: 13px 15px;
                        transition: all .5s;
                        background-position:99% 0;
                        pointer-events: none;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        margin-top: -11px;
                    }
                }
                .nav-link.active{
                    &::before{
                        background-position:99% -58px;
                    }
                }
            }

            .dk-livejobTabItem{
                .nav-link{
                    &::before{
                        opacity: 0.7;
                    }
                }
                .nav-link.active{
                    &::before{
                        opacity: 1;
                    }
                }
            }
            .dk-livejobTabItem.jobtitle{
                .nav-link{
                    &::before{
                        content: "";
                        background: url("../../../assets//images//sprite_img.png") no-repeat;
                        padding: 13px 17px;
                        transition: all .5s;
                        background-position:71% 0;
                        pointer-events: none;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        margin-top: -11px;
                    }
                }
                .nav-link.active{
                    &::before{
                        background-position:71% -58px;
                    }
                }
            }
            .dk-livejobTabItem.country{
                .nav-link{
                    &::before{
                        content: "";
                        background: url("../../../assets//images//sprite_img.png") no-repeat;
                        padding: 14px 14px;
                        transition: all .5s;
                        background-position:13% 0;
                        pointer-events: none;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        margin-top: -11px;
                    }
                }
                .nav-link.active{
                    &::before{
                        background-position:13% -58px;
                    }
                }
            }
            .dk-livejobTabItem.city{
                .nav-link{
                    &::before{
                        content: "";
                        background: url("../../../assets//images//sprite_img.png") no-repeat;
                        padding: 13px 15px;
                        transition: all .5s;
                        background-position:24% 0;
                        pointer-events: none;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        margin-top: -11px;
                    }
                }
                .nav-link.active{
                    &::before{
                        background-position:24% -58px;
                    }
                }
            }
            .dk-livejobTabItem.language{
                .nav-link{
                    &::before{
                        content: "";
                        background: url("../../../assets//images//sprite_img.png") no-repeat;
                        padding: 13px 15px;
                        transition: all .5s;
                        background-position:36% 0;
                        pointer-events: none;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        margin-top: -11px;
                    }
                }
                .nav-link.active{
                    &::before{
                        background-position:36% -58px;
                    }
                }
            }
            .dk-livejobTabItem.salary{
                .nav-link{
                    &::before{
                        content: "";
                        background: url("../../../assets//images//sprite_img.png") no-repeat;
                        padding: 14px 14px;
                        transition: all .5s;
                        background-position:47% 0;
                        pointer-events: none;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        margin-top: -11px;
                    }
                }
                .nav-link.active{
                    &::before{
                        background-position:47% -58px;
                    }
                }
            }
            .dk-livejobTabItem.workpermit{
                .nav-link{
                    &::before{
                        content: "";
                        background: url("../../../assets//images//sprite_img.png") no-repeat;
                        padding: 14px 18px;
                        transition: all .5s;
                        background-position:83% 0;
                        pointer-events: none;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        margin-top: -11px;
                    }
                }
                .nav-link.active{
                    &::before{
                        background-position:83% -58px;
                    }
                }
            }
            .dk-livejobTabItem.nav-item.industry:before{
                background-position: 59% 0;
            }
            // .dk-livejobTabItem.nav-item.industry:before .nav-link {
            //     background-position: 0 100%;
            //     transition: all .5s;
            // }
            .dk-livejobTabItem.nav-item.level:before {
                background-position: 99% 0;
            }
            .dk-livejobTabItem.nav-item.jobtitle:before {
                background-position: 71% 0;
                padding: 13px 17px;
            }
            .dk-livejobTabItem.nav-item.country:before {
                background-position: 13% 0;
                padding: 15px 14px;
            }
            .dk-livejobTabItem.nav-item.city:before {
                background-position: 24% 0;
                padding: 13px 15px;
            }
            .dk-livejobTabItem.nav-item.language:before {
                background-position: 35.5% 0;
                padding: 13px 15px;
            }
            .dk-livejobTabItem.nav-item.salary:before {
                background-position: 47% 0;
            }
            .dk-livejobTabItem.nav-item.workpermit:before {
                background-position: 83% 0;
                padding: 13px 17px;
            }
        }
    }
    .livejobsTabContent{
        .tab-pane{
            .dkTabePaneCon{
                padding: 24px 24px 0 24px;
                .dk-jobtypeMCont{
                    display: flex;
                    flex-flow: row wrap;
                    .dk-jobtypeCon{
                        flex-basis: 20%;
                        text-align: left;
                        ul.dk-jobtypeItem{
                            list-style: none;
                            li {
                                list-style-type: none;
                                line-height: 2;
                                a {
                                    color: #fff;
                                    font-size: 14px;
                                    span {
                                        color: #ffc107;
                                        margin-left: 0.3rem;
                                    }
                                }
                            }
                        }
                    }
                    .dk-viewbtnCon{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        margin-top: 1.5rem;
                        button.dk-viewbtn{
                            color: #fff;
                            background-color: transparent;
                            background-image: none;
                            border: 1px solid#fff;
                            box-shadow: none;
                            font-size: .9rem;
                            border-radius: 5px;
                            width: 150px;
                            margin: auto;
                            padding-top: .7rem;
                            padding-bottom: .7rem;
                        }
                        button.dk-viewbtn:hover {
                            background-color: #fff;
                            color: #325f7d;
                        }
                    }
                }
                .dk-industryMCont{
                    display: flex;
                    flex-flow: row wrap;
                    .dk-industryCon{
                        flex-basis: 25%;
                        text-align: left;
                        ul.dk-jobtypeItem{
                            list-style: none;
                            li {
                                list-style-type: none;
                                line-height: 2;
                                a {
                                    color: #fff;
                                    font-size: 14px;
                                    span {
                                        color: #ffc107;
                                        margin-left: 0.3rem;
                                    }
                                }
                            }
                        }
                    }
                    .dk-viewbtnCon{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        margin-top: 1.5rem;
                        button.dk-viewbtn{
                            color: #fff;
                            background-color: transparent;
                            background-image: none;
                            border: 1px solid#fff;
                            box-shadow: none;
                            font-size: .9rem;
                            border-radius: 5px;
                            width: 150px;
                            margin: auto;
                            padding-top: .7rem;
                            padding-bottom: .7rem;
                        }
                        button.dk-viewbtn:hover {
                            background-color: #fff;
                            color: #325f7d;
                        }
                    }
                }
                .dk-joblevelMCont{
                    display: flex;
                    flex-flow: row wrap;
                    .dk-joblevelCon{
                        flex-basis: 16.66%;
                        text-align: left;
                        ul.dk-joblevelItem{
                            list-style: none;
                            li {
                                list-style-type: none;
                                line-height: 2;
                                a {
                                    color: #fff;
                                    font-size: 14px;
                                    span {
                                        color: #ffc107;
                                        margin-left: 0.3rem;
                                    }
                                }
                            }
                        }
                    }
                    .dk-viewbtnCon{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        margin-top: 1.5rem;
                        button.dk-viewbtn{
                            color: #fff;
                            background-color: transparent;
                            background-image: none;
                            border: 1px solid#fff;
                            box-shadow: none;
                            font-size: .9rem;
                            border-radius: 5px;
                            width: 150px;
                            margin: auto;
                            padding-top: .7rem;
                            padding-bottom: .7rem;
                        }
                        button.dk-viewbtn:hover {
                            background-color: #fff;
                            color: #325f7d;
                        }
                    }
                }
                .dk-SalaryMCont{
                    .dk-salrySwapTabsCon{
                        width: 100%;
                        .dk-salryTabsCon{
                            .dk-salrySwapTab.nav-item{
                                border: 1px solid #b9b1b1;
                                .nav-link {
                                    border-radius: 0;
                                    color: #fff;
                                    min-width: 220px;
                                    text-align: center;
                                    border: none;
                                    font-size: 15px;
                                    padding: 0.52rem 1rem;
                                }
                                .nav-link:first-child{
                                    border-right: none;
                                }
                                .nav-link.active{
                                    border-radius: 0;
                                    color: #495057;
                                    background-color: #fff;
                                }
                            }
                            .dk-salrySwapTab.nav-item:first-child{
                                border-right: none;
                            }
                            .dk-salrySwapTab.nav-item .nav-link.active{
                                border: 1px solid #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}
.dk-popularjob-section{
    padding: 30px 0 35px;
    background-color: #fff;
    .dk-sectitleCon{
        h2{
            margin: 0;
            padding: 0 0 15px;
            font-size: 24px;
            text-transform: uppercase;
            text-align: center;
            color: #333;
            font-weight: 600;
        }
    }
    .dk-cardsecCon{
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .dk-viewindustryBtnCon{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1.5rem;
        .dk-viewindustryBtn{
            text-transform: uppercase;
            border: 1px solid#cdd2d6;
            box-shadow: none;
            font-size: .9rem;
            border-radius: 5px;
            padding: 0.55rem 1.25rem;
            color: #333;
            font-weight: normal;
        }
        .dk-viewindustryBtn:hover {
            color: #fff!important;
            border-color: #25455a!important;
            background: #25455a!important;
        }
    }
}
.dk-featuredjob-section{
    background-color: #325f7d;
    padding: 30px 0 35px;
    h2.section-title{
        margin: 0;
        padding: 0 0 15px;
        font-size: 24px;
        text-transform: uppercase;
        text-align: center;
        color: #fff;
        font-weight: 600;
        width: 100%;
    }
    .feature-jobs-section-ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li.featured-jobs-block {
            margin: 0;
            padding: 0 10px;
            flex: 0 0 50%;
            max-width: 50%;
            .job_box{
                display: flex;
                align-items: center;
                margin: 0;
                padding: 15px 0;
                border-bottom: 1px solid #25455a;
                transition: all .4s ease-in-out;
                .imgBox{
                    display: flex;
                    align-items: center;
                    margin: 0;
                    max-width: 90px;
                    width: 90px;
                    min-height: 50px;
                    justify-content: center;
                    background: #fff;
                }
                .textBox{
                    width: calc(100% - 100px);
                    padding-left: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .job_sdesc{
                        a{
                            color: #fff;
                            font-weight: 700;
                            font-size: 14px;
                            text-align: left;
                        }
                        p{
                            margin: 0;
                            font-size: 12px;
                            color: #fff;
                            text-align: left;
                        }
                    }
                    .job_acs {
                        padding-right: 10px;
                        display: flex;
                        align-items: center;
                        span {
                            margin-right: 12px;
                            padding: 3px 10px;
                            font-size: 13px;
                            color: #d1dfe8;
                            border: 1px solid #7794a7;
                            display: block;
                            border-radius: 20px;
                        }
                        a{
                            font-size: 13px;
                            text-transform: uppercase;
                            box-shadow: none;
                            border-radius: 0;
                        }
                        a.btn-theme-dark {
                            background: #25455a;
                            border-color: #25455a;
                            color: #fff;
                            padding: .5rem 1.6rem;
                        }
                    }
                }
            }
            .job_box:hover {
                background: #fff;
                .textBox{
                    .job_sdesc{
                        a{
                            color: #333;
                            font-weight: 700;
                            font-size: 14px;
                            text-align: left;
                        }
                        p{
                            margin: 0;
                            font-size: 12px;
                            color: #333;
                            text-align: left;
                        }
                    }
                    .job_acs {
                        span {
                            color: #474747;
                            border: 1px solid #474747;
                        }
                        a{
                            font-size: 13px;
                            text-transform: uppercase;
                            box-shadow: none;
                            border-radius: 0;
                        }
                        a.btn-theme-dark {
                            background: #25455a;
                            border-color: #25455a;
                            color: #fff;
                            padding: .5rem 1.6rem;
                        }
                    }
                }
            }
        }
    }
}
.dk-feature-employers-section{
    padding: 30px 0 35px;
    .section-titleCon{
         display: flex;
         justify-content: center;
         align-items: center;
         h2{
            margin: 0;
            padding: 0 0 15px;
            font-size: 24px;
            text-transform: uppercase;
            text-align: center;
            color: #333;
            font-weight: 600;
         }
    }
    .dk-empagenceBoxSection{
        ul.dk-empagenceBoxCon{
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            background: #fff;
            border: 1px solid #ddd;
            border-bottom: none;
            li{
                margin: 0;
                padding: 0;
                flex: 0 0 14.2857%;
                max-width: 14.2857%;
                text-align: center;
                border-right: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                transition: all .6s ease-in-out;
                .flip_box {
                    position: relative;
                    -webkit-transform-style: preserve-3d;
                    transform-style: preserve-3d;
                    a{
                        .imgBox{
                            min-height: 110px;
                            align-items: center;
                            justify-content: center;
                            display: flex;
                            margin: 0;
                            img {
                                max-width: 100%;
                            }
                        }
                        .hover_sjob {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            background-color: #325f7d;
                            color: #fff;
                            top: 0;
                            left: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            opacity: 0;
                            visibility: hidden;
                            -webkit-transform: scale(.7);
                            transform: scale(.7);
                            transition: all .7s;
                            span{
                                b{
                                    margin: 0 0 5px;
                                    display: block;
                                }
                            }
                        }
                    }
                }
                .flip_box:hover {
                    opacity: 1;
                    visibility: visible;
                    -webkit-transform: scale(1);
                    transform: scale(1);
                    a{
                        .imgBox{
                            min-height: 110px;
                            align-items: center;
                            justify-content: center;
                            display: flex;
                            margin: 0;
                            img {
                                max-width: 100%;
                            }
                        }
                        .hover_sjob {
                            z-index: 2;
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
                .flip_lastbox{
                    a{
                        background-color: #325f7d;
                        color: #fff;
                        min-height: 110px;
                        align-items: center;
                        justify-content: center;
                        display: flex;
                        margin: 0;
                    }
                }

            }
            li:nth-child(07),
            li:nth-child(14),
            li:nth-child(21){
                border-right: none;
            }
        }
    }
}
.dk-home-testimonials-section{
    padding: 30px 0 35px;
    background-color: #325f7d;
    .sectitleCon{
        h2.title{
            margin: 0;
            padding: 0 0 15px;
            font-size: 24px;
            text-transform: uppercase;
            text-align: center;
            color: #fff;
            font-weight: 600;
        }
    }
    .testimonalsCon{
        color: #333;
        .carousel.slide{
            padding: 0 50px;
            .carousel-indicators{
               display: none;
            }
            .carousel-inner{
                .carousel-item{
                    .container{
                        .testmoinal_card.card{
                           box-shadow: none;
                           font-weight: 400;
                           .card-body{
                            .testmoinal_text{
                                p{
                                    font-size: 14px;
                                    color: #333;
                                    text-align: justify;
                                }
                            }
                           }
                        }
                        .dk-dwoncornerimg{
                            margin: -3px 0 0 10px;
                            width: 25px;
                            height: 25px;
                        }
                        .testmonialUser{
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            padding-left: 30px;
                            img{
                                width: 72px;
                                height: 72px;
                                border-radius: 50%;
                                border: 3px solid #fff;
                            }
                        }
                    }
                }
            }
            .carousel-control-prev {
                .carousel-control-prev-icon{
                    position: absolute;
                    top: 40%;
                    left: 30%;
                    transform: translate(40%,30%);
                    height: 30px;
                    background-image: url("../../../assets/images/testimonial-left-arrow.png");
                }
            }
            .carousel-control-next {
                .carousel-control-next-icon {
                    position: absolute;
                    top: 40%;
                    right: 40%;
                    transform: translate(40%,40%);
                    height: 30px;
                    background-image: url("../../../assets/images/testimonial-arrow-right.png");
                }
            }
        }
    }
    .dk-testviewAllBtnCon{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;
        .dk-testviewAllBtn{
            color: #fff;
            background-color: transparent;
            background-image: none;
            border: 1px solid#fff;
            box-shadow: none;
            font-size: .9rem;
            border-radius: 5px;
            padding: .55rem 1.25rem;
            min-width: 200px;
        }
        .dk-testviewAllBtn:hover {
            background-color: #fff;
            color: #325f7d;
        }
    }
}
.dk-home-blog-section{
    padding: 30px 0 35px;
    background-color: #fff;
    .sectitleCon{
        h2.title{
            margin: 0;
            padding: 0 0 15px;
            font-size: 24px;
            text-transform: uppercase;
            text-align: center;
            color: #333;
            font-weight: 600;
        }
    }
    .dk-home-blogCon{
        .carousel.slide{
            padding: 0 50px;
            .carousel-indicators{
               display: none;
            }
            .carousel-inner{
                .carousel-item{
                    .container{
                        a{
                            .home_blog_card{
                                border-radius: 10px;
                                border: 1px solid rgba(0,0,0,.125);
                                box-shadow: none;
                                .home_blog_imgCon{
                                    width: 100%;
                                    .home_blog_img{
                                        width: 100%;
                                        height: 235px;
                                        height: auto;
                                        object-fit: cover;
                                        border-top-left-radius: 10px;
                                        border-top-right-radius: 10px;
                                    }
                                }
                                .home_blog_Content{
                                    padding: 1rem 1.25rem;
                                    h3{
                                        font-weight: 600;
                                        margin-bottom: 3px;
                                        font-size: 20px;
                                        color: #3b3636;
                                        line-height: 1.5;
                                        text-align: left;
                                    }
                                }
                                .home_blog_bottomContent{
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    flex-flow: row wrap;
                                    border-top: 1px solid #dee2e6;
                                    padding: 0.5rem;
                                    a{
                                        text-align: center;
                                    }
                                    .blog_bottomContent1,
                                    .blog_bottomContent2,
                                    .blog_bottomContent3,
                                    .blog_bottomContent4{
                                        flex-basis: 16.25%;
                                        color: #333;
                                        font-size: 13px;
                                        i {
                                            display: block;
                                            color: #333;
                                            font-size: 1.333em;
                                        }
                                    }
                                    .blog_bottomContent5{
                                        flex-basis: 35%;
                                        color: #333;
                                        font-size: 13px;
                                        i {
                                            display: block;
                                            color: #333;
                                            font-size: 1.333em;
                                        }
                                    }
                                    .blog_bottomContent1{
                                        .dk-imgCon{
                                            img{
                                                width: 30px;
                                                height: 30px;
                                                border-radius: 50%;
                                            }
                                        }
                                    }
                                }
                             }
                        }  
                    }
                }
            }
            .carousel-control-prev {
                opacity: 1;
                .carousel-control-prev-icon{
                    position: absolute;
                    top: 45%;
                    left: 20%;
                    transform: translate(20%, 45%);
                    width: 47px;
                    height: 47px;
                    background: #25455a;
                    border-radius: 50%;
                    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
                    transition: all .2s ease-in-out;
                    background-image: url("../../../assets/images/left-arrow-angle32.png");
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 14px;
                }
            }
            .carousel-control-next {
                opacity: 1;
                .carousel-control-next-icon {
                    position: absolute;
                    top: 45%;
                    right: 30%;
                    transform: translate(30%, 45%);
                    width: 47px;
                    height: 47px;
                    background: #25455a;
                    border-radius: 50%;
                    box-shadow: 0 5px 11px 0 rgba(0,0,0,.18), 0 4px 15px 0 rgba(0,0,0,.15);
                    transition: all .2s ease-in-out;
                    background-image: url("../../../assets/images/right-arrow-angle32.png");
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 14px;
                }
            }
        }
    }
}

