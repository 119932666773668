.dk-standardDashboard{
    padding: 25px 35px;
    .dk-dashboardGraph{
        .dk-dashboardFilter{
            display: block;
            clear: both;
            margin-bottom: 25px;
            position: relative;
            h3.title{
                position: absolute;
                left: 50%;
                -webkit-transform: translate(-50%);
                transform: translate(-50%);
                text-transform: uppercase;
                width: 100%;
                margin-left: 0;
                text-align: center;
                color: #000;
                font-weight: 700;
                font-size: 19px;
            }
            li{
                list-style-type: none;
                &:first-child{
                    a{
                        border-radius: 5px 0 0 5px;
                    }
                }
                &:last-child{
                    a{
                        border-right: 1px solid #67818e;
                        border-radius: 0 5px 5px 0;
                    }
                }
                a{
                    padding: 8px 18px;
                    border: 1px solid #67818e;
                    border-right: none;
                    color: #1f394c;
                }
                a.active{
                    background: #67818e;
                    color: #fff;
                    border: 1px solid #67818e;
                }
            }
        }
        .dk-dashboardBar{
            background: #67818e;
            padding: 25px 25px 10px;
            border: 1px solid #ccc;
            border-radius: 8px;
            margin-bottom: 30px;
            .dk-barLeftPanel{
                .title{
                    font-weight: 700;
                    font-size: 22px;
                    color: #fff;
                    text-align: left;
                    margin-bottom: 8px;
                }
                ul{
                    list-style: none;
                    padding: 0;
                    li{
                        list-style-type: none;
                        padding-bottom: 8px;
                        color: #fff;
                        text-align: left;
                        i{
                            width: 18px;
                        }
                    }
                }
            }
            .dk-barRightPanel{
                width: 100%;
                max-width: 380px;
                float: right;
                li{
                    list-style-type: none;
                    width: 100%;
                    margin-right: 8px;
                    text-align: center;
                    color: #fff;
                    font-size: 12px;
                    a{
                        display: inline-block;
                        border: 1px solid #234158;
                        padding: 6px 12px;
                        color: #fff;
                        border-radius: 4px;
                        margin-right: 8px;
                        background: #234158;
                    }
                    span{
                        display: block;
                        margin-top: 3px;
                    }
                }
                .dk-dashboardProgressbar{
                    padding-top: 30px;
                    color: #fff;
                    .subtitle{
                        text-align: left;
                        display: block;
                        margin-bottom: 5px;
                    }
                    .progress{
                        height: 22px;
                        border-radius: 5px;
                        .progress-bar{
                            background-color:#1f394c !important;
                        }
                    }
                }
            }
        }
        .jobseeker-standard-dashboard-block{
            .dk-dashboardBox-sec-Cols{
                padding: 22px 0;
                color: #fff;
                font-weight: 600;
                text-align: center;
                font-size: 32px;
                line-height: 2.7rem;
                border-radius: 5px;
                background:#67818e;
                span{
                    display: block;
                    width: 100%;
                    font-size: 18px;
                    font-weight: 500;
                }
            }
        }
    }
}