.consent{
    h4{
        text-transform: uppercase;
        font-weight: 500;
        color: #966312;
        margin: 15px 0;
        display: block;
        font-size: 1.2rem;
    }
    .dk-complaince-field{
        width: 75%;
        .txt-password{
            border-radius: 0;
            height: 41px;
            border: 1px solid #ccc;
            outline: none;
        }
        .dk-confirmBtn{
            background: #1f394c;
            border-color: #1f394c;
            max-width: -moz-fit-content;
            max-width: fit-content;
            padding: 0 25px;
            border-radius: 0;
            color: #fff;
            height: 40px;
            outline: 0;
            text-transform: uppercase;
            border: none;
            &:hover{
                background:#2d96b6;
                transition: all 0.5s;
            }
        }
    }
}