.dk-empfeedboxCon{
    .dkjobs-posts{
        border-radius: 5px;
        // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
        // 0 2px 10px 0 rgba(0, 0, 0,  0.12);
        box-shadow: 0 2px 5px 0 rgba(0,0,0, .16), 0 2px 10px 0 rgba(0,0,0, .12);
        border: 1px solid rgba(0,0,0,.125);
        // box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
        .card-body {
            padding: 12px 15px;
            .dk-FistfeedRow{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                a{
                    img{
                        width: 24px;
                        height: 24px;
                    }
                }
                a.dk-topfeedtitle{
                    font-size: 13px;
                    color: #606060;
                }
            }
            .discription{
                p{
                    font-size: 13px;
                    color: #343434;
                    text-align: left;
                }
            }
            .dkpost_img{
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .dk-commentFeedSec{
                padding-top: 0.5rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .like-viewSec{
                    ul.socila-like-section{
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        li{
                            display: inline;
                            a{
                                color: #888181;
                                font-size: 1rem;
                                i{
                                    color: #d4cdcd;
                                    font-size: 1.2em;
                                }
                            }
                            a:hover i{
                                color: #2b4a60;
                            }
                        }
                    }
                }
                .comentSec{
                    ul{
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        li{
                            display: inline;
                            a{
                                color: #888181;
                                font-size: 1rem;
                                i{
                                    color: #d4cdcd;
                                    font-size: 1.2em;
                                }
                            }
                            a:hover i{
                                color: #2b4a60;
                            }
                        }
                    }
                }
            }
        }

    }
}