.dk-basicProfile{
    margin-top: 40px;
    .dk-basicProfileCon{
        .dk-boxsCon{
            color: #333;
            background: #d9d9d9;
            padding: 25px !important;
            border-radius: 5px;
            border: 1px solid #ccc;
            h3{
                text-align: center;
                font-weight: 700;
                font-size: 20px;
                text-transform: uppercase;
            }
            form{
                .dk-contactPerson-image {
                    width: 100%;
                    max-width: 150px;
                    border: 1px solid #ccc;
                    padding: 0;
                    margin-right: 15 px;
                    height: 150px;
                    display: flex;
                    align-items: center;
                    background: #fff;
                    justify-content: center;
                    overflow: hidden;
                    border-radius: 50%;
                    .profile-img{
                        max-width: 100%;
                    }
                    canvas{
                        width: 150px;
                        height: 150px;
                    }
                }
                .dk-uploadBtnText{
                    .dk-contactPerson-uploadBtn{
                        position: relative;
                        width: 100%;
                        max-width: 120px;
                        margin-left: 15px;
                        cursor: pointer;
                        input[type=file] {
                            width: 120px;
                            height: 35px;
                            position: relative;
                            z-index: 9;
                            opacity: 0;
                            cursor: pointer;
                        }
                        span {
                            display: inline-block;
                            width: 100%;
                            height: 35px;
                            line-height: 35px;
                            top: 0;
                            left: 0;
                            background: #1f394c;
                            color: #fff;
                            border-radius: 3px;
                            text-align: center;
                            position: absolute;
                            z-index: 1;
                            cursor: pointer;
                        }
                    }
                    .dk-sbTitle {
                        margin-left: 15px;
                        margin-bottom: 5px;
                        a {
                            color: #333;
                            &:hover{
                                color: #E2445B;
                            }
                        }
                    }
                }
                .dk-informationCols{
                    padding: 35px 25px;
                    background: #fff;
                    text-align: left;
                    position: relative;
                    width: 94%;
                    max-width: 94%;
                    &::before{
                        content: "";
                        width: 6px;
                        height: 100%;
                        background: #2d96b6;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                }
                label{
                    width: 100%;
                    text-align: left;
                }
                input.form-control{
                    width: 100%;
                    height: auto;
                    display: block;
                    border: 1px solid #ccc;
                    padding: 7px 10px;
                    border-radius: 0;
                    margin: 0;
                    font-size: 13px;
                    background: #fff;
                }
                .btn-container{
                    .dk-cancelBtn{
                        background: transparent;
                        color: #E2445B;
                        font-size: 16px;
                        border-radius: 5px;
                        outline: none;
                        margin: 0 5px;
                        border: none;
                        padding: 8px 25px;
                        &:hover{
                            background: #E2445B !important;
                            color: #fff;
                            transition: all 0.5s;
                        }
                    }
                    .dk-updateBtn{
                        background: #1f394c;
                        color: #fff;
                        font-size: 16px;
                        border-radius: 5px;
                        outline: none;
                        margin: 0 5px;
                        border: none;
                        padding: 8px 25px;
                        text-transform: capitalize;
                        &:hover{
                            background: #2d96b6;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
.ReactCrop__crop-selection{
    border-radius: 50%;
}

.dk-cropImgModal{
    .modal-dialog{
        .modal-content{
            .modal-body{
                .ReactCrop{
                    .ReactCrop__image{
                        width: 100%;
                        height: 370px;
                        object-fit: cover;
                    }
                }
            }
            .modal-footer{
                button{
                    display: inline-block;
                    width: 100%;
                    max-width:100px;
                    min-width:100px;
                    padding:8px 12px;
                    border:none;
                    margin-right:10px;
                    background: #1f394c;
                    color: #fff;
                    outline: none;
                    border-radius: 5px;
                    text-transform: uppercase;
                    font-weight: 500;
                    font-size: 14px !important;
                    box-shadow: none !important;
                    &:hover{
                        background: #2d96b6;
                        transition: all 0.5s;
                    }
                }
                button.dk-updateBtn{
                    margin-right: 0;
                }
                button.dk-cancelBtn{
                    background: transparent;
                    color: #E2445B;
                    &:hover{
                        background: #E2445B;
                        transition: all 0.5s;
                        color: #fff;
                    }
                }
            }
        }
    }
}