.dk-deleteQueryMain{
    .modal-dialog{
        width: 100%;
        max-width: 500px;
        margin:0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .modal-content{
            border-radius: 5px;
            border:none;
            box-shadow:none;
            .modal-header{
                background-color: #26597d;
                border-radius: 4px 4px 0 0;
                padding: 8px 10px;
                .modal-title{
                    margin-bottom: 0;
                    width: 100%;
                    font-weight: bold;
                    text-align: center;
                }
                .close{
                    padding: 0;
                    margin: 0;
                    text-shadow: none;
                    position: absolute;
                    right: 12px;
                    top: 11px;
                    outline: none;
                    font-size: 24px;
                    &:hover{
                        color: #fff;
                    }
                }
            }
            .modal-body{
                padding: 20px 0;
                .dk-modal-title-color{
                    color: #dc3545;
                    padding: 20px 0 35px;
                    text-align: center;
                    font-size: 22px;
                    margin: 0;
                    border-bottom: 1px solid #ddd;
                    margin-bottom: 20px;
                }
                button{
                    color: #ffffff;
                    padding: 0.4em 0.8em;
                    border: none;
                    border-radius: 3px;
                    background: #dc3545;
                    font-weight: 500;
                    font-size: 16px;
                }
            }
        }
    }
}

.dk-deleteModalMain{
    .modal-dialog{
        min-width: 30%;
        max-width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin: 0 auto;
        .modal-content{
            border-radius: 5px;
            .modal-body{
                padding: 20px 25px;
                .dk-deleteModal-body{
                    max-width: 100%;
                    p{
                        font-size: 15px;
                        color: #e2445b;
                        font-weight:500;
                        padding: 15px 0;
                        display: block;
                        text-align: left;
                    }
                    .d-flex{
                        button{
                            border: 1px solid #333;
                            color: #333;
                            background: transparent;
                            display: inline-block;
                            width: 100%;
                            max-width: 90px;
                            border-radius: 5px;
                            padding: 7px 0;
                            transition: all .5s;
                            outline: none;
                            font-weight:500;
                            &:hover{
                                background: #333;
                                border-color: #333;
                                transition: all 0.5s;
                                color: #fff;
                            }
                        }
                        button.dk-yesBtn{
                            background: #e2445b;
                            border: 1px solid #e2445b;
                            color: #fff;
                            margin-left: 10px;
                            &:hover{
                                background: #333;
                                border-color: #333;
                                transition: all 0.5s;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}