.dk-blogspageMainCon{
    .pageHeadeTitle{
        padding: 10px 0px 15px;
        h2.section-title {
            margin-top: 16px;
            padding: 0 0 15px;
            font-size: 24px;
            text-transform: uppercase;
            text-align: center;
            color: #333;
            font-weight: 600;
        }
    } 
    .dk-blog-search-main {
        background-color: #325f7d;
        padding: 18px 35px;
        width: 100%;
        .dk-blog-searchCon{
            width: 100%;
            .dk-blog-search {
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 220px;
                input {
                    width: 100%;
                    height: 35px;
                    padding: 0 10px;
                    border: 1px solid #ccc;
                    border-radius: 5px 0 0 5px;
                    outline: none;
                }
                button {
                    padding: 0;
                    width: 100%;
                    max-width: 35px;
                    height: 35px;
                    box-shadow: none;
                    margin: 0;
                    background-color: #d0d0d0!important;
                    color: #6b6b6b;
                    font-size: 14px;
                    border-radius: 0 5px 5px 0;
                }
                button {
                    padding: 0;
                    width: 100%;
                    max-width: 35px;
                    height: 35px;
                    box-shadow: none;
                    margin: 0;
                    background-color: #d0d0d0!important;
                    color: #6b6b6b;
                    font-size: 14px;
                    border-radius: 0 5px 5px 0;
                }
            }
        }
        .dk-blog-search-filterbtn {
            width: 100%;
            max-width: 700px;
            .custom-select {
                border-radius: 5px!important;
            }
        }
    } 
    .dk-search-blog-div{
        padding: 30px 0 35px;
        .list-blog{
            &.mb-2rem {
                margin-bottom: 2rem;
            }
            a{
                .card.blog-thumbnail{
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    min-width: 0;
                    word-wrap: break-word;
                    background-color: #fff;
                    background-clip: border-box;
                    border: 1px solid rgba(0,0,0,.125);
                    border-radius: 10px;
                    .view{
                        div{
                            .img-fluid {
                                border-radius: 10px;
                                max-height: 170px;
                                min-height: 170px;
                            }
                        }
                    }
                    .card-body{
                        color: #333;
                        h3 {
                            font-size: 1.28571429rem;
                            margin-top: 0;
                        }
                        .card-text, .card-text:last-child {
                            margin-bottom: 0;
                        }
                    }
                    .card-footer {
                        padding: 5px 10px 4px!important;
                        margin-top: 11px;
                        border-bottom-right-radius: 10px;
                        background-color: #ececec;
                        &:last-child {
                            border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
                        }
                        img.posted_img{
                            width: 30px;
                            height: 30px;
                            cursor: pointer;
                            border-radius: 50%;
                            margin-top: 3px;
                        }
                        .text-333{
                            color: #333;
                            i{
                                font-size: 1em;
                                display: block;
                                color: #333;
                            }
                        }
                    }
                }
            }
        }
    }
    .dk-emplistPageination{
        .pagination.pagination-circle{
            color: #ccc;
            .page-item{
                .page-link {
                    background: #325f7d;
                    border:1px solid #325f7d ;
                    color: #fff;
                    height: 40px;
                    width: 40px;
                    margin-right: 3px;
                    margin-left: 3px;
                    font-size: 1.3em;
                    padding: 8px 15px;
                    border-radius: 50%;
                }
                &.active{
                    .page-link {
                        background: #ffee89;
                        color: #333;
                        border: 1px solid #ffee89;
                        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
                        transition: all .2s linear;
                    }
                }
            } 
        }
    }
}
// START SCSS FOR BLOGDETAILS PAGE
.dk-articledetail-container{
    padding: 0 30px;
    text-align: justify;
    .dk-art-detail-col{
        border: 1px solid #ccc;
        border-radius: 5px;
        .blog-title {
            padding-left: 15px!important;
            padding-bottom: 0;
            padding-top: 25px;
            font-size: 20px;
            font-weight: 600;
            padding: 15px 0;
        }
        .dk-rt-container-cols {
            padding: 15px;
            font-size: 15px;
            .blog-thumbnail {
                border-radius: 10px;
                box-shadow: 0 0 10px rgba(0,0,0,0.6);
            }
            p.bold-text{
                font-weight: 600;
            }
            .top-detail-rt {
                border-bottom: 1px solid #ccc;
                .dk-art-detail-profile {
                    width: 100%;
                    max-width: 21%;
                    background: #f4d03e;
                    padding: 15px 8px;
                    .dk-art-detail-img {
                        margin-right: 8px;
                        img{
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;

                        }
                    }
                    .dk-art-detail-text {
                        color: #333;
                        width: 125px;
                        .title {
                            font-weight: 600;
                        }
                        a {
                            display: inline-block;
                            border: 1px solid #333;
                            color: #333;
                            border-radius: 5px;
                            padding: 3px 12px;
                            font-weight: 600;
                            margin-top: 5px;
                            font-size: 13px;
                            width: 100%;
                            text-align: center;
                        }
                    }
                }
                .dk-art-detail-box{
                    width: 100%;
                    max-width: 16%;
                    padding: 15px;
                    text-align: center;
                    border: 1px solid #ccc;
                    border-right: none;
                    border-bottom: none;
                    &:last-child {
                        border: 1px solid #ccc;
                        border-bottom: none;
                    }
                    .title {
                        font-weight: 600;
                        margin-bottom: 15px;
                        .blog-icon-color {
                            color: #325f7d;
                            margin-right: 10px;
                        }
                        .icon-title {
                            position: relative;
                            top: -5px;
                        }
                        .dk-rt-detail-star {
                            font-size: 14px;
                            display: inline-block;
                            color: #333;
                        }
                        button{
                            border: navajowhite;
                            background: transparent;
                        }
                    }
                }
            }
        }
    }
    .dk-art-detail-col{
        border: 1px solid #ccc;
        border-radius: 5px;
        color: #333;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        border-color: rgb(175, 170, 170);
        padding-left: 15px;
        .dk-rt-social {
            color: #333;
            max-width: 100%;
            display: flex;
            flex-wrap: wrap;
            a{
                display: flex;
                padding: 8px 20.5px;
                border-radius: 5px;
                align-items: center;
                color: #fff;
                margin: 5px 8px 5px 0;
                &.dk_facebook{
                    background-color: #3b5999;
                }
                &.dk_twitter {
                    background-color: #1c9dec;
                }
                &.dk_linkedin {
                    background-color: #0271ae;
                }
                &.dk_instagram {
                    background-color: #d82b63;
                } 
                &.dk_email {
                    background-color: #cc0a0a;
                } 
                i{
                    margin-right: 7px;
                }                  
            }
        }
    }
}
// END SCSSS FOR BLOGDETAILS PAGE