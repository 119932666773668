.dk-paymenteditModal{
    .modal-dialog{
        min-width: 500px;
        max-width: 500px;
        .modal-content{
            border: none;
            border-radius: .125rem;
            .dk-paymenteditModalHeader.modal-header{
                padding: 10px 20px 0;
                border-bottom: none;
                .modal-title {
                    width: 100%;
                    text-align: center;
                    font-weight: 700;
                    text-transform: uppercase;
                    font-size: 20px;
                }
                .close{
                    font-size: 24px;
                    outline: none;
                    padding: 12px 10px 0;
                    font-weight: 600;
                    &:hover{
                        background-color: #eaeaea;
                        color: #333;
                        border-top-right-radius: 5px;
                    }
                }
            }
            .dk-paymenteditModalBody{
                form{
                    padding:20px;
                    .form-group{
                        margin-bottom: 10px;
                        label{
                            width: 100%;
                            color: #7d7777;
                            input{
                                height: 40px;
                                border-radius: 0;
                            }
                        }
                        label.dkcard-number{
                            position: relative;
                            &::before{
                                content: "";
                                background: url('../../../../../../../../assets/images/card_icon.png') no-repeat;
                                padding: 13px 15px;
                                position: absolute;
                                top: 7px;
                                background-size: 28px;
                                left: 10px;
                            }
                            input{
                                padding-left: 50px;
                            }
                        }
                        .dkmake-primary{
                            input{
                                height: 20px;
                                width: 16px;
                            }
                            label{
                                margin: 0 5px;
                            }
                        }
                    }
                    .dk-paymentEditBtn{
                        font-size: 13px;
                        padding:12px 20px;
                        outline: none;
                        box-shadow: none;
                        border-radius: 0;
                        border:none;
                        margin-right: 10px;
                        text-transform: uppercase;
                        color: #fff;
                        font-weight:400;
                        &:first-child{
                            background-color: transparent!important;
                            color: #333;
                            &:hover{
                                background: #E2445B !important;
                                transition: all 0.5s;
                                color: #fff;
                            }
                        }
                        &:last-child{
                            background-color: #3b97b6!important;
                            margin-right: 0;
                            &:hover{
                                background: #107b10!important;
                                transition: all 0.5s;
                            }
                        }
                    }
                }
            }
        }
    }
}