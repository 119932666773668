.dk-createResumeMain{
    margin-top: 20px;
    .dk-createResumeNavPills{
        .dk-createResumeNav-Item{
            margin-right: 15px;
            .nav-link{
                color: #616161;
                padding: 8px 0;
                margin-right: 10px;
                span {
                    display: inline-block;
                    width: 25px;
                    height: 25px;
                    background: #ccc;
                    text-align: center;
                    line-height: 25px;
                    border-radius: 50%;
                    font-weight: 600;
                    margin-right: 5px;
                }
                &.nav-link.active{
                    color: #d2271b;
                    background-color: transparent;
                    span{
                        background-color: #d2271b;
                        color: #fff;
                    }
                }
            }
        }
    }
    .dk-createResumeTabContent{
        .dk-createResumeTab-Pane{
            .dk-apperenceCvCon{
                .dk-headingCon{
                    h6{
                        margin: 0;
                        display: block;
                        padding: 35px 0;
                        font-size: 16px;
                        font-weight: 500;
                        text-align: left;
                    }
                }
                .dk-apperance-CVBox{
                    .dk-profileImgCols{
                        border: 1px solid #9c9c9c;
                        position: relative;
                    }
                    figure {
                        opacity: 0;
                        transition: all .5s;
                        margin: 0 0 1rem;
                        .dk-checkIcon {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            -webkit-transform: translate(-50%,-50%);
                            transform: translate(-50%,-50%);
                            i {
                                width: 75px;
                                height: 75px;
                                background: #f0af13;
                                text-align: center;
                                line-height: 75px;
                                border-radius: 50%;
                                color: #fff;
                            }
                        }
                        .footSec {
                            background: #333;
                            padding: 12px;
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            button {
                                background: #f2f2f2;
                                width: 100%;
                                padding: 8px 12px;
                                text-align: center;
                                font-size: 14px;
                                font-weight: 500;
                                outline: none;
                                border: none;
                                border-radius: 4px;
                            }
                        }
                    }
                    &:hover{
                        figure{
                            opacity: 1;
                            .dk-checkIcon {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                -webkit-transform: translate(-50%,-50%);
                                transform: translate(-50%,-50%);
                                i {
                                    width: 75px;
                                    height: 75px;
                                    background: #f0af13;
                                    text-align: center;
                                    line-height: 75px;
                                    border-radius: 50%;
                                    color: #fff;
                                }
                            }
                            .footSec {
                                background: #333;
                                padding: 12px;
                                position: absolute;
                                bottom: 0;
                                width: 100%;
                                button {
                                    background: #f2f2f2;
                                    width: 100%;
                                    padding: 8px 12px;
                                    text-align: center;
                                    font-size: 14px;
                                    font-weight: 500;
                                    outline: none;
                                    border: none;
                                    border-radius: 4px;
                                }
                            }
                        }
                    }
                }
            }
            .dk-persionalDetailCon{
                padding: 35px 0 25px;
                .dk-persionalLeftCon{
                    h3 {
                        font-weight: 500;
                        font-size: 24px;
                        text-align: left;
                    }
                    form {
                        padding:0;
                        .form-group {
                            margin-bottom: 1rem;
                            .leftCols {
                                width: 100%;
                                max-width: 280px;
                                text-align: left;
                                label {
                                    font-weight: 600;
                                    font-size: 14px;
                                }
                                a.dk-backBtn {
                                    color: #212529;
                                    background-color: #dae0e5;
                                    border: 1px solid #ccc;
                                    display: block;
                                    width: 100%;
                                    max-width: 140px;
                                    text-align: center;
                                    padding: 6px 0;
                                    border-radius: 4px;
                                }
                            }
                            .rightCols{
                                width: 100%;
                                label {
                                    width: 100%;
                                    input{
                                        border: 1px solid #9c9c9c;
                                        border-radius: 4px;
                                        height: 35px;
                                    }
                                }
                                .gender {
                                    margin-right: 10px;
                                    display: flex;
                                    input {
                                        margin-right: 5px;
                                        position: relative;
                                        top: 4px;
                                    }
                                }
                                a {
                                    background: #bb2318;
                                    margin-right: 20px;
                                    padding: 6px 12px;
                                    border-radius: 4px;
                                    text-align: center;
                                    color: #fff;
                                    &:last-child {
                                        background-color: #dae0e5;
                                        border: 1px solid #ccc;
                                        color: #212529;
                                    }
                                }
                            }
                        }
                        .fileUpload-cols{
                            .dk-fileUpload{
                                label.uploadCols {
                                    position: relative;
                                    width: 100%;
                                    max-width: 80px;
                                    height: 80px;
                                    background: #eaeaea;
                                    border-radius: 4px;
                                    border: 1px solid #ccc;
                                    input {
                                        width: 100%;
                                        max-width: 80px;
                                        height: 80px;
                                        opacity: 0;
                                        position: relative;
                                        z-index: 9;
                                    }
                                    span{
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        -webkit-transform: translate(-50%,-50%);
                                        transform: translate(-50%,-50%);
                                        z-index: 1;
                                        img{
                                            max-width: 100%;
                                            height: auto;
                                        }
                                    }
                                }
                                .photoDeleteBtn {
                                    margin-left: 10px;
                                    background: #eaeaea;
                                    border: 1px solid #ccc;
                                    width: 100%;
                                    max-width: 120px;
                                    text-align: center;
                                    padding: 4px 0;
                                    border-radius: 4px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
                .dk-persionalRightCon{
                    .dk-resumeImg-cols{
                        a {
                            display: block;
                            .title {
                                background: #f0af13;
                                padding: 10px 0;
                                text-align: center;
                                text-transform: uppercase;
                                font-size: 24px;
                                font-weight: 700;
                                color: #fff;
                            }
                            .resumeimgCon{
                                width: 100%;
                                border: 1px solid #8b8b88;
                                img{
                                    width: 100%;
                                    height: auto;
                                }
                            }
                        }
                        &:hover{
                            a{
                                .title{
                                    color: #333;
                                }
                            }
                        }
                    }
                }
            }
            .dk-educationCon{
                padding: 35px 0 25px;
                .dk-educationLeftCon{
                    h3 {
                        font-weight: 500;
                        font-size: 24px;
                        text-align: left;
                    }
                    form {
                        padding:0;
                        .form-group {
                            margin-bottom: 1rem;
                            .leftCols {
                                width: 100%;
                                max-width: 280px;
                                text-align: left;
                                label {
                                    font-weight: 600;
                                    font-size: 14px;
                                }
                                a.dk-backBtn {
                                    color: #212529;
                                    background-color: #dae0e5;
                                    border: 1px solid #ccc;
                                    display: block;
                                    width: 100%;
                                    max-width: 140px;
                                    text-align: center;
                                    padding: 6px 0;
                                    border-radius: 4px;
                                }
                            }
                            .rightCols{
                                width: 100%;
                                label {
                                    width: 100%;
                                    input{
                                        border: 1px solid #9c9c9c;
                                        border-radius: 4px;
                                        height: 35px;
                                    }
                                    textarea.dk-eductionteatArea{
                                        border: 1px solid #9c9c9c;
                                        border-radius: 4px;
                                        height: 150px;
                                        resize: vertical;
                                    }
                                }
                                .gender {
                                    margin-right: 10px;
                                    display: flex;
                                    input {
                                        margin-right: 5px;
                                        position: relative;
                                        top: 4px;
                                    }
                                }
                                a {
                                    background: #bb2318;
                                    margin-right: 20px;
                                    padding: 6px 12px;
                                    border-radius: 4px;
                                    text-align: center;
                                    color: #fff;
                                    &:last-child {
                                        background-color: #dae0e5;
                                        border: 1px solid #ccc;
                                        color: #212529;
                                    }
                                }
                            }
                        }
                        .fileUpload-cols{
                            .dk-fileUpload{
                                label.uploadCols {
                                    position: relative;
                                    width: 100%;
                                    max-width: 80px;
                                    height: 80px;
                                    background: #eaeaea;
                                    border-radius: 4px;
                                    border: 1px solid #ccc;
                                    input {
                                        width: 100%;
                                        max-width: 80px;
                                        height: 80px;
                                        opacity: 0;
                                        position: relative;
                                        z-index: 9;
                                    }
                                    span{
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        -webkit-transform: translate(-50%,-50%);
                                        transform: translate(-50%,-50%);
                                        z-index: 1;
                                        img{
                                            max-width: 100%;
                                            height: auto;
                                        }
                                    }
                                }
                                .photoDeleteBtn {
                                    margin-left: 10px;
                                    background: #eaeaea;
                                    border: 1px solid #ccc;
                                    width: 100%;
                                    max-width: 120px;
                                    text-align: center;
                                    padding: 4px 0;
                                    border-radius: 4px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
                .dk-educationRightCon{
                    .dk-resumeImg-cols{
                        a {
                            display: block;
                            .title {
                                background: #f0af13;
                                padding: 10px 0;
                                text-align: center;
                                text-transform: uppercase;
                                font-size: 24px;
                                font-weight: 700;
                                color: #fff;
                            }
                            .resumeimgCon{
                                width: 100%;
                                border: 1px solid #8b8b88;
                                img{
                                    width: 100%;
                                    height: auto;
                                }
                            }
                        }
                        &:hover{
                            a{
                                .title{
                                    color: #333;
                                }
                            }
                        }
                    }
                }
            }
            .dk-workexpMainCon{
                padding: 35px 0 25px;
                .dk-workexperienceLeftCon{
                    .headingCon{
                        h3 {
                            font-weight: 500;
                            font-size: 24px;
                            text-align: left;
                        }
                        h6 {
                            margin: 0;
                            display: block;
                            padding: 35px 0;
                            font-size: 16px;
                            font-weight: 500;
                            text-align: left;
                        }
                    }
                    h3 {
                        font-weight: 500;
                        font-size: 24px;
                        text-align: left;
                    }
                    form {
                        padding:0;
                        .form-group {
                            margin-bottom: 1rem;
                            .leftCols {
                                width: 100%;
                                max-width: 280px;
                                text-align: left;
                                label {
                                    font-weight: 600;
                                    font-size: 14px;
                                }
                                a.dk-backBtn {
                                    color: #212529;
                                    background-color: #dae0e5;
                                    border: 1px solid #ccc;
                                    display: block;
                                    width: 100%;
                                    max-width: 140px;
                                    text-align: center;
                                    padding: 6px 0;
                                    border-radius: 4px;
                                }
                            }
                            .rightCols{
                                width: 100%;
                                label {
                                    width: 100%;
                                    input{
                                        border: 1px solid #9c9c9c;
                                        border-radius: 4px;
                                        height: 35px;
                                    }
                                    textarea.dk-eductionteatArea{
                                        border: 1px solid #9c9c9c;
                                        border-radius: 4px;
                                        height: 150px;
                                        resize: vertical;
                                    }
                                }
                                .gender {
                                    margin-right: 10px;
                                    display: flex;
                                    input {
                                        margin-right: 5px;
                                        position: relative;
                                        top: 4px;
                                    }
                                }
                                a {
                                    background: #bb2318;
                                    margin-right: 20px;
                                    padding: 6px 12px;
                                    border-radius: 4px;
                                    text-align: center;
                                    color: #fff;
                                    &:last-child {
                                        background-color: #dae0e5;
                                        border: 1px solid #ccc;
                                        color: #212529;
                                    }
                                }
                            }
                        }
                        .fileUpload-cols{
                            .dk-fileUpload{
                                label.uploadCols {
                                    position: relative;
                                    width: 100%;
                                    max-width: 80px;
                                    height: 80px;
                                    background: #eaeaea;
                                    border-radius: 4px;
                                    border: 1px solid #ccc;
                                    input {
                                        width: 100%;
                                        max-width: 80px;
                                        height: 80px;
                                        opacity: 0;
                                        position: relative;
                                        z-index: 9;
                                    }
                                    span{
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        -webkit-transform: translate(-50%,-50%);
                                        transform: translate(-50%,-50%);
                                        z-index: 1;
                                        img{
                                            max-width: 100%;
                                            height: auto;
                                        }
                                    }
                                }
                                .photoDeleteBtn {
                                    margin-left: 10px;
                                    background: #eaeaea;
                                    border: 1px solid #ccc;
                                    width: 100%;
                                    max-width: 120px;
                                    text-align: center;
                                    padding: 4px 0;
                                    border-radius: 4px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
                .dk-workexperienceRightCon{
                    .dk-resumeImg-cols{
                        a {
                            display: block;
                            .title {
                                background: #f0af13;
                                padding: 10px 0;
                                text-align: center;
                                text-transform: uppercase;
                                font-size: 24px;
                                font-weight: 700;
                                color: #fff;
                            }
                            .resumeimgCon{
                                width: 100%;
                                border: 1px solid #8b8b88;
                                img{
                                    width: 100%;
                                    height: auto;
                                }
                            }
                        }
                        &:hover{
                            a{
                                .title{
                                    color: #333;
                                }
                            }
                        }
                    }
                }
                .dk-fillForm{
                    form{
                        h3{
                            font-weight: 500;
                            font-size: 24px;
                            text-align: left;
                        }
                        h6{
                            margin: 0;
                            display: block;
                            padding:0 0 30px;
                            font-size: 16px;
                            font-weight: 500;
                            text-align:left;
                        }
                        .leftCols{
                            width: 100%;
                            max-width: 280px;
                            text-align: left;
                            .textCols{
                                font-weight: 600;
                                font-size: 14px;
                            }
                        }
                        .soughtCols{
                            width: 100%;
                            label.soughtLabel{
                                width: 100%;
                            }
                        }
                        .rightCols.dk-selectBox{
                            width: 100%;
                            max-width: 400px;
                            label{
                                select{
                                    border: 1px solid #9c9c9c !important;
                                    border-radius: 4px;
                                    margin-right: 8px;
                                    height: 35px;
                                }
                            }
                            
                            .trash-addBtn{
                                a{
                                    background: #bb2318;
                                    margin-right:8px;
                                    padding: 6px 12px;
                                    border-radius: 4px;
                                    text-align: center;
                                    color: #fff;
                                    height: 35px;
                                    &:last-child{
                                        background-color: #dae0e5;
                                        border: 1px solid #ccc;
                                        color: #212529;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .dk-skillsMainCon{
                padding: 35px 0 25px;
                .dk-skillsLeftCon{
                    .headingCon{
                        h3 {
                            font-weight: 500;
                            font-size: 24px;
                            text-align: left;
                        }
                        h6 {
                            margin: 0;
                            display: block;
                            padding: 35px 0;
                            font-size: 16px;
                            font-weight: 500;
                            text-align: left;
                        }
                    }
                    h3 {
                        font-weight: 500;
                        font-size: 24px;
                        text-align: left;
                    }
                    form {
                        padding: 8px 15px;
                        .form-group {
                            margin-bottom: 1rem;
                            .leftCols {
                                width: 100%;
                                max-width: 280px;
                                text-align: left;
                                label {
                                    font-weight: 600;
                                    font-size: 14px;
                                }
                                a.dk-backBtn {
                                    color: #212529;
                                    background-color: #dae0e5;
                                    border: 1px solid #ccc;
                                    display: block;
                                    width: 100%;
                                    max-width: 140px;
                                    text-align: center;
                                    padding: 6px 0;
                                    border-radius: 4px;
                                }
                            }
                            .rightCols{
                                width: 100%;
                                label {
                                    width: 100%;
                                    input{
                                        border: 1px solid #9c9c9c;
                                        border-radius: 4px;
                                        height: 35px;
                                    }
                                    textarea.dk-eductionteatArea{
                                        border: 1px solid #9c9c9c;
                                        border-radius: 4px;
                                        height: 150px;
                                        resize: vertical;
                                    }
                                    .form-control.dk-skillsSelectPicker{
                                        min-width: 165px;
                                        max-width: 165px;
                                    }
                                    .dk-deleteAddBtnCon{
                                        display: flex;
                                        align-items: center;
                                    }
                                }
                                .gender {
                                    margin-right: 10px;
                                    display: flex;
                                    input {
                                        margin-right: 5px;
                                        position: relative;
                                        top: 4px;
                                    }
                                }
                                a {
                                    background: #bb2318;
                                    margin-right: 20px;
                                    padding: 6px 12px;
                                    border-radius: 4px;
                                    text-align: center;
                                    color: #fff;
                                    &:last-child {
                                        background-color: #dae0e5;
                                        border: 1px solid #ccc;
                                        color: #212529;
                                    }
                                }
                            }
                        }
                        .fileUpload-cols{
                            .dk-fileUpload{
                                label.uploadCols {
                                    position: relative;
                                    width: 100%;
                                    max-width: 80px;
                                    height: 80px;
                                    background: #eaeaea;
                                    border-radius: 4px;
                                    border: 1px solid #ccc;
                                    input {
                                        width: 100%;
                                        max-width: 80px;
                                        height: 80px;
                                        opacity: 0;
                                        position: relative;
                                        z-index: 9;
                                    }
                                    span{
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        -webkit-transform: translate(-50%,-50%);
                                        transform: translate(-50%,-50%);
                                        z-index: 1;
                                        img{
                                            max-width: 100%;
                                            height: auto;
                                        }
                                    }
                                }
                                .photoDeleteBtn {
                                    margin-left: 10px;
                                    background: #eaeaea;
                                    border: 1px solid #ccc;
                                    width: 100%;
                                    max-width: 120px;
                                    text-align: center;
                                    padding: 4px 0;
                                    border-radius: 4px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
                .dk-skillsRightCon{
                    .dk-resumeImg-cols{
                        a {
                            display: block;
                            .title {
                                background: #f0af13;
                                padding: 10px 0;
                                text-align: center;
                                text-transform: uppercase;
                                font-size: 24px;
                                font-weight: 700;
                                color: #fff;
                            }
                            .resumeimgCon{
                                width: 100%;
                                border: 1px solid #8b8b88;
                                img{
                                    width: 100%;
                                    height: auto;
                                }
                            }
                        }
                        &:hover{
                            a{
                                .title{
                                    color: #333;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}