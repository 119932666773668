.dk-billingHistroy {
    padding: 30px 15px;
    h3 {
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        text-transform: uppercase;
    }
    .dk-billingHistroy-fluid {
        background: #d9d9d9;
        border: 1px solid #b9b9b9;
        padding: 25px;
        .dk-contactPerson-form{
            form{
                .form-group.uplaod-image{
                    .dk-contactPerson-image {
                        width: 100%;
                        max-width: 150px;
                        border: 1px solid #ccc;
                        padding: 0;
                        margin-right: 15px;
                        height: 150px;
                        display: flex;
                        align-items: center;
                        background: #fff;
                        justify-content: center;
                        overflow: hidden;
                        border-radius: 50%;
                        img {
                            width: 100%;
                            max-width: 150px;
                            object-fit: cover;
                            border-radius: 50%;
                            height: 150px;
                            object-position: top center;
                        }
                    }
                    .dk-contactPerson-uploadBtn {
                        position: relative;
                        width: 100%;
                        max-width: 120px;
                        margin-left: 15px;
                        display: none;
                        input[type=file] {
                            width: 120px;
                            height: 35px;
                            position: relative;
                            z-index: 9;
                            opacity: 0;
                            cursor: pointer;
                        }
                        span {
                            display: inline-block;
                            width: 100%;
                            height: 35px;
                            line-height: 35px;
                            top: 0;
                            left: 0;
                            background: #1f394c;
                            color: #fff;
                            border-radius: 3px;
                            text-align: center;
                            position: absolute;
                            z-index: 1;
                            cursor: pointer;
                        }
                    }
                }
                .dk-ownerForm-note {
                    border: 1px solid #f2f2f2;
                    border-left: 4px solid #2d96b6;
                    background: #f2f2f2;
                    padding: 22px 20px;
                }
                .form-group{
                    label {
                        width: 100%;
                        input {
                            width: 100%;
                            height: 40px;
                            border-radius: 0;
                        }
                    }
                }
            }
        }
    }
}