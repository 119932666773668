.jobseeker-standard-dashboard {
    background-color: #67818e;
    padding: 10px 0 0;
    max-width: 100%; 
    .dk-jobseeker-fluid{
        width: 100%;
        max-width: 1600px;
        margin: auto;
        .d-flex{
            .dk-ds-profile{
                .dk-ds-profile-img{
                    img{
                        width: 80px;
                        height : 80px;
                        border-radius : 50%;
                        margin: 5px 0 8px;
                    }
                }
            }
            .dk-ds-menu {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                margin: 0!important;
                .bell-singOutbtnCon {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                    padding-top: 5px;
                    .bellCon{
                        .dropdown{
                            .dropdown-toggle.btn{
                                color: #fff;
                                svg{
                                    font-size: 18px;
                                    margin-top: -7px;
                                }
                                &::after{
                                    display: none
                                }
                                
                            }
                        }
                    }
                    .sigoutbtnCon{
                        .sigoutbtn{
                            display: inline-block;
                            background: #2d96b6;
                            color: #fff;
                            padding: 5px 25px ;
                            border-radius: 30px;
                        }
                    }
                }
                .dk-ds-menu-cols{
                    width: 100%;
                    ul{
                        list-style: none;
                        display: flex;
                        height: 100%;
                        align-items: flex-end;
                        margin-bottom: 0;
                        li{
                            width: 100%;
                            max-width: 10%;
                            text-align: center;
                            a{
                                color: #fff;
                                font-size: 13px;
                                padding: 12px 5px;
                                width: 100%;
                                background-color: #1f394c;
                                display: flex;
                                color: #fff;
                                font-weight: 600;
                                border: 1px solid #868686;
                                border-right: none;
                                border-bottom: none;
                                justify-content: center;
                                white-space: nowrap;
                                &.active{
                                    background-color: #fff;
                                    color: #333
                                }
                                &:hover{
                                    background-color: #363f4c;
                                    color: #fff;
                                    transition: all .5s;
                                }
                                span.badge {
                                    padding: 0.35em 0.57em;
                                    font-size: 11px;
                                }
                                svg{
                                    margin-top: 4px;
                                }
                            }
                            &:first-child a{
                                border-top-left-radius: 5px;
                            }
                            &:last-child a{
                                border-top-right-radius: 5px;
                            }
                            a.grey-active{
                                background: #d9d9d9;
                                color: #222;
                            }
                        }
                    }
                }
            }
        }
    }
}