.dkjobs-induCarCon{
    padding: 20px 10px;
    text-align: center;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 5px #999;
    transition: all .4s ease-in-out;
    margin: 0 5px 30px;
    a,a:hover{
        color: #1a3445;
        .cardImgCon{
            margin: 0 0 1rem;
            img {
                width: 100%;
                max-width: 70px;
            }
        }
        h3 {
            margin: 0;
            padding: 0;
            font-size: 17px;
            color: #000;
            font-weight: 600;
        }
        p {
            margin: 15px 0 0;
            color: #25455a;
        }
    }
}
.dkjobs-induCarCon:hover {
    box-shadow: 1px 8px 11px #a2a2a2;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}