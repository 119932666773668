.modal-dialog.dkjobs-infoPop{
    min-width : 30%;
    .modal-content{
        box-shadow: none;
        background-color: transparent;
        .modal-header{
            background: #2e97b6;
            color: #fff;
            border-radius: 0;
            padding: 9px;
            border-top-left-radius: 9px;
            border-top-right-radius: 9px;
            .modal-title{
                width: 100%;
                text-align: center;
                font-weight: 700;
            }
        }
        .modal-body{
            padding: 20px 30px;
            background-color: #F2F2F2;
            border-bottom-left-radius: 9px;
            border-bottom-right-radius: 9px;
        }
    }

}


