.dk-paymenttitleinfoModal{
    .modal-dialog{
        min-width: 35%;
        max-width: 35%;
        .modal-content{
            background-color: transparent;
            border: none;
            .dk-titleinfoModalHeader.modal-header{
                padding: 6px 0;
                background: #f7bb44;
                border-radius: 5px 5px 0 0;
                position: relative;
                border-bottom: none;
                .modal-title {
                    width: 100%;
                    text-align: center;
                    color: #fff;
                    font-size: 18px;
                    font-weight: 600;
                }
                .close{
                    text-shadow: none;
                    opacity: 1;
                    color: #fff;
                    position: absolute;
                    right: 1px;
                    top: 1px;
                    padding: 0.6rem 0.75rem;
                    margin: 0;
                    &:hover{
                        background-color: #eaeaea;
                        color: #333;
                        border-top-right-radius: 5px;
                    }
                }
            }
            .dk-titleinfoModalBody{
                background-color: #fff;
                border-radius: 0 0 5px 5px;
                padding: 15px;
            }
        }
    }
}