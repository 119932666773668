.dk-docaddnewFileModal{
    .modal-dialog{
        min-width: 500px;
        max-width: 500px;
        .modal-content{
            border: none;
            background-color: transparent;
            .dk-docaddnewFileModalHeader{
                padding: 8px;
                text-align: center;
                width: 100%;
                background: #1f394c;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px 5px 0;
                .modal-title {
                    font-weight: 700;
                    font-size: 18px;
                    width: 100%;
                    text-align: center;
                }
                .close{
                    opacity: 1;
                    span{
                        color: #fff;
                        text-shadow: none;
                        &:hover{
                            opacity: 1;
                        }
                    }
                }
            }
            .dk-docaddnewFileModalBody{
                background-color: #fff;
                // border-radius: 0 0 5px 5px;
                .form-group{
                    label {
                        width: 100%;
                        font-weight: 400;
                        color: #666;
                        input {
                            width: 100%;
                            height: 35px;
                            border: 1px solid #ccc;
                            padding: 0 10px;
                            outline: none;
                            border-radius: 0;
                        }
                        select{
                            border-radius: 0 !important;
                        }
                        textarea{
                            border-radius: 0;
                        }
                    }
                }
            }
            .dk-docaddnewFileModalFooter{
                border-radius: 0 0 5px 5px;
                background-color: #fff;
                .closebtn{
                    padding: 8px 20px;
                    font-size: 14px;
                    border-radius: 3px;
                    background-color: #fff;
                    color: #333;
                    // box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
                    border: none;
                }
                .submitbtn{
                    padding: 8px 20px;
                    font-size: 14px;
                    border-radius: 3px;
                    background-color: #6c757d;
                    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
                    border: none;
                }
            }
        }        
    }
}
