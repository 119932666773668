.dk-paymenremoveModal{
    .modal-dialog{
        min-width: 450px;
        max-width: 450px;
        .modal-content{
            border: none;
            border-radius: 5px;
            .dk-paymenremoveModalHeader.modal-header{
                padding: 10px 0;
                background: #1f394b;
                border-radius: 4px 4px 0 0;
                position: relative;
                border-bottom: none;
                .modal-title {
                    font-weight:500;
                    color: #fff;
                    display: block;
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                }
                .close{
                    margin: 0;
                    text-shadow: none;
                    color: #fff;
                    opacity: 1;
                    padding: 0;
                    outline: none;
                    font-size: 24px;
                    font-weight: normal;
                    position: absolute;
                    right: 12px;
                }
            }
            .dk-paymenremoveModalBody{
                background-color: #fff;
                border-radius: 0 0 5px 5px;
                padding: 15px;
                p {
                    font-size: 18px;
                    padding: 20px 0;
                    margin: 0;
                    text-align: center;
                    font-weight: 400;
                }
                .dk-continuebtnCon{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    margin-top: 15px;
                    margin-bottom: 10px;
                    button.dk-continuebtn{
                        font-size: 14px;
                        padding: 8px 18px;
                        outline: none;
                        text-transform: uppercase;
                        background-color: #E2445B;
                        color: #fff;
                        border: 1px solid #E2445B;
                        &:hover {
                            background-color: #2d96b6;
                            border: 1px solid #2d96b6;
                            transition: all .5s;
                        }
                    }
                }
            }
        }
    }
}