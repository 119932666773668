.dk-privacyPolicyMain{
    padding: 30px 15px;
    color: #fff;
    .pageTitle{
        h1 {
            font-size: 28px;
            font-weight: 600;
            margin-bottom: 15px;
            color: #333;
            span{
                font-weight: 700;
            }
        }
    }
    .dk-privacyAccordiaon{
        .MuiAccordion-root{
            background-color: #262f3c;
            color: #fff;
            border-bottom: 1px solid #1b232e;
            &:first-child{
                border-radius: 0;
            }
            &:last-child{
                border-radius: 0;
            }
            &::before{
                display: none;
            }
            .MuiAccordionSummary-content{
                p{
                    font-size: 15px;
                    font-weight: 300;
                }
            }
            .MuiButtonBase-root.MuiAccordionSummary-root{
                .MuiAccordionSummary-expandIcon {
                    color: #fff;
                }
            }
            .MuiAccordionSummary-root.Mui-expanded{
                min-height: auto;
                .MuiAccordionSummary-content.Mui-expanded{
                    margin: 0;
                }
            }
        }
        .MuiAccordion-root.Mui-expanded{
            margin: 0;
        }
        .MuiCollapse-root{
            background-color: #fff;
            color: #333;
            font-weight: 500;
            font-size: 15px;
            border: 1px solid #4d5a6b;
            .MuiCollapse-wrapper{
                .MuiCollapse-wrapperInner{
                    .MuiAccordionDetails-root{
                        padding: 20px;
                        p{
                            font-size: 15px;
                        }
                    }
                }
            }
        }
        
    }
}