.dk-empCompProfilePage{
    margin-top: 45px;
    .dk-comProfilepills{
        width: 100%;
        max-width: 85%;
        .dk-comProfileNavItem{
            .nav-link {
                border-radius: 0;
                background: #d9d9d9;
                border: none;
                border-bottom: 1px solid #000;
                color: #000;
                text-align: left;
                &.active {
                    background: #67808e;
                    color: #fff;
                    position: relative;
                    &:after {
                        content: "\f105";
                        display: inline-block;
                        font-family: "FontAwesome";
                        font-size: inherit;
                        font-weight: 700;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        position: absolute;
                        right: 8px;
                        top: 8px;
                        font-size: 18px;
                    }
                }
            }
            &:last-child{
                a{
                    border-bottom: none;
                }
            }
        }
    }
    .dk-comProfileTabContent{
        width: 100%;
        background: #67808e;
        box-shadow: 0 0 10px rgba(0,0,0,0.19);
        padding: 25px;
        .dk-comProfileTabPane.uploadLogo{
            .dk-uploadlogopanel{
                .dk-stProfile{
                    width: 100%;
                    max-width: 200px;
                    border: 1px solid #ccc;
                    padding: 5px;
                    margin-right: 15px;
                    height: 150px;
                    display: flex;
                    align-items: center;
                    background: #fff;
                    justify-content: center;
                    img{
                        width: 100%;
                        max-width: 130px;
                        object-fit: cover;
                    }
                }
                .dk-stImg-uploadBtn{
                    position: relative;
                    width: 120px;
                    height: 35px;
                    input {
                        width: 120px;
                        height: 35px;
                        position: relative;
                        z-index: 9;
                        cursor: pointer;
                        opacity: 0;
                    }
                    span {
                        margin: 0;
                        width: 100%;
                        max-width: 100%;
                        height: 35px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                        background: #1f394c;
                        cursor: pointer;
                        color: #fff;
                        line-height: 34px;
                        border-radius: 5px;
                        text-align: center;
                    }
                }

            }
            .dk-ownerForm-note{
                background: #fff;
                padding: 40px 20px;
                border: 1px solid #f2f2f2;
                border-left: 4px solid #2d96b6;
            }
        }
        .dk-comProfileTabPane.aboutUs{
            .dk-aboutsFromInputGrp{
                .form-group {
                    margin-bottom: 14px;
                    display: flex;
                    align-items: center;
                    label {
                        min-width: 160px;
                        margin-bottom: 0;
                        color: #fff;
                        font-family: "Open Sans",sans-serif;
                        font-weight: 500;
                        font-size: 14px;
                        text-align: left;
                    }
                    input {
                        border-right: none;
                        border-bottom: none;
                        border-radius: 0;
                    }
                }
            }
            .dk-aboutUsRightPanel{
                .dk-ownerForm-note{
                    background: #fff;
                    padding: 40px 20px;
                    border: 1px solid #f2f2f2;
                    border-left: 4px solid #2d96b6;
                }
                .dk-textAreaCon{
                    label.title{
                        text-align: left;
                        width: 100%;
                        font-size: 16px;
                        font-weight: 700;
                        color: #fff;
                    }
                    label{
                        width: 100%;
                        textarea{
                            width: 100%;
                            height: 220px;
                            padding: 15px;
                        }
                    }
                }
            }
            .dk-updateBtnCon{
                display: flex;
                justify-content: center;
                align-items: center;
                .dk-updateBtn{
                    display: inline-block;
                    background: #1f394c;
                    cursor: pointer;
                    color: #fff;
                    text-align: center;
                    line-height: 35px;
                    border-radius: 5px;
                    min-width: 125px;
                }
            }
        }
        .dk-comProfileTabPane.contactUs{
            .form-group.dk-contactUsFrmGrp{
                margin-bottom: 1rem;
                label.title{
                    display: block;
                    text-align: left;
                    color: #fff;
                }
                label{
                    width: 100%;
                    input.form-control{
                        border-radius: 0;
                    }
                }

            }
            .dk-socialWithInput{
                label{
                    span{
                        max-width: 34px;
                        margin-right: 12px;
                        img{
                            width: 100%;
                        }
                    }
                    input.form-control{
                        border-radius: 0;
                    }
                }
                .dk-updatebtnCon{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .dk-updatebtn{
                        display: inline-block;
                        background: #1f394c;
                        cursor: pointer;
                        color: #fff;
                        text-align: center;
                        line-height: 35px;
                        border-radius: 5px;
                        min-width: 125px;
                    }
                }
            }
        }
        .dk-comProfileTabPane.previewCon{
            .dk-empBtnprviewCon{
                display: flex;
                justify-content: center;
                align-items: center;
                .dk-empBtnprview{
                    display: inline-block;
                    padding: 12px 25px;
                    background: #1f394b;
                    color: #fff;
                    border-radius: 5px;
                    min-width: 180px;
                    text-align: center;

                }
            }
        }
    }
}