.modal-dialog.dksignUpFaqModal{
    width: 100%;
    max-width: 700px;
    .modal-content {
        box-shadow: none;
        border: none;
        border-radius: 5px;
        .dk-signupHeader{
            .modal-title {
                font-weight: 700;
                font-size: 18px;
                width: 100%;
                text-align: center;
            }
        }
        .dk-signupBody{
            color: #333;
            .MuiAccordion-rounded{
                .MuiAccordionSummary-root {
                    background: #325f7e;
                    padding: 0;
                    min-height: auto;
                     .MuiAccordionSummary-content{
                         color: #fff;
                         min-height: auto;
                         padding: 12px 15px;
                         margin: 0;
                     }
                     .MuiIconButton-edgeEnd {
                         padding: 0 8px;
                         margin: 0;
                         color: #fff;
                         .MuiIconButton-label {
                             width: 100%;
                             display: flex;
                             align-items: inherit;
                             justify-content: inherit;
                             font-size: 17px;
                         }
                     }
                }
                 .MuiAccordion-root.Mui-expanded:last-child  .MuiAccordionSummary-root{
                    border-radius: 0 0 0 0;
                }
                .MuiAccordion-rounded{
                    box-shadow: none;
                }
                .MuiCollapse-wrapper{
                    .MuiCollapse-wrapperInner{
                        .MuiAccordionDetails-root {
                            display: flex;
                            padding: 8px 16px 16px;
                            background-color: #2e3641;
                            p {
                                color: #fff;
                                letter-spacing: .5px;
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
            .MuiAccordion-rounded:first-child{
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                .MuiAccordionSummary-root {
                    border-radius: 8px 8px 0 0;
                    background: #325f7e;
                    padding: 0;
                    min-height: auto;
                     .MuiAccordionSummary-content{
                         color: #fff;
                         min-height: auto;
                         padding: 12px 15px;
                         margin: 0;
                     }
                     .MuiIconButton-edgeEnd {
                         padding: 0 8px;
                         margin: 0;
                         color: #fff;
                         .MuiIconButton-label {
                             width: 100%;
                             display: flex;
                             align-items: inherit;
                             justify-content: inherit;
                             font-size: 17px;
                         }
                     }
                 }
                 .MuiAccordion-root.Mui-expanded:last-child  .MuiAccordionSummary-root{
                    border-radius: 0 0 0 0;
                 }
                 .MuiAccordion-rounded{
                    box-shadow: none;
                 }
            }
            .MuiAccordion-rounded:last-child .MuiAccordionSummary-root {
                border-radius: 0 0 8px 8px;
            }
            .MuiAccordion-rounded:last-child {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
            }
            .MuiAccordion-root.Mui-expanded{
                margin: 0;
                border: 1px solid #808080;
            }
        }
    }
}