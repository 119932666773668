.dk-signupPage-main{
    background: #1f4353;
    padding: 0 15px;
    height: 100vh;
    display: flex;
    align-items: center;
    .dk-signColbox{
        width: 100%;
        max-width: 500px;
        margin: 50px auto;
        background: #fff;
        border-radius: 5px;
        .title{
            text-transform: uppercase;
            font-weight: 700;
            font-size: 20px;
            text-align: center;
            padding: 15px 0;
            border-bottom: 1px solid #ddd;
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            .dk-signupfaqBtn {
                background: #fbbb57;
                display: inline-block;
                padding: 5px 12px;
                border-radius: 30px;
                position: absolute;
                right: 5px;
                line-height: 20px;
                a {
                    color: #333;
                    font-weight: 600;
                    font-size: 13px;
                    text-decoration: none;
                }
            }
        }
        .dk-signCols{
            padding-bottom: 45px;
            .sbtitle {
                text-align: center;
                padding: 25px 0;
                display: block;
            }
            a{
                width: 130px;
                height: 120px;
                background: #1f4353;
                margin: 0 10px;
                border-radius: 5px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                line-height: 35px;
                text-decoration: none;
                span{
                    i,svg{
                        display: block;
                        width: 100%;
                        font-size: 46px;
                        text-align: center;
                    }
                    
                }
            }
            a:hover {
                background: #2d96b6;
            }
        }
    }
}