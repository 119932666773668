.dk-searchBox-main{
    .dk-jobseekerSt-searchMain{
        margin-top: 15px;
        section{
            width: 100%;
            max-width: 1600px;
            background: #fff;
            margin: 0 auto;
            border-radius: 5px;
            position: relative;
            padding-top: 15px;
            .dk-faqCols{
                position: relative;
                margin: 0 15px;
                text-align: right;
                a{
                    background: #48b5e5;
                    border: 1px solid #48b5e5;
                    color: #fff!important;
                    display: inline-block;
                    padding: 5px 18px;
                    border-radius: 30px;
                    font-weight: 600;
                    &:hover{
                        background: #1f394c;
                        border-color: #1f394c;
                        color: #fff;
                        transition: all .5s;
                    }
                }
            }
            .section-title{
                padding-bottom: 0;
                color: #333;
                margin:0;
                font-weight: 600;
                font-size: 24px;
            }
            .dk-jobsfilter{
                .dk-mobViewFilter-main{
                    margin-top: 58px;
                    width: 100%;
                    padding: 15px;
                    border-radius: 3px;
                    .dk-jobsfilter-search{
                        margin-bottom: 1.1em;
                        label{
                            margin:0 0 15px;
                            input{
                                height: 43px;
                                background: #f0f0f0;
                                border-radius: 3px 0 0 3px!important;
                                font-size: 14px;
                                border-right: 0;
                            }
                            .dk-filter-search-btn{
                                background: #f0f0f0;
                                height: 43px;
                                width: 48px;
                                border: 1px solid #ddd;
                                border-radius: 0 5px 5px 0;
                            }
                        }
                    }
                    .dk-jobsfilter-cols{
                        background: #f0f0f0;
                        padding: 10px 15px;
                        font-size: 14px;
                        font-weight: 300;
                        border: 1px solid #ddd;
                        .d-flex{
                            margin-bottom:10px;
                            span{
                                font-weight: 500;
                                color: #00f;
                            }
                        }
                        .title{
                            font-size: 14px;
                            margin-bottom: 15px;
                            color: #333;
                            font-weight: 700;
                            text-transform: uppercase;
                            text-align: left;
                        }
                        &:nth-child(02){
                            border-radius: 5px 5px 0 0;
                        }
                        .custom-checkbox{
                            label{
                                color: #333;
                                font-weight: 400;
                                &::before{
                                    border-radius: 0;
                                    background: #ccc;
                                    border-color: #ccc;
                                    top: 3px;
                                }
                                &::after{
                                    top: 3px;
                                }
                            }
                            input:checked~.custom-control-label:before{
                                color: #fff;
                                border-color: #007bff;
                                background-color: #007bff;
                            }
                        }
                        .custom-checkbox.fulltime{
                            .custom-control-label{
                                &::after{
                                    background-color: #0170c1;
                                    border-color: #0170c1;
                                }
                            }
                        }
                        .custom-checkbox.parttime{
                            .custom-control-label{
                                &::after{
                                    background-color:#34976e;
                                    border-color:#34976e;
                                }
                            }
                        }
                        .custom-checkbox.freelance{
                            .custom-control-label{
                                &::after{
                                    background-color:#34976e;
                                    border-color:#34976e;
                                }
                            }
                        }
                        .custom-checkbox.remote{
                            .custom-control-label{
                                &::after{
                                    background-color:#ec8b5e;
                                    border-color:#ec8b5e;
                                }
                            }
                        }
                        .custom-checkbox.intership{
                            .custom-control-label{
                                &::after{
                                    background-color:#ffc001;
                                    border-color:#ffc001;
                                }
                            }
                        }
                    }
                }
            }
            .col-md-3.custom-w{
                max-width: 22%;
                flex: 22% 1;
                @media(min-width:767px){
                    padding-right: 0.5rem!important;
                }
            }
            .col-md-7.custom-w{
                padding-left: 0;
                padding-right: 0;
                max-width: 60%;
                flex: 60% 1;
            }
            .dk-joblistingMain{
                padding: 15px 0;
                border-radius: 3px;
                width: 100%;
                max-width: 97%;
                position: relative;
                .dk-jobslisting-category{
                    background: #fff;
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    ul.filterUl{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        white-space: nowrap;
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        list-style-type: none;
                        overflow: hidden;
                        li{
                            display: inline;
                            color: #000;
                            float: left;
                            padding: 0!important;
                            min-width: 11.11%;
                            width: 100%;
                            text-align: center;
                            border-right: 1px solid #ccc;
                            font-size: 13px;
                            font-weight: 600;
                            a{
                                background-color: #f0f0f0;
                                color: #333;
                                display: block;
                                padding: 10px 0;
                            }
                            &:last-child{
                                border-right: none;
                                a.active{
                                    border-radius:0 5px 5px 0;
                                }
                            }
                            a.active{
                                background: #194254;
                                color: #fff;
                            }
                            &:first-child{
                                a.active{
                                    border-radius: 5px 0 0 5px;
                                }
                            }
                        }
                    }
                }
                .dk-jobslisting-tagselect{
                    background: #f0f0f0;
                    border: 1px solid #ddd;
                    margin: 15px 0 10px;
                    border-radius: 3px;
                    padding: 7px 0 7px 12px;
                    font-size: 14px;
                    .dk-jobs-list{
                        color: #333;
                    }
                    .dk-jobs-cate-link{
                        a.box{
                            border: 1px solid #ccc;
                            display: inline-block;
                            margin: 0 5px;
                            border-radius: 30px;
                            position: relative;
                            font-size: 11px;
                            font-weight: 600;
                            color: #333;
                            padding:2px 27px 2px 8px;
                            background: #ccc;
                            &:hover{
                                background: #2d96b6;
                                color: #fff;
                                transition: all .5s;
                                border-color: #2d96b6;
                                i{
                                    background-color: #1780a0;
                                    color: #fff;
                                    transition: all .5s;
                                }
                            }
                            i{
                                position: absolute;
                                right: 0;
                                background: #a5a5a5;
                                padding:7px 6px;
                                top: 0;
                                border-radius: 0 30px 30px 0;
                                color: #fff;
                            }
                        }
                        a{
                            &:last-child{
                                margin-left: 0;
                                background: #fff;
                                width: 100%;
                                font-weight: 700;
                                padding: 11px 12px;
                                color: #007bff;
                                text-transform: uppercase;
                                border-radius: 0 2px 2px 0;
                                font-size: 12px;
                                &:hover{
                                    background: #325f7d;
                                    color: #fff;
                                    transition: all .5s;
                                }
                            }
                        }
                    }
                }
            }

            .col-md-2.custom-w{
                padding-right: 0;
            }

            .dk-companiesBox{
                background: #fff;
                border: 1px solid #ddd;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 25px 15px;
                margin-bottom: 10px;
                &:first-child{
                    margin-top: 127px;
                }
            }

            .dk-boxes-cols{
                background: #f0f0f0;
                border: 1px solid #ddd;
                border-radius:3px;
                padding:0;
                margin-bottom: 10px;
                .dk-boxes-cols-img{
                    text-align: center;
                    margin-top: 5px;
                    img{
                        width: 100%;
                        max-width: 95px;
                    }
                    .dk-boxes-btn.fulltime{
                        color: #0170c1;
                        border: 1.5px solid #0170c1;
                    }
                    .dk-boxes-btn{
                        display: block;
                        width: 100%;
                        max-width: 170px;
                        margin: 5px auto;
                        padding: 5px 0;
                        border-radius: 0;
                        font-size: 14px;
                        font-weight: 700;
                    }
                }
                .dk-boxes-cols-right{
                    .dk-boxes-cols-right-cols{
                        width: 100%;
                        .title{
                            text-align: left;
                            margin-top: 12px;
                            a{
                                font-size: 16px;
                                margin-bottom: 2px;
                                display: block;
                                color: #184354;
                                font-weight: 700;
                                pointer-events: none;
                            }
                        }
                        .discription{
                            font-size: 13px;
                            color: #000;
                        }
                        .dk-boxes-right-cols-desc{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: 15px;
                            width: 100%;
                            max-width: 325px;
                            li{
                                list-style-type: none;
                                font-size: 13px;
                                width: 100%;
                                position: relative;
                                margin-bottom: 7px;
                                color: #000;
                                display: flex;
                                white-space: nowrap;
                                align-items: center;
                                i{
                                    color: #9c9c9c;
                                    display: inline-block;
                                    width: 22px;
                                    text-align: left;
                                }
                            }                            
                        }
                    }
                    .col-xs-12.col-lg-4{
                        max-width: 32%;
                        flex: 32% 1;
                    }
                    .dk-boxes-cols-right-cols-viewjobs{
                        border-left: 1px solid #ddd;
                        padding:0;
                        a{
                            border: #194254;
                            color: #333;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            border-radius: 0;
                            padding:14px 15px;
                            margin-right: 0;
                            font-size: 14px;
                            width: 100%;
                            text-align: center;
                            border-bottom: 1px solid #ddd;
                            display: block;
                            width: 100%;
                            &:last-child{
                                border-bottom: none;
                                &:hover{
                                    border-radius: 0 0 3px 0;
                                }
                            }
                            &:first-child{
                                &:hover{
                                    border-radius:0 3px 0 0;
                                }
                            }
                            &:hover{
                                transition: all 0.5s;
                                background: #2d96b6;
                                border-color: #2d96b6;
                                color: #fff;
                            }
                            i{
                                display: inline-block;
                                width: 20px;
                                text-align: left;
                            }
                        }
                    }
                }
                .dk-searchByHead{
                    padding:10px 12px;
                    display: flex;
                    position: relative;
                    .dk-bacjBtn{
                        display: inline-block;
                        a{
                            display:block;
                            background: #6c757d;
                            color: #fff;
                            padding:7px 15px;
                            text-transform: uppercase;
                            white-space: nowrap;
                            font-size: 13px;
                            border-radius: 5px;
                            &:hover{
                                background: #194254;
                                color: #fff;
                                transition: all 0.5s;
                            }
                        }
                    }
                    .title{
                        position: absolute;
                        left: 50%;
                        top: 17px;
                        transform: translate(-50%);
                        font-size: 17px;
                        color: #333;
                        font-weight: 700;
                        display: inline-block;
                    }
                }
                .dk-searchByPanel{
                    border-top: 1px solid #ccc;
                    padding: 12px;
                    ul{
                        margin:0;
                        padding: 0;
                        list-style: none;
                        li{
                            list-style-type: none;
                            padding-bottom: 10px;
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            label.form-check-label{
                                cursor: pointer;
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.dk-faqModalMain{
    .modal-dialog{
        max-width: 700px;
        min-width: 700px;
        .modal-content{
            box-shadow: none;
            border: none;
            border-radius: 5px;
            .modal-header{
                padding: 8px 0;
                color: #333;
                .modal-title{
                    display: block;
                    text-align: center;
                    width: 100%;
                    font-weight: 400;
                    font-size: 18px;
                }
                .close{
                    margin: 0;
                    padding: 0;
                    position: absolute;
                    right: 11px;
                    top: 9px;
                    opacity: 1;
                    color: #333;
                    font-size: 25px;
                    font-weight:600;
                    outline: none;
                }
            }
            .modal-body{
                .MuiAccordion-rounded{
                    box-shadow: none;
                    &:first-child{
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                        .MuiAccordionSummary-root{
                            border-radius: 8px 8px 0 0;
                        }
                    }
                    &:last-child{
                        .MuiAccordionSummary-root{
                            border-radius:0 0 8px 8px;
                        }
                    }
                    .MuiAccordionSummary-root{
                        background: #325f7e;
                        padding: 0;
                        min-height: auto;
                        .MuiAccordionSummary-content{
                            color: #fff;
                            min-height: auto;
                            padding: 12px 15px;
                            margin: 0;
                            p{
                                color: #fff;
                                font-size: 14px;
                                font-weight: 400;
                                text-transform: uppercase;
                                letter-spacing: .5px;
                            }
                        }
                        .MuiIconButton-edgeEnd{
                            padding: 0 8px;
                            margin: 0;
                            color: #fff;
                        }
                    }
                }
                .MuiAccordion-root.Mui-expanded{
                    margin-bottom: 0;
                    margin: 0;
                    &::before{
                        opacity: 1;
                    }
                    &:last-child{
                        .MuiAccordionSummary-root{
                            border-radius:0;
                        }
                    }
                }
                .MuiCollapse-wrapper{
                    background:#2e3641;
                    .MuiCollapse-wrapperInner{
                        width: 100%;
                        .MuiAccordionDetails-root{
                            p{
                                color: #fff;
                                letter-spacing: .5px;
                                font-size:14px;
                                text-align:right;
                            }
                        }
                    }
                }
                .MuiAccordion-root{
                    &:last-child{
                        .MuiCollapse-root{
                            .MuiCollapse-wrapper{
                                border-radius: 0 0 8px 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}