.dk-gdprexpMainPage{
    padding: 30px 15px;
    font-family: "Open Sans",sans-serif;
    h1 {
        margin: 0;
        background: #1e3342;
        text-align: center;
        padding: 8px 0;
        color: #fff!important;
        text-transform: uppercase;
        font-size: 22px;
        span {
            font-weight: 700;
        }
    }
    .dk-cookiesCols {
        padding: 25px 15px;
        border: 1px solid #ccc;
        border-top: none;
        h3 {
            font-size: 22px;
            font-weight: 600;
            text-align: left;
            color: #333;
        }
        p {
            font-size: 15px;
            text-align: left;
        }
        ul {
            padding: 0;
            margin: 0 0 15px;
            list-style: none;
            li {
                list-style-type: none;
                margin-bottom: 7px;
                text-align: left;
                position: relative;
                &:before {
                    content: "\f105";
                    font-family: "FontAwesome";
                    -moz-osx-font-smoothing: grayscale;
                    -webkit-font-smoothing: antialiased;
                    display: inline-block;
                    font-style: normal;
                    -webkit-font-feature-settings: normal;
                    font-feature-settings: normal;
                    font-variant: normal;
                    text-rendering: auto;
                    line-height: 1;
                    font-weight: 900;
                    font-size: 12px;
                    margin-right: 5px;
                    position: relative;
                    bottom: 2px;
                }
                a{
                    color: #333;
                    &:hover {
                        margin-left: 10px;
                        transition: all .5;
                        opacity: .8;
                    }
                }
            }
        }
    }
}