*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body,html{
    font-family: 'Open Sans', sans-serif;
}

a, a:hover{
    text-decoration: none;
}
.dkjobsNavBarMainCon{
    padding: 0 1rem !important;
    background-color: #fff;
    font-weight: 300;
    -moz-box-shadow: 0px 0px 5px 0px #333;
    -webkit-box-shadow: 0px 0px 5px 0px #333;
    box-shadow: 0px 0px 5px 0px #333;
    .dkjobsNavBarCon{
        padding: 0;
        .navbar-brand.dk-dkjobsLogoCon{
            img.dkjobslogo{
                width: 165px;
            }
        }
        .navbar-nav{
            a{
                margin: 0;
                padding: 5px 15px;
                color: #325f7d !important;
                font-size: 14px;
                font-weight: 600;
            }
        }
        .navbar-nav.dk-jobsnls_bg{
            margin: 0 0 0 1%;
            padding:  0 !important;
            background: #eaeaea;
            border-radius: 5px;
            box-shadow: 1px 1px 4px #999;
            min-width: 179px;
            .dk-jobsheaderbtn.nav-link {
                border-radius: 3px;
                margin-right: 5px;
                padding: 5px 10px!important;
                color: #325f7d;
                font-weight: 600;
                font-size: 14px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .dkjobsNavBarMainCon{
        .dkjobsNavBarCon{
            .navbar-brand.dk-dkjobsLogoCon{
                img.dkjobslogo{
                    width: 165px;
                }
            }
            .navbar-nav{
                .nav-link{
                    text-align: left;
                }
            }
            .navbar-nav.dk-jobsnls_bg{
              display: flex;
              justify-content: left;
              align-items: center;
            }
        }
    }
}
@media only screen and (min-width: 600px) {
}
@media only screen and (min-width: 768px) {

}
@media only screen and (min-width: 992px) {

}
@media only screen and (min-width: 1200px) {

}