.dk-settFaqPageMain{
    margin-top: 20px;
    .dk-settFaqPageCon{
        color: #333;
        .dk-faqSideMainCon{
            .dk-faqSideMenu{
                width: 100%;
                max-width: 85%;
                    a.nav-link{
                        border-bottom: 1px solid #1f394c;
                        border-radius: 0;
                        background-color: #67818e;
                        border: none;
                        color: #fff;
                        font-size: 15px;
                        position: relative;
                        display: flex;
                        align-items: center;
                        &.nav-link.active{
                            background-color: #1f394c;
                            &:after {
                                content: "";
                                width: 0;
                                height: 0;
                                border-color: transparent transparent transparent #1f394c;
                                border-style: solid;
                                border-width: 8.5px 0 8.5px 12px;
                                line-height: 0;
                                _border-color: #000 #000 #000 #1f394c;
                                _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                                position: absolute;
                                right: -12px;
                                top: 9px;
                                transform: translate(0px,0px);
                                -webkit-transform: translate(0px, 0px);
                            }
                        }
                    }
            }
            .dk-settFaqAccCont{
                background-color: #1f394c;
                .dk-faqSetTabpane{
                    .dk-faqAccordion{
                        .dk-faqAccCard{
                            border-radius: 0;
                            border-top: 0;
                            border-bottom: 1px solid #27333c;
                            .dk-faqAccHeader.card-header{
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                background: #1f394c;
                                color: #fff;
                                cursor: pointer;
                                height: 48px;
                            }
                            .dk-faqAccCardBody{
                                background-color: #67818e;
                                color: #fff;
                                display: flex;
                                text-align: left;
                            }
                        }
                    }
                }
            }
        }
    }
}