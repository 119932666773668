.dk-EmployerMain{
    background-color: #25455a;
    padding: 50px 0;
    .dk-empsingupCont{
       background-color: transparent;
        h2.title{
           font-size: 24px;
           font-weight: 600;
           text-align: center;
           width: 100%;
           padding:  0;
           padding-bottom: 0;
           color: #fff;
       }
    }
    .dk-EmpFormContiner{
        margin: 0 auto;
        max-width: 800px;
        border-radius: 8px;
        padding: 30px;
        background: #f2f2f2;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
        .dk-EmpFormContent{
            form{
                color: #333;
                .dk-empFrmtitle{
                    h3{
                        font-weight: 700;
                        font-size: 20px;
                        margin-bottom: 10px;
                        color: rgba(0, 0, 0, 0.87);
                        text-align: left;
                    }
                }
                .browser-default.custom-select{
                    padding: 7px 5px;
                    font-size: 14px;
                    border-radius: 0;
                }
                .form-group{
                    margin-bottom: 20px;
                    input.form-control{
                        width: 100%;
                        height: auto;
                        display: block;
                        border: 1px solid #ccc;
                        padding: 7px 10px;
                        border-radius: 0;
                        margin: 0;
                        font-size: 13px;
                        background: #fff;
                    }
                    input.form-control:focus{
                        box-shadow: none;
                    }
                    textarea {
                        padding: 10px;
                        height: 50px;
                        font-size: 13px;
                        resize: vertical;
                        outline: none;
                        width: 100%;
                        border: 1px solid #ccc;
                        border-radius: 0;
                    }
                }
                .dk-termsplicytextCon{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    p{
                        color: #000;
                        font-size: 14px;
                        a {
                            color: #007bff;
                            cursor: pointer;
                            text-decoration: none;
                        }
                    }
                }
                .dk-formsubmit-buttonCon{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    .formsubmit-button{
                        border: none;
                        padding: 10px 45px;
                        color: #fff;
                        border-radius: 5px;
                        font-size: 14px;
                        background-color: #25455a;
                        letter-spacing: 0.02857em;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}