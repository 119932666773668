.dkjobs-contactUS{
    background-color: #25455a;
    color: #fff;
    h2.section-title{
        padding: 30px 0 35px;
        margin: 0;
        font-size: 24px;
        color: #fff;
    }
    .dk-contact-cols{
        width: 100%;
        background-color: #38607c;
        box-shadow: 0 0 15px rgba(0,0,0,0.45);
        padding: 50px 15px 30px;
        .dk-contact-textarea{
            background-color: #fff;
            padding: 95px 25px;
            color: #222;
            position: relative;
            left: -75px;
            .title {
                font-size: 18px;
                font-weight: 600;
                color: #25455a;
                margin-bottom: 10px;
                text-align: left;
            }
            ul {
                padding: 0;
                list-style: none;
                li {
                    list-style-type: none;
                    display: block;
                    margin: 20px 0;
                    text-align: left;
                    i{
                        font-size: 16px;
                    }
                    a {
                        color: #222;
                    }
                }
            }
            .dk-contact-socialicon{
                a {
                    margin-right: 10px;
                    width: 30px;
                    height: 30px;
                    img{
                        border-radius: 5px;
                    }
                }
            }
        }
        .dk-contact-form {
            color: #fff;
            margin-left: -30px;
            .title {
                font-size: 18px;
                text-align: left;
            }
            form {
                width: 100%;
                .form-group {
                    margin-top: 15px;
                    label {
                        width: 100%;
                        margin-bottom: 15px;
                        input {
                            border-radius: 5px;
                            border: none;
                            width: 100%;
                            max-width: 330px;
                        }
                        textarea {
                            border-radius: 5px;
                            border: none;
                            width: 100%;
                            max-width: 100%;
                            height: 90px;
                            resize: vertical;
                        }
                        button {
                            background-color: #fff!important;
                            border-radius: 5px;
                            color: #222;
                            font-size: 14px;
                            padding: 10px 25px;
                            display: flex;
                            justify-content: left;
                            text-transform: uppercase;
                            font-weight: 500;

                        }
                    }
                    label.terms-dflex {
                        input {
                            width: 20px;
                            height: 20px;
                            position: relative;
                            top: 2px;
                        }
                        span {
                            position: relative;
                            top: -3px;
                            padding-left: 7px;
                            a {
                                color: #5d9de2;
                            }
                        }
                    }
                }
            }
        }
    }
}