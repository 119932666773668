.dk-changeLoginPage{
    margin-top: 40px;
    .dk-changeLoginPageCon{
        .dk-boxsCon{
            color: #333;
            .dk-informationCols{
                padding: 35px 25px;
                background: #e2e2e2;
                text-align: left;
                position: relative;
                width: 100%;
                max-width: 94%;
                &::before{
                    content: "";
                    width: 6px;
                    height: 100%;
                    background: #2d96b6;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
            .dk-complinceCon{
                background: #fff9f9;
                color: #333;
                .title{
                    background: #67818e;
                    padding: 0 10px;
                    color: #fff;
                    font-size: 18px;
                    text-align: center;
                    border-top-left-radius: 5px ;
                    border-top-right-radius: 5px;
                    height: 45px;
                    line-height: 45px;
                }
                .dk-complaince-Body{
                    background-color: #d9d9d9;
                    label {
                        width: 100%;
                        font-weight: 400;
                        position: relative;
                        color: #333;
                        text-align: left;
                        margin-bottom: 0;
                        span {
                            width: 40px;
                            height: 40px;
                            background: #ccc;
                            text-align: center;
                            line-height: 40px;
                            &:hover{
                                background: #2d96b6;
                                color: #fff;
                                cursor: pointer;
                            }
                            i {
                                color: #333;
                            }
                        }
                    }
                    input.form-control{
                        width: 100%;
                        height: auto;
                        display: block;
                        border: 1px solid #ccc;
                        padding: 7px 10px;
                        border-radius: 0;
                        margin: 0;
                        font-size: 13px;
                        background: #fff;
                    }
                    input.form-control:focus{
                        box-shadow: none;
                    }
                    .btn-container{
                        .dk-cancelBtn{
                            background: transparent;
                            color: #d40505;
                            font-size: 16px;
                            border-radius: 5px;
                            outline: none;
                            margin: 0 5px;
                            border: none;
                            padding: 8px 25px;
                            &:hover{
                                background: #d40505;
                                color: #fff;
                            }
                        }
                        .dk-updateBtn{
                            background: #1f394c;
                            color: #fff;
                            font-size: 16px;
                            border-radius: 5px;
                            outline: none;
                            margin: 0 5px;
                            border: none;
                            padding: 8px 25px;
                            text-transform: capitalize;
                            &:hover{
                                background: #2d96b6;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}