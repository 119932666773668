.dk-servpageCon{
    margin-top: 2rem;
    .dk-headingCon{
        h1{
            margin: 0 0 30px;
            text-align: center;
            font-size: 22px;
            display: inline-block;
            position: relative;
            font-weight: 600;
            color: #000;
            &:before {
                position: absolute;
                left: 0;
                bottom: -14px;
                width: 100%;
                height: 1px;
                background: #ddd;
                content: "";
            }
            :after {
                margin: auto;
                position: absolute;
                left: 0;
                right: 0;
                bottom: -16px;
                width: 100px;
                height: 4px;
                border-radius: 5px;
                background: #1d618e;
                content: "";
            }
            span{
                font-weight: 700;
            }
        }
    }
    .dk-textCon{
        padding: 0 5%;
        p{
            text-align: justify;
        }
    }
}
.dk-AboutLogoSec{
    margin: 60px 0 10px;
    padding: 50px 5%;
    background: #1d618e;
    text-align: center;
    h2 {
        margin: 0 0 50px;
        text-align: center;
        font-size: 22px;
        display: inline-block;
        position: relative;
        font-weight: 600;
        color: #fff;
        &:before {
            position: absolute;
            left: 0;
            bottom: -14px;
            width: 100%;
            height: 1px;
            background: #fff;
            content: "";
        }
        &:after {
            margin: auto;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -16px;
            width: 100px;
            height: 4px;
            border-radius: 5px;
            background: #fff;
            content: "";
        }
        span {
            font-weight: 700;
        }
    }
    .logo_f {
        margin: 0 0 70px;
        display: flex;
        justify-content: center;
        position: relative;
        &:before {
            margin: 0 auto;
            content: "";
            background: #fff;
            width: 1px;
            height: 38px;
            position: absolute;
            top: 90%;
            left: 0;
            right: 0;
        }
        :after {
            content: "";
            position: absolute;
            bottom: -30px;
            width: 86%;
            height: 1px;
            background: #bfbfbf;
            left: 7%;
        }
        figure {
            margin: 0 auto 5px;
            padding: 15px;
            max-width: 150px;
            display: block;
            border-radius: 5px;
            background: #fff;
            img {
                max-width: 100%;
            }
        }
    }
    ul {
        padding: 0;
        display: flex;
        justify-content: space-between;
        li {
            list-style: none;
            color: #fff;
            text-align: center;
            width: 100%;
            position: relative;
            &:before {
                margin: 0 auto;
                content: "";
                background: #fff;
                width: 1px;
                height: 42px;
                position: absolute;
                bottom: 98%;
                left: 0;
                right: 0;
            }
            figure {
                margin: 0 auto 5px;
                padding: 15px;
                max-width: 150px;
                display: block;
                border-radius: 5px;
                background: #fff;
                img {
                    max-width: 100%;
                }
            }
        }
    }
}
.dk-corporateSec{
    margin: 0;
    padding: 50px 5%;
    background: #fff;
    text-align: center;
    h2 {
        margin: 0 0 30px;
        text-align: center;
        font-size: 22px;
        display: inline-block;
        position: relative;
        font-weight: 600;
        color: #000;
        &:before {
            position: absolute;
            left: 0;
            bottom: -14px;
            width: 100%;
            height: 1px;
            background: #ddd;
            content: "";
        }
        &:after {
            margin: auto;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -16px;
            width: 100px;
            height: 4px;
            border-radius: 5px;
            background: #1d618e;
            content: "";
        }
    }
    .dk-cor-box{
        margin: 70px 0 20px;
        padding: 10px;
        background: #e7eff2;
        border: 1px solid #e0e4e7;
        border-radius: 5px;
        figure {
            background: #1d618e;
            width: 120px;
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: -60px auto 15px;
            border-radius: 100%;
            border: 5px solid #fff;
            img {
                width: 110px;
            }
        }
        h3 {
            font-size: 1.75rem;
            font-weight: 500;
            color: #000;
        }
        p {
            text-align: justify;
        }
    }
}
.dk-mvSec{
    // padding-left: 0 !important;
    // padding-right: 0 !important;
    margin: 0 0 50px;
    background: #e7eff2;
    h2{
        margin: 0 0 30px;
        text-align: center;
        font-size: 22px;
        display: inline-block;
        position: relative;
        font-weight: 600;
        color: #000;
        &:before {
            position: absolute;
            left: 0;
            bottom: -14px;
            width: 100%;
            height: 1px;
            background: #ddd;
            content: "";
        }
        &:after {
            position: absolute;
            left: 50%;
            bottom: -16px;
            width: 40px;
            height: 4px;
            border-radius: 5px;
            background: #1d618e;
            content: "";
            -webkit-transform: translate(-50%);
            transform: translate(-50%);
        }
    }
    p{
        text-align: justify;
        font-size: 14px;
    }
}