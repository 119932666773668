.dk-signupthankyou{
    background-color: #25455a;
    padding: 50px 0;
    height: 100vh;
    .dk-topsingupCont{
        background-color: transparent;
         h2.title{
            font-size: 24px;
            font-weight: 600;
            text-align: center;
            width: 100%;
            padding:  0;
            padding-bottom: 0;
            color: #fff;
        }
    }
    .dk-FormContiner{
        margin: 0 auto;
        max-width: 800px;
        border-radius: 8px;
        padding: 30px;
        background: #f2f2f2;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
        p{
            color: #333;
        }
    }
    .login-bottomSec {
        display: block;
        margin: 30px 0 0;
        width: 100%;
        text-align: center;
        li {
            list-style-type: none;
            a{
                display: block;
                color: #fff;
                margin: 15px 0 0;
                font-weight: 400;
                letter-spacing: .5px;
            }
            a:hover{
                text-decoration: underline;
            }
        }
    }
}