.dk-frontJobseekerMain{
    width: 100%;
    margin-top: 0;
    padding: 30px 0 15px;
    background: #f1f2f3;
    .dk-frontJobhead{
        padding: 0 15px;
        margin-bottom: 5px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3.title{
            margin: 0 0 8px;
            width: 100%;
            text-align: center;
            font-weight: 600;
            font-size: 22px;
            span{
                cursor: pointer;
                font-size: 18px;
                position: relative;
                top: -1px;
                color: #2d4961;
            }
        }
    }
    .dk-frontJobInner{
        width: 100%;
        max-width: 1600px;
        margin: auto;
        .dk-frontJobTags{
            padding: 15px;
            a{
                display: inline-block;
                background: #345b7e;
                font-size: 12px;
                color: #fff;
                border-radius: 30px;
                position: relative;
                padding: 5px 29px 5px 15px;
                &:hover{
                    background: #9c9c9c;
                    transition: all .5s;
                    span{
                        background: grey;
                    }
                }
                span{
                    font-size: 10px;
                    background: #2d4961;
                    display: inline-block;
                    position: absolute;
                    right: 0;
                    text-align: center;
                    padding: 6px 8px;
                    border-radius: 0 30px 30px 0;
                    top: 0;
                    line-height: 16px;
                }
            }
        }
        .dk-frontJobScroll{
            max-height: 500px;
            overflow-y: auto;
            .col-md-2{
                @media(min-width:768px){
                    flex: 1 0;
                    max-width: 20%;
                    padding: 0 8px;
                }
            }
            .dk-frontJobInner-cols{
                width: 100%;
                border-radius: 5px;
                background: #fff;
                font-size: 13px;
                padding: 5px 0 8px;
                position: relative;
                overflow: hidden;
                color: #000;
                box-shadow: 0 0 9px rgba(0,0,0,0.12);
                margin-bottom: 20px;
                border: 1px solid #ddd;
                .tagStatus{
                    border-radius: 5px 0 5px 0;
                    padding: 1px 12px;
                    font-size: 12px;
                }
                .tagStatus.green{
                    color: green;
                    font-weight: 600;
                }
                .dk-frontJob-times{
                    opacity: 0.5;
                    padding: 1px 12px;
                }
                .tagStatus.red{
                    color: #9c9c9c;
                    font-weight: 600;
                }
                .glogo{
                    width: 100%;
                    max-width: 95px;
                    margin: 15px auto 10px;
                    height: 95px;
                    border-radius: 50%;
                    cursor: pointer;
                    img{
                        width: 100%;
                        height: 95px;
                        border-radius: 50%;
                        max-width: 95px;
                    }
                }
                .Gtitle{
                    color: #28679f;
                    display: block;
                    h3{
                        font-size: 16px;
                        color: #28679f;
                        font-weight: 600;
                        text-align: center;
                    }
                }
                .dk-frontJob-details{
                    margin-top: 12px;
                    padding-left: 15px;
                    li{
                        list-style-type: none;
                        display: block;
                        padding: 3px 0;
                        i{
                            color: #a0a0a0;
                            display: inline-block;
                            width: 18px;
                            font-size: 14px;
                        }
                        span.dk-langImg{
                            width: auto;
                            display: inline-block;
                            position: relative;
                            top: -2px;
                            left: 6px;
                            img{
                                margin-right: 5px;
                            }
                        }
                    }
                }
                .dk-frontJob-info{
                    display: block;
                    margin: 5px 0 0;
                    border-top: 1px solid #ececec;
                    padding: 8px 0 0;
                    .dk-frontJob-info-cols{
                        font-size: 12px;
                        padding: 0 10px;
                        .title{
                            text-transform: uppercase;
                            color: #b3b3b3;
                            font-weight: 600;
                        }
                        .sbtitle{
                            text-align: center;
                        }
                    }
                }
            }
        }
        .dk-frontJobScroll::-webkit-scrollbar {
            width: 0;
        }
        
        .dk-frontJobScroll::-webkit-scrollbar-track {
            box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
        }
        
        .dk-frontJobScroll::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 0 solid slategrey;
        }
        .dk-frontJob-pagination{
            padding: 5px 15px;
            .dk-frontJob-paginationNav{
                a{
                    display: inline-block;
                    background: #fff;
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    line-height: 30px;
                    border: 1px solid #ddd;
                    border-right: none;
                    color: #333;
                    &:first-child{
                        border-radius: 3px 0 0 3px;
                    }
                    &:last-child{
                        border-radius: 0 3px 3px 0;
                        border-right: 1px solid #ddd;
                    }
                }
            }
        }
    }

    .dk-frontJobseekersFilter-main{
        .dk-frontJobseekersFilter-nav{
            background: #2d4961;
            padding: 0 15px;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            border-bottom: none!important;
            label{
                position: relative;
                margin-right: 12px;
                width: 100%;
                min-width: 150px;
                margin-bottom: 0;
                &::after{
                    content: "\f002";
                    display: inline-block;
                    font-family: "Font Awesome 5 Free";
                    font-size: inherit;
                    font-weight: 700;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    position: absolute;
                    right: 8px;
                    top: 8px;
                    color: #656565;
                }
                input{
                    border: none;
                    border-radius: 3px;
                    box-shadow: none !important;
                }
            }
            button.btn{
                background: transparent!important;
                box-shadow: none!important;
                padding: 14px 0 11px;
                margin: 0;
                font-size: 14px;
                color: #ccc;
                text-transform: capitalize;
                width: 100%;
                border:none !important;
                font-weight:300;
                position: relative;
                border-radius: 0 !important;
                &:hover{
                    background: #345b7e!important;
                    transition: all 0.5s;
                }
                i{
                    display: block;
                    font-size: 13px;
                    margin-bottom:2px;
                }
            }
            button.btn.active{
                background:#345b7e !important;
            }
        }
        .dk-frontJobseekersFilter-panel{
            background: #345b7e;
            padding: 0;
            .dk-frontJobSeekers-panelBody{
                .d-flex{
                    width: 100%;
                    max-width: 1240px;
                    margin: auto;
                }
                ul{
                    margin:0;
                    list-style: none;
                    padding: 22px 0;
                    li{
                        list-style-type: none;
                        a{
                            color: #f2f2f2;
                            display: block;
                            margin: 5px 0;
                            font-size: 13px;
                            font-weight: 300;
                            span.text-orange{
                                color: orange;
                            }
                        }
                    }
                }
            }
        }
    }
    
}

.dk-jobseekerDetails-modal{
    .modal-dialog{
        width: 100%;
        max-width: 75%;
        .modal-content{
            border: none;
            box-shadow: none;
            border-radius: 5px;
            overflow: hidden;
            .modal-body{
                padding: 0;
                .dk-frontCandiadteModal-box{
                    .dk-onofTags{
                        position: absolute;
                        left: 14px;
                        top: 0;
                        &::before{
                            content: "";
                            width: 0;
                            height: 0;
                            border-color: #087510 transparent transparent;
                            border-style: solid;
                            border-width: 72px 85px 0 0;
                            line-height: 0;
                            _border-color: #087510 #000 #000 #000;
                            _filter: progid:DXImageTransform.Microsoft.Chroma(color="#000000");
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                        span{
                            color: #fff;
                            position: absolute;
                            -webkit-transform: rotate(320deg);
                            transform: rotate(320deg);
                            top: 15px;
                            left: 7px;
                            font-size: 14px;
                        }
                    }
                    .dk-frontCandiadteModal-img{
                        text-align: center;
                        padding: 25px 0 5px;
                        img{
                            width: 100px;
                            height: 100px;
                            border-radius: 50%;
                        }
                    }
                    .dk-frontCandiadteModal-text{
                        padding-right: 0;
                        width: 100%;
                        max-width: 290px;
                        margin: auto;
                        .title{
                            text-align: center;
                            font-size: 18px;
                            font-weight: 600;
                            color: #2273aa;
                            margin-bottom: 20px;
                        }
                        label{
                            padding: 0 15px;
                            width: 100%;
                            position: relative;
                            input{
                                height: 35px;
                                padding-left: 45px;
                                border-color: #ddd !important;
                                box-shadow: none !important;
                            }
                        }
                        label.dk-user{
                            &::before{
                                content: "\f095";
                                display: inline-block;
                                font-family: "Font Awesome 5 Free";
                                font-size: 16px;
                                font-weight: 700;
                                text-rendering: auto;
                                -webkit-font-smoothing: antialiased;
                                -moz-osx-font-smoothing: grayscale;
                                position: absolute;
                                top: 0;
                                width: 35px;
                                height: 35px;
                                background: #ddd;
                                text-align: center;
                                line-height: 36px;
                                color: #333;
                                -webkit-transform: rotate(90deg);
                                transform: rotate(90deg);
                            }                            
                        }
                        label.dk-email{
                            &::before{
                                content: "\f1fa";
                                display: inline-block;
                                font-family: "Font Awesome 5 Free";
                                font-size: 16px;
                                font-weight: 700;
                                text-rendering: auto;
                                -webkit-font-smoothing: antialiased;
                                -moz-osx-font-smoothing: grayscale;
                                position: absolute;
                                top: 0;
                                width: 35px;
                                height: 35px;
                                background: #ddd;
                                text-align: center;
                                line-height: 36px;
                                color: #333;
                            }
                        }
                        label.dk-sendMessage{
                            display: flex;
                            align-items: center;
                            span{
                                width: 40px;
                                background: #ddd;
                                height: 35px;
                                text-align: center;
                                line-height: 35px;
                                color: #333;
                                font-size: 15px;
                            }
                            a{
                                display: block;
                                width: 100%;
                                background: #f2f2f2;
                                text-align: left;
                                font-size: 14px;
                                font-weight: 600;
                                color: #333;
                                border-radius: 0;
                                height: 35px;
                                line-height: 34px;
                                border: 1px solid #ddd;
                                padding: 0 0 0 12px;
                            }
                        }
                    }
                }
                .dk-frontModal-rightPanel{
                    padding: 15px 15px 0;
                    .title{
                        font-weight: 700;
                        font-size: 20px;
                        border-bottom: 1px solid #ddd;
                        padding-bottom: 5px;
                        margin-bottom: 15px;
                    }
                    p{
                        background: #f2f2f2;
                        padding: 15px;
                        text-align: justify;
                    }
                }
                .dk-frontJob-form{
                    padding-right: 25px;
                    padding-left: 15px;
                    padding-top:20px;
                    label{
                        width: 100%;
                        margin-bottom: 15px;
                        color: #7d7777;
                        &:first-child{
                            width: 100%;
                            max-width: 100px;
                        }
                        input{
                            width: 100%;
                            background: #f2f2f2!important;
                            border-color: #ddd!important;
                            box-shadow: none!important;
                            border-radius: 0;
                        }
                    }
                    label.dk-dates{
                        position: relative;
                        &::before{
                            content: "\f073";
                            display: inline-block;
                            font-family: "Font Awesome 5 Free";
                            font-size: 16px;
                            font-weight: 700;
                            text-rendering: auto;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            position: absolute;
                            top: 8px;
                            right: 8px;
                        }
                    }
                }
                .dk-frontCandiadteModal-foot{
                    background: #345b7e;
                    padding: 15px;
                    display: block;
                    color: #fff;
                    width: 100%;
                    max-width: 32%;
                    border-radius: 0 0 0 5px;
                    .title{
                        display: block;
                        text-align: center;
                        font-weight: 600;
                        font-size: 16px;
                    }
                    .d-flex{
                        margin: 16px 0;
                        font-size: 14px;
                    }
                    .dk-modalFoot-cols{
                        font-weight:500;
                    }
                }
                .dk-frontJob-rightPanel-foot{
                    display: block;
                    width: 100%;
                    .dk-rightPanel-foot-cols{
                        width: 100%;
                        background: #5b6c8a;
                        color: #fff;
                        padding: 15px 20px 4px;
                        &:last-child{
                            border-radius: 0 0 5px 0;
                        }
                        .title{
                            text-align: center;
                            font-size: 16px;
                            font-weight: 600;
                        }
                        .dk-progressBar{
                            position: relative;
                            background: #fff;
                            width: 100%;
                            max-width: 290px;
                            margin: 15px auto;
                            border-radius: 30px;
                            height: 25px;
                        }
                        .dk-progressBar.techSkill{
                            span{
                                background: #bbad5a;
                                border-radius: 30px;
                                width: 100%;
                                max-width: 230px;
                                display: inline-block;
                                height: 25px;
                                padding: 2px 10px;
                            }
                        }
                        .dk-progressBar.manaSkill{
                            span{
                                background: #bfbfbf;
                                border-radius: 30px;
                                width: 100%;
                                max-width: 180px;
                                display: inline-block;
                                height: 25px;
                                padding: 2px 10px;
                            }
                        }
                        .dk-progressBar.timeSkill{
                            span{
                                background: #99a6bc;
                                border-radius: 30px;
                                width: 100%;
                                max-width: 250px;
                                display: inline-block;
                                height: 25px;
                                padding: 2px 10px;
                            }
                        }
                        .dk-progressBar.teamSkill{
                            span{
                                background: #2f96b6;
                                border-radius: 30px;
                                width: 100%;
                                max-width: 150px;
                                display: inline-block;
                                height: 25px;
                                padding: 2px 10px;
                                white-space: nowrap;
                            }
                        }

                        .dk-frontCandiadteModal-foot{
                            background: #345b7e;
                            padding: 15px;
                            display: block;
                            color: #fff;
                            width: 100%;
                            max-width: 32%;
                            border-radius: 0 0 0 5px;
                            padding: 0!important;
                            background: transparent!important;
                            margin: 0!important;
                            max-width: 100%!important;
                            .title{
                                display: block;
                                text-align: center;
                                font-weight: 600;
                                font-size: 16px;
                            }
                        }
                    }
                }
                .dk-rightPanel-foot-cols.light{
                    background: #5c737d;
                    width: 100%;
                }
            }
        }
    }
}