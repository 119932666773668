.dk-notificationPage{
    margin-top: 40px;
    h2{
        text-transform: uppercase;
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 25px;
    }
    .dk-prefrenceCols{
        width: 100%;
        max-width: 60%;
        margin: auto;
        background: #e5e5e5;
        padding: 20px;
        border: 1px solid #dcdcdc;
        .dk-emailNotification{
            text-align: left;
            .sbtitle{
                font-weight: 700;
                font-size: 16px;
                margin-bottom: 5px;
                text-align: left;
            }
            p{
                a{
                    color: #2d96b6;
                }
            }
        }
        .dk-emailNotification-cols{
            padding: 10px 0;
            .dk-emailNotification-box{
                padding: 25px;
                border: 1px solid #ccc;
                border-bottom: none;
                background: #fff;
                .dk-emailColsLeft{
                    h4{
                        margin: 0;
                        font-size: 16px;
                        font-weight: 600;
                        text-align: left;
                    }
                    p{
                        margin: 5px 0;
                        font-size: 13px;
                        color: #2d96b6;
                    }
                }
                .dk-emailColsRight{
                    .custom-control-input {
                        position: absolute;
                        left: 0;
                        z-index: -1;
                        width: 1rem;
                        height: 1.25rem;
                        opacity: 0;
                    }
                    .custom-switch{                    
                        .custom-control-label{
                            &::before{
                                width: 3.5rem;
                                height: 1.6rem;
                                border-radius: 30px;
                                background: #ccc;
                                color: #fff;
                                border-color: #ccc;
                                background-color: #ccc;
                            }
                            &::after{
                                width: 19px;
                                height: 19px;
                                border-radius: 50%;
                                background: #fff;
                                transform: translateX(0.08rem);
                            }
                        }
                        .custom-control-input:checked~.custom-control-label:before {
                            color: #fff;
                            border-color: #2d96b6;
                            background-color: #2d96b6;
                        }
                        .custom-control-input:checked~.custom-control-label:after {
                            -webkit-transform: translateX(1.85rem);
                            transform: translateX(1.85rem);
                        }
                    }
                }
            }
        }
    }
}

/* Float Shadow */
.hvr-float-shadow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
  }
  .hvr-float-shadow:before {
    pointer-events: none;
    position: absolute;
    z-index: -1;
    content: "";
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 0;
    background: -webkit-radial-gradient(
      center,
      ellipse,
      rgba(0, 0, 0, 0.35) 0%,
      rgba(0, 0, 0, 0) 80%
    );
    background: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0.35) 0%,
      rgba(0, 0, 0, 0) 80%
    );
    /* W3C */
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform, opacity;
    transition-property: transform, opacity;
  }
  .hvr-float-shadow:hover,
  .hvr-float-shadow:focus,
  .hvr-float-shadow:active {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    /* move the element up by 5px */
  }
  .hvr-float-shadow:hover:before,
  .hvr-float-shadow:focus:before,
  .hvr-float-shadow:active:before {
    opacity: 1;
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
    /* move the element down by 5px (it will stay in place because it's attached to the element that also moves up 5px) */
  }