.dkjobs-SelectPickerM{
    .ui.search.dropdown{
        border-radius: 0;
    }
    .ui.selection.active.dropdown{
        .menu{
            border-radius: 0;
        }
    }
    .ui.default.dropdown:not(.button)>.text,
    .ui.dropdown:not(.button)>.default.text,
    .ui.selection.dropdown>.dropdown.icon{
        color: #797575;
    }

}