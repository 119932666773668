.dk-paymentToMainCon{
    width: 100%;
    max-width: 1440px;
    margin: auto;
    padding: 35px 15px;
    .dk-bankinstruction {
        padding: 10px 25px;
        background: #fff;
        position: relative;
        border: 1px solid #cecece;
        border-left: 4px solid #2d96b6;
        .title {
            font-size: 26px;
            margin-bottom: 10px;
            font-weight: 400;
            text-align: left;
        }
    }
    .dk-bankinstructionInfo{
        padding: 25px;
        background: #d9d9d9;
        border: 1px solid #cecece;
        .dk-bankHead {
            text-align: center;
            margin-bottom: 30px;
            img{
                width: 35px;
                height: 35px;
                border-radius: 50%;
                background: #1f394c;
                padding: 6px;
            }
            span {
                display: block;
                font-weight: 600;
                font-size: 16px;
            }
        }
        form {
            display: block;
            margin-top: 0em;
            .form-group {
                margin-bottom: 1rem;
                label {
                    font-size: 14px;
                    width: 100%;
                    &:first-child {
                        width: 100%;
                        max-width: 250px;
                    }
                    .form-control {
                        display: block;
                        width: 100%;
                        height: calc(1.5em + 0.75rem + 2px);
                        padding: 0.375rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #495057;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0;
                    }
                }
            }
        }
    }
}