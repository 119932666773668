.dk-paymentHistoryMain{
    padding: 30px 15px;
    .dk-paymentHead{
        position: relative;
        .dk-payemntHistory-date{
            label{
                width: 100%;
                max-width: 160px;
                margin-right: 10px;
                margin-bottom: 0;
                span{
                    font-weight: 500;
                    font-size: 14px;
                    margin-bottom: 3px;
                    display: block;
                    color: #000;
                }
                input{
                    height: 40px;
                    border-radius:5px;
                    box-shadow: none !important;
                    border:1px solid #ddd !important;
                }
                input[type="date"]::-webkit-calendar-picker-indicator {
                    color: rgba(0, 0, 0, 0);
                    opacity: 1;
                    display: block;
                    background: url('../../../../../../../assets/images/date.png') no-repeat;
                    width: 20px;
                    height: 20px;
                    border-width: thin;
                    background-size: 20px;
                }
            }
            .dk-go{
                width: 40px;
                height: 38px;
                background: #f2f2f2;
                border: 1px solid #ccc;
                font-weight: 600;
                text-transform: uppercase;
                color: #666;
                outline: none;
            }
        }
        h3.title{
            position: absolute;
            left: 50%;
            -webkit-transform: translate(-50%);
            transform: translate(-50%);
            top: 33px;
            margin: 0;
            text-align: center;
            font-weight: 700;
            font-size: 20px;
            text-transform: uppercase;
        }
        .dk-paymentExport{
            button{
                padding:10px 20px;
                font-size: 14px;
                font-weight: 300;
                background-color: #67818e;
                box-shadow: none;
                margin: 0;
                border-radius: 3px;
                position: relative;
                top: 12px;
                color: #fff;
                border: none;
                text-transform: uppercase;
                &:hover{
                    background: #3a89b1;
                    transition: all 0.5s;
                }
            }
        }
    }
    .dk-paymentHistory-table{
        margin-top:6px;
        table{
            tr{
                th{
                    background: #67818e;
                    color: #fff;
                    font-weight: 600;
                    font-size: 14px;
                    border-bottom: none;
                    padding: 10px 12px;
                    text-align: center;
                }
                td{
                    font-weight: 500;
                    font-size: 14px;
                    background: #fff;
                    text-align: center;
                    vertical-align: middle;
                    .dk-statusDropdown{
                        .dropdown{
                            .dropdown-toggle{
                                border-radius: 0;
                                margin: 0;
                                min-width: 100px;
                                text-transform: uppercase;
                                font-size: 13px;
                                padding: 8px 10px;
                                box-shadow: none !important;
                                &::after{
                                    display: none;
                                }
                            }
                            .dropdown-menu{
                                padding: 0;
                                border-radius: 0;
                                border:none;
                                box-shadow: none;
                                min-width: 100px;
                                a{
                                    padding: 10px 12px;
                                    background: #E2445B;
                                    color: #fff;
                                    text-transform: uppercase;
                                    text-align: center;
                                }
                            }
                        }
                    }
                    .dk-action{
                        a{
                            background: #dc9514;
                            border: none;
                            color: #fff;
                            width: 30px;
                            height: 30px;
                            line-height: 28px;
                            font-size: 13px;
                            outline: none;
                            margin-right: 8px;
                            display: inline-block;
                            &:last-child{
                                background: #2d96b6;
                            }
                        }
                    }
                }
            }
        }
        .dk-paymentPagination{
            button{
                width: 35px;
                height: 35px;
                border: 1px solid #ccc;
                border-left: none;
                outline: none;
                font-weight: 600;
                color: #666;
                background: #fff;
            }
            button.dk-paginationArrow{
                background: #fff;
                color: #000;
                font-size: 20px;
                outline: none;
                line-height: 35px;
                &:first-child{
                    border-left: 1px solid #ccc;
                }
                &:last-child{
                    border-right: 1px solid #ccc;
                }
            }
            button.active{
                background: #eaeaea;
            }
        }
    }
}