.dk-billingAddressMain{
    padding: 30px 15px;
    .dk-ownerForm-note {
        margin-top: 23px;
        background: #fff;
        padding: 40px 20px;
        border: 1px solid #f2f2f2;
        border-left: 4px solid #2d96b6;
    }
    h3{
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        text-transform: uppercase;
    }
    .dk-billingAddress-fluid{
        background: #d9d9d9;
        border: 1px solid #b9b9b9;
        padding: 25px;
        .dk-contactPerson-form{
            form{
                .form-group{
                    label {
                        width: 100%;
                        input {
                            width: 100%;
                            height: 40px;
                            border-radius: 0;
                        }
                    }
                    &.dk-saveChanges button{
                        background-color: #1f394c;
                        padding: 10px 20px;
                        font-size: 14px;
                        margin: 0.375rem;
                        color: #fff;
                        text-transform: uppercase;
                        word-wrap: break-word;
                        white-space: normal;
                        cursor: pointer;
                        border: 0;
                        border-radius: 0.125rem;
                        box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
                        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                        &:hover {
                            background-color: #2d96b6;
                            transition: all .5s;
                        }
                    }
                }
            }
        }
    }
}