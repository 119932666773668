.dk-empstndrardCon{
    top: 0;
    background-color: #67818e;
    display: flex;
    flex-wrap: wrap;
    padding: 5px 0 0;
    position: relative;
    margin-top: 0;
    z-index: 9;
    max-width: 100%;
     .dk-ds-profile{
        max-width: 100%;
        .dk-emp-LogoHeader{
            padding: 0 0 5px;
            .dk-emplogoCon{
                img {
                    height: 50px;
                    width: 100%;
                    object-fit: contain;
                    border-radius: 5px;
                    min-width: 142px;
                    max-width: 142px;
                    padding: 2px 5px;
                    background-color: #fff;
                }
            }
            .dk-logoHeaderTitle{
                h3.title {
                    font-size: 20px;
                    font-weight: 700;
                    position: relative;
                    top: 15px;
                    text-transform: uppercase;
                    color: #fff;
                }
            }
            .dk-navBarRightPanel{
                display: flex;
                align-items: center;
                margin-top: 6px;
                .navBarbellCon{
                    .dropdown{
                        .dropdown-toggle.btn {
                            color: #fff;
                            box-shadow: none;
                            background-color: transparent !important;
                            border: none;
                            &::after{
                                display: none;
                            }
                            svg{
                                font-size: 18px;
                            }
                        }
                    }
                }
                .singoutbtnCon{
                    .sigoutbtn {
                        display: inline-block;
                        background: #2d96b6;
                        color: #fff;
                        padding: 5px 25px;
                        border-radius: 30px;
                    }
                }
            }
        }
    }
    .dk-ds-menu {
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 0!important;
        .dk-ds-menu-cols{
            width: 100%;
            .standard_nav{
                list-style: none;
                display: flex;
                height: 100%;
                align-items: flex-end;
                padding: 0;
                margin: 0;
                li {
                    width: 100%;
                    max-width: 11.2%;
                    text-align: center;
                    &:first-child a, &:first-child a.active{
                        border-top-left-radius: 5px;
                    }
                    &:last-child a, &:last-child a.active{
                        border-top-right-radius: 5px;
                    }
                    a {
                        width: 100%;
                        background-color: #234158;
                        display: flex;
                        color: #fff;
                        font-weight: 600;
                        font-size: 14px;
                        padding: 12px 10px;
                        border: 1px solid #afafaf;
                        border-right: none;
                        border-bottom: none;
                        justify-content: center;
                        &.active {
                            background-color: #eaeaea;
                            color: #333;
                        }
                        svg{
                            margin-top: 4px;
                        }
                    }
                }
            }
        }
    }

}