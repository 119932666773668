.dk-settContractsPageMain{
    margin-top: 20px;
    .dk-mydocPageCon{
        .dk-mydocpageHeader{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .dk-mydocLeftCon{
                .dk-searchFieldBox{
                    max-width: 180px;
                    .input-group{
                        input{
                            border-radius: 0;
                            border-right: none;
                            &:focus{
                                box-shadow: none;
                                outline: none;
                            }
                        }
                        .input-group-prepend{
                            .input-group-text{
                                background-color: #fff;
                                border-left: none;
                            }
                        }
                    }
                }
            }
            .dk-mydocCentreCon{
                h3.dk-tableTitle {
                    position: absolute;
                    left: 50%;
                    -webkit-transform: translate(-50%);
                    transform: translate(-50%);
                    color: #333;
                    font-size: 20px;
                    font-weight: 700;
                    margin: 0;
                    display: inline-block;
                    top: 7px;
                    text-transform: uppercase;
                    span{
                        cursor: pointer;
                    }
                }

            }
            .dk-mydocRightCon{
                .dk-myDocs-btnCon{
                    .dk-myDocs-btn{
                        padding: 7px 12px;
                        font-size: 14px;
                        text-transform: inherit;
                        background-color: #6d767f;
                        margin: 0;
                        border-radius: 3px;
                        color: #fff;
                    }
                }
            }
        }
        .dk-mydocumentTableCon{
            margin-top: 10px;
            .dk-mydocumentTable{
                margin-bottom: 0;
                thead{
                    tr th{
                        border: 1px solid #526173;
                        color: #fff;
                        font-weight: 600;
                        font-size: 15px;
                        background: #1e394c;
                        vertical-align: middle;
                        padding: 12px;
                        white-space: nowrap;
                    }
                }
                tbody{
                    tr td {
                        border: 1px solid #526173;
                        color: #fff;
                        vertical-align: middle;
                        padding: 7px 12px;
                        background: #67818e;
                        font-weight: 400;
                        white-space: nowrap;
                        .dk-actionsCols{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            a {
                                display: inline-block;
                                padding: 6px 15px;
                                border-radius: 3px;
                                font-weight: 600;
                                margin-right: 5px;
                                transition: all 0.5s;
                                &.dk-view-btn{
                                    background: #ffb64d;
                                    color: #333;
                                }
                                &.dk-download-btn {
                                    background: #6c757e;
                                    color: #fff;
                                }
                                &.dk-edit-btn {
                                    background: #007bff;
                                    color: #fff;
                                }
                                &.dk-delete-btn {
                                    background: #d93647;
                                    color: #fff;
                                }
                                &:hover {
                                    -webkit-transform: translateY(-5px);
                                    transform: translateY(-5px);
                                    box-shadow: 0 0 1px transparent;
                                    transition: all 0.5s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}