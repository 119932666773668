.dk-compliancePage{
    margin-top: 40px;
    .dk-complianceLeft{
        width: 100%;
        max-width: 175px;
        margin-right: 5px;
        border-radius: 5px 5px 0 0;
        padding-left: 0;
        .nav-item{
            max-width: 100%;
            flex: 100% 1;
            border: none!important;
            list-style-type: none;
            border-radius: 0;
            margin: 0;
            justify-content: space-around;
            display: flow-root;
            .nav-link{
                border-radius: 0;
                color: #fff;
                border: none;
                border-bottom: 1px solid #bdbdbd;
                padding: 10px 15px;
                position: relative;
                background: #1f394c;
                display: flex;
            }
            .nav-link.active{
                background: #67818e;
                &:after {
                    content: "\f0da";
                    display: inline-block;
                    font-family: "Font Awesome 5 Free";
                    font-size: 18px;
                    font-weight: 700;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    position: absolute;
                    right: 12px;
                    top: 8px;
                }
            }
        }
    }
    .dk-complianceRight {
        width: 100%;
        padding-right: 0;
        .dk-bodyPanel{
            text-align: left;
            h2{
                text-align: center;
                background: #67818e;
                padding: 8px 0;
                color: #fff;
                font-weight: 700;
                font-size: 22px;
                text-transform: uppercase;
                margin: 0;
            }
            .dk-panelCols {
                padding: 15px;
                border: 1px solid #ccc;
                border-top: none;
            }
        }
    }
}