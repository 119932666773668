.MuiPagination-ul li button {
    display: inline-block;
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 0;
    margin: 0;
    border-right: 1px solid #ccc;
    font-size: 14px;
    outline: none;
    line-height: 33px;
  }
  .MuiPagination-ul li button:hover {
    background: #2e97b6;
    color: #fff;
  }
  .MuiPagination-ul li button.Mui-selected {
    background: #2e97b6;
    color: #fff;
  }/*# sourceMappingURL=style.css.map */