.dk-jobspage-footerCon {
    bottom: 0;
    color: #fff;
    background: #1e3342;
    .container-fluid{
        width: 100%;
        max-width: 1600px;
        margin: auto;
    }
    .ftop{
       padding: 0;
       background: #182834;
       .dkjobs-topCon{
           .bdr-r{
            border-right: 1px solid #456e8c;
           }
           h4 {
            font-size: 14px;
            padding:8px 0 8px;
            color: #e6e6e6;
            text-transform: uppercase;
            font-weight: 600;
        }
       }
    }
    .fMiddle{
        .dkjobs-MiddleCon{
            .bdr-r {
                border-right: 1px solid #456e8c;
            }
            .dkjobs-flagCon{
                width: 100%;
                a{
                    padding: 1px 2px 10px;
                    display: inline-block;
                    width: 25%;
                    text-align: right;
                    img {
                        width: 42px;
                        border-radius: 2px;
                    }
                }
            }
            .dkjobs-footerCtreCon{
                .dkjobs-Ctrecols{
                    h4{
                        padding: 10px 0 5px;
                        color: #e6e6e6;
                        font-size: 13px;
                        text-transform: uppercase;
                        font-weight: 600;
                        text-align: left;
                    }
                    ul{
                        padding: 0;
                        margin: 0;
                        list-style-type: none;
                        li {
                            display: block;
                            margin: 3px 0;
                            text-align: left;
                            a {
                                font-size: 12.5px;
                                color: #999;
                                text-transform: capitalize;
                                text-decoration: none;
                                text-align: left;
                            }
                        }
                    }
                }
            }
            .dk-footerrightCols{
                h4{
                    padding: 10px 0 5px;
                    color: #e6e6e6;
                    font-size: 13px;
                    text-transform: uppercase;
                    font-weight: 600;
                }
                .dkjobs-instaCon{
                    img{
                        padding: 0 4px 7px;
                        max-width: 100px;
                        width: 25%;
                        cursor: pointer;
                    }
                }
                .footer-social-links{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    a{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        text-decoration: none;
                        i{
                            font-size: .96154rem;
                            color: #fff;
                        }
                    }
                    a.facebookicon{
                        background-color: #3b5998;
                    }
                    a.instaicon{
                        background-color: #2e5e86;
                    }
                    a.linkicon{
                        background-color: #0082ca;
                    }
                    a.youtubeicon{
                        background-color: #ed302f;
                    }
                    a.twittericon{
                        background-color: #55acee;
                    }

                }
            }
        }
    }
    .fbottom{
        display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            padding: 15px 0;
            background: #182834;
        .dkjobs-bottomCon{     
            text-align: center;       
            p{
                margin: 0 0 2px;
                color: #999;
                font-size: 12.5px;
            }
        }
    }
}