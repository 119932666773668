.dk-frontSubscription-main{
    background-color: #25455a;
    padding: 80px 15px;
    .dk-subscription-title{
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 26px;
        padding: 8px 0;
        color: #fff;
        margin-bottom:5px;
    }
    .dk-subscription-cols{
        background: #fff;
        padding: 25px;
        border-radius: 5px;
        .dk-subscriptionTabs{
            width: 100%;
            max-width:370px;
            margin: auto;
            margin-top: 15px;
            .nav-pills{
                border: 1px solid #c7c7c7;
                padding: 5px;
                border-radius: 30px;
                flex-wrap:nowrap;
                .nav-item{
                    width: 100%;
                    a{
                        width: 100%;
                        text-align: center;
                        text-transform: uppercase;
                        color: #333;
                        font-weight: 600;
                        border-radius: 30px;
                        padding: 8px 0;
                        &:hover{
                            color: #0084de;
                            transition: all .5s;
                        }
                    }
                    a.active{
                        background-color: #2e5574;
                        color: #fff;
                    }
                }
            }
        }
        .dk-subscriptionTabs-panel{
            margin-top:20px;
            .dk-pricePlan-mainContainer{
                .dk-pricePlan-main{
                    table{
                        margin: 0;
                        tr{
                            th{
                                text-transform: uppercase;
                                color: #fff;
                                text-align: center;
                                font-size: 20px;
                                font-weight: 700;
                                width: 18.66%;
                                border: 1px solid #979aa0;
                                border-bottom: none;
                                position: relative;
                                vertical-align: middle;
                                padding-top: 17px;
                                padding-bottom: 17px;
                                span{
                                    img{
                                        width: 23px;
                                        margin-right: 9px;
                                        position: relative;
                                        top: -2px;
                                        cursor: pointer;
                                    }
                                }
                                &:nth-child(01){
                                    background: #323d4f;
                                    width: 40%;
                                }
                                &:nth-child(02){
                                    background: #67818e;
                                }
                                &:nth-child(03){
                                    background-color: #9d265c;
                                }
                            }
                        }
                    }
                    table.table_2{
                        tr{
                            th{
                                font-weight: 600;
                                font-size: 16px!important;
                                border: 1px solid #979aa0;
                                padding: 10px 10px;
                                &:nth-child(01){
                                    width: 10%;
                                }
                                &:nth-child(02){
                                    width: 30%;
                                    background: #323d4f;
                                    text-align: left;
                                }
                                &:nth-child(03){
                                    background: #67818e;
                                }
                                &:nth-child(04){
                                    background: #9d265c;
                                }
                            }
                            td{
                                text-transform: uppercase;
                                font-size: 13px;
                                font-weight: 600;
                                text-align: center;
                                border: 1px solid #ccc;
                                color: #7a8798;
                                vertical-align: middle;
                                padding: 0.5rem 1rem;
                                span.pull-right{
                                    position: absolute;
                                    font-size: 20px;
                                    color: #ccc;
                                    top: 5px;
                                    right: 15px;
                                    z-index: 99;
                                    cursor: pointer;
                                }
                                span.cross{
                                    font-size: 22px;
                                    color: #da4759;
                                    line-height: 0;
                                }
                                span.check{
                                    font-size: 20px;
                                    color: #73bd2a;
                                    line-height: 0;
                                }
                                &:nth-child(01){
                                    text-align: center;
                                    color: #000;
                                }
                                &:nth-child(02){
                                    text-align: left;
                                    color: #000;
                                    position: relative;
                                    cursor: pointer;
                                }
                            }
                            &:last-child{
                                td{
                                    a{
                                        padding: 10px 12px;
                                        text-transform: uppercase;
                                        color: #fff;
                                        display: block;
                                        margin: 8px;
                                        &:hover{
                                            background: #243b4c!important;
                                            transition: all .5s;
                                            color: #fff;
                                        }
                                    }
                                    &:nth-child(03){
                                        a{
                                            
                                            background: #67818e;
                                        }
                                    }
                                    &:nth-child(04){
                                        a{
                                            
                                            background: #9d265c;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.dk-subscriptionInfo-modal{
    .modal-dialog{
        max-width: 500px;
    }    
    .modal-content{
        box-shadow: none;
        border: none;
        border-radius: 5px;
        .modal-header{
            background: #2e5574;
            border-radius:4px 4px 0 0;
            padding: 8px 10px;
            border-bottom: none;
            .modal-title{
                color: #fff;
                text-align: center;
                width: 100%;
                font-size: 18px;
                text-transform: uppercase;
                font-weight: 600;
            }
            .close{
                margin:3px 0 0;
                display: inline-block;
                padding: 0;
                font-size: 24px;
                color: #fff;
                opacity: 1;
                text-shadow: none;
                font-weight: normal;
                outline: none;
            }
        }
    }
}