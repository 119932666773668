.dk-fullProfilePageCon{
    margin-top: 40px;
    .dk-fullProfileCon{
        .dk-profileBg{
            background-color: #23475f;
            flex: 1 1 auto;
            // min-height: 1px;
            padding: 1.25rem;
            border-radius: 5px;
            min-height: 85vh;
            .dk-topIntroSec{
                display: flex;
                flex-flow: row wrap;
                .dk-LeftIntroSec{
                    display: flex;
                    flex-flow: row wrap;
                    flex-basis: 50%;
                    .dk-usrImgCon{
                        flex-basis: 30%;
                        text-align: left;
                        img{
                            margin-bottom: 5px;
                            overflow: hidden;
                            border: 5px solid #fff;
                            border-radius: 50%;
                            width: 100px;
                            height: 100px;
                        }
                    }
                    .dk-usrNameDes{
                        flex-basis: 70%;
                        text-align: left;
                        h4{
                            font-size: 1.5rem;
                            font-weight: 500;
                        }
                        .desText{
                            ul{
                                list-style: none;
                                padding: 0;
                                margin: 0;
                                list-style-type: none;
                                .dk-icon {
                                    padding: 5px 0;
                                    font-size: 14px;
                                    font-weight: 600;
                                    white-space: nowrap;
                                    color: #ddd;
                                }
                            }
                        }
                    }
                }
                .dk-RighttIntroSec{
                    flex-basis: 50%;
                    .dk-hertcheckboxCon{
                        display: flex;
                        justify-content: flex-end;
                        & i{
                            padding-right: 1rem;
                            color: #fff;
                            font-size: 20px;
                        }
                        .checkboxIcon{
                            .form-check{
                                padding: 0;
                                padding-left: 15px;
                                input[type=checkbox]{
                                    width: 15px;
                                    height: 15px;
                                    position: relative;
                                    top: -3px;
                                }
                            }                            
                        }
                    }
                    .contactus-Text{
                        ul{
                            list-style: none;
                            margin: 0;
                            margin-top: 1.25rem;
                            li{
                                text-align: left;
                                padding: 5px 0;
                                font-size: 14px;
                                font-weight: 600;
                                white-space: nowrap;
                                color: #ddd;
                            }
                        }
                    }
                }
            }
            .dk-fullProfhrTabsCon{
                margin-top: 1rem;
                div{
                    .dk-fullProfhrTabspills{
                        flex-wrap: nowrap;
                        .dk-fullProfileNavItem.nav-item{
                            flex: 100% 1;
                            max-width: 100%;
                            &:first-child{
                                .nav-link{
                                    border-top-left-radius: 5px;
                                    border-bottom-left-radius:5px;
                                }
                            }
                            &:last-child{
                                .nav-link{
                                    border-top-right-radius: 5px;
                                    border-bottom-right-radius: 5px;
                                }
                            }
                            .nav-link {
                                background: #e7e6e6;
                                border-left: 1px solid #ad9f9f;
                                color: #333;
                                font-weight: 600;
                                border-radius: 0;
                            }
                            &.show .nav-link,& .nav-link.active {
                                background-color: #f4d03e;
                                border-left: 1px solid #ad9f9f;
                                color: #333;
                                border-color: transparent;
                                height: 100%;
                            }
                        }
                    }
                }
                .dk-fullProfileTabContent{
                    color: #fff;
                    padding: 1.5rem 0;
                    .dk-fullProfileTabPane.tab-pane{
                        .dk-profilesummLeft{
                            .d-flex{
                                margin: 0.5rem 0;
                                label{
                                    color: #fff;
                                    min-width: 140px;
                                     text-align: left;
                                    font-size: 13px;
                                }
                                input {
                                    border: 1px solid #8a8383;
                                    background-color: transparent;
                                    color: #fff;
                                    text-align: center;
                                    padding: 7px 8px;
                                    border-radius: 3px;
                                    font-size: 13px;
                                    width: 100%;
                                    outline: none;
                                }
                            }
                        }
                        .dk-formright-panel{
                            .dk-suMMtextAreaCon{
                                label.title {
                                    border: 1px solid #8a8383;
                                    border-bottom: none;
                                    padding: 4px 0;
                                    text-align: center;
                                    width: 100%;
                                    margin-bottom: 0;
                                }
                                textarea.dk-profsumm11{
                                    width: 100%;
                                    height: 130px;
                                    &:focus{
                                        box-shadow: none;
                                        outline: none;
                                    }
                                }
                            }
                        }
                        .dk-bottom-panelCon{
                            .dk-suMMtextAreaCon{
                                display: flex;
                                align-items: center;
                                label{
                                    color: #fff;
                                    min-width: 153px;
                                    text-align: left;
                                    font-size: 13px;
                                }
                                textarea{
                                    width: 100%;
                                    height: 130px;
                                }
                            }
                        }
                        .dk-DocmentsCon{
                            .dk-docHeader{
                                .title{
                                    margin-bottom: 10px;
                                    text-align: left;
                                    font-weight: 500;
                                }
                            }
                            .dk-doctableCon{
                                .dk-doctable{
                                    thead
                                    {
                                        tr th{
                                            border-bottom: none;
                                            color: #fff;
                                            font-size: 1rem;
                                            padding: 0.75rem;
                                            vertical-align: middle;
                                            font-weight: normal;
                                        }
                                    }
                                    tbody{
                                        tr td{
                                            color: #fff;
                                            padding: 0.125rem 0.75rem;
                                            vertical-align: middle;
                                            i{
                                                font-size: 18px;
                                            }
                                            .dk-actioncols{
                                                display: flex;
                                                justify-content: center;
                                                .vewbtn{
                                                    margin: 0.375rem;
                                                    padding: 0.35rem 1.14rem;
                                                    color: #333;
                                                    font-size: 0.81rem;
                                                    background-color: #fb3;
                                                    margin-right: 0.5rem;
                                                    border: 0;
                                                    border-radius: 0.125rem;
                                                    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
                                                    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                                                }
                                                .downloadbtn{
                                                    margin: 0.375rem;
                                                    padding: 0.35rem 1.14rem;
                                                    color: #333;
                                                    background-color: #2bbbad;
                                                    border: 0;
                                                    border-radius: 0.125rem;
                                                    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
                                                    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .dk-achivementTabCon{
                            border: 1px solid rgb(173, 159, 159);
                            padding: 1rem;
                            padding-top: 0;
                            .dk-archiveBoxCon{
                                display: flex;
                                border-bottom: 1px solid #ad9f9f;
                                padding: 1rem 0; 
                                &:last-child{
                                    border-bottom: none;
                                }
                                .dk-achiveRightPanel{
                                    .topCon{
                                        display: flex;
                                        align-items: center;
    
                                        label{
                                            min-width: 145px;
                                            text-align: left;
                                        }
                                        span{
                                            margin-bottom: 0.5rem;
                                        }
                                    }
                                    .dk-sliderdesc{
                                        p{
                                            text-align: justify;
                                        }
                                    }
                                }
                            }
                        }
                        .dk-experinceAccordion{
                            .accordion{
                                .card{
                                    background-color: transparent;
                                    border: none;
                                    .card-header{
                                        color: #fff;
                                        border-bottom: none;
                                        padding: 0;
                                        .dk-timeline-wrapper{
                                            display: flex;
                                            flex-flow: row wrap;
                                            .dk-timeline-time{
                                                font-style: italic;
                                                padding: 20px;
                                                flex: 0 0 40%;
                                                text-align: center;
                                                position: relative;
                                                &:first-child:after {
                                                    position: absolute;
                                                    content: "";
                                                    left: 15px;
                                                    top: 30px;
                                                    width: 30%;
                                                    height: 1px;
                                                    background: #dadbdb;
                                                    display: block;
                                                }
                                                .timeline-icon {
                                                    position: absolute;
                                                    width: 30px;
                                                    height: 30px;
                                                    line-height: 30px;
                                                    color: #23475f;
                                                    background: #e7e6e6;
                                                    text-align: center;
                                                    left: 0;
                                                    border-radius: 5px;
                                                    top: 15px;
                                                    font-style: normal;
                                                    z-index: 2;
                                                    font-size: 14px;
                                                    cursor: pointer;
                                                }
                                            }
                                            .dk-timeline-series {
                                                margin-top: 15px;
                                                margin-bottom: 15px;
                                                flex: 0 0 60%;
                                                border: 1px solid #ccc;
                                                padding: 0.5em 1em;
                                                border-radius: 4px;
                                                position: relative;
                                                // display: flex;
                                                // justify-content: space-between;
                                                // align-items: center;
                                                &:before {
                                                    position: absolute;
                                                    content: "";
                                                    left: -23%;
                                                    top: 15px;
                                                    width: 23%;
                                                    height: 1px;
                                                    background: #dadbdb;
                                                    display: block;
                                                }
                                                .dk-seriesBetween{
                                                    // display: flex;
                                                    // justify-content: space-between;
                                                    // width: 100%;
                                                    p{
                                                        width: 100%;
                                                        position: relative;
                                                        text-align: left;
                                                        span{
                                                            position: absolute;
                                                            right: 10px;
                                                        }
                                                    }
                                                    .collapse{
                                                        .dk-dgd{
                                                            ul{
                                                                list-style: none;
                                                                li{
                                                                    text-align: left;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}